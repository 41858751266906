import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "../../router";
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  createdBusinessStatus: 0,
  createdBusinessName: "",
  percentSetupCompleted: 0,
  onSelectedBankAccount: null,
  onChangeValue: "organizationSetting",
};
const mutations = {
  setPercent(state, payload) {
    state.percentSetupCompleted = payload;
  },
  setCreatedBusiness(state, payload) {
    state.createdBusinessStatus = payload.status;
    state.createdBusinessName = payload.businessName;
  },
  setSelectedBanckAccount(state, payload) {
    state.onSelectedBankAccount = payload;
  },
  setonchangeValue(state, payload) {
    console.log("mutation called");
    state.onChangeValue = payload;
    console.log("this", state.onChangeValue);
  },
};
const actions = {
  setPercent: ({ state, getters, commit, rootState, rootGetters }, percent) => {
    commit("setPercent", percent);
  },
  setBankAccount: ({ commit }, selectedBanckAccount) => {
    commit("setSelectedBanckAccount", selectedBanckAccount);
  },
  setBusinessCreated: (
    { state, getters, commit, rootState, rootGetters },
    payload
  ) => {
    commit("setCreatedBusiness", payload);
  },
  setupBusiness: ({ state, getters, commit, rootState, rootGetters }, data) => {
    let formData = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    return request
      .make("POST", "/Business/AddBusiness", null, formData, options)
      .then((response) => {
        console.log(response);
        return response;
      })
      .catch((error) => {
        console.log("yes" + error);
        return error;
      });
  },
  getDashBoardBankDetails: ({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
  }) => {
    // let formData = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "DashBoard/DashBoardBankDetails",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((response) => {
        console.log("inResponseIfDaseboard", response);
        return response;
      })
      .catch((error) => {
        console.log("In Error Response Of Dashborad" + error);
        return error;
      });
  },
  getDashboardCashDetail: ({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
  }) => {
    // let formData = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "DashBoard/DashBoardCashDetails",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((response) => {
        console.log("getDashboardCashDetail Response", response);
        return response;
      })
      .catch((error) => {
        console.log("In Error Response Of getDashboardCashDetail" + error);
        return error;
      });
  },
  getDashboardSearchOutStandingInvoice: ({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
  }) => {
    // let formData = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "DashBoard/SearchOutstandingInvoices",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((response) => {
        console.log("getDashboardOutStandingInvoice Response", response);
        return response;
      })
      .catch((error) => {
        console.log(
          "In Error Response Of getDashboardOutStandingInvoice" + error
        );
        return error;
      });
  },
  getDashboardIsWatchableOnDashBoard: ({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
  }) => {
    // let formData = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "DashBoard/IsWatchableOnDashBoard",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((response) => {
        console.log("getDashboardIsWatchableOnDashBoard Response", response);
        return response;
      })
      .catch((error) => {
        console.log(
          "In Error Response Of getDashboardIsWatchableOnDashBoard" + error
        );
        return error;
      });
  },
};
const getters = {
  getonchangeValue(state) {
    return state.onChangeValue;
  },
  loggedIn(state) {
    return state.token !== null;
  },
  getPrecent(state) {
    return state.percentSetupCompleted;
  },
  getSelectedBanckAccount(state) {
    return state.onSelectedBankAccount;
  },
  companyCreated(state) {
    let data = {
      status: state.createdBusinessStatus,
      business: state.createdBusinessName,
    };
    return data;
  },
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state,
};
import store from "../index";
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function (resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          if (event.srcElement.status === 401) {
            store.commit("auth/setLoginExpired", true);
          } else if (event.srcElement.status === 403) {
            router.push({ name: "accessDenied" });
          } else if (event.srcElement.status === 500) {
            console.log(
              "Error 500",
              JSON.parse(event.srcElement.response).message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).message
            );
          } else {
            return new Promise(function (resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function (resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
