<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="6" xl="4" class="mx-auto mt-15">
          <v-card>
            <v-card-text class="text-center">
              <img src="../../assets/images/serverError.svg" alt="" />
              <div class="text-center">
                <h2>404 Not Found</h2>
                <v-btn color="primary" class="mt-5" :to="{ name: 'dashboard' }"
                  >Go Back</v-btn
                >
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
