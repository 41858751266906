import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  getReportIsFormatValue:false,
  filteredReport: {},
  headerData:{},
  filterData:null,
  netTotalCredit:0,
  netTotalDebit:0,
  grossprofitAndLoss:0,
  prevgrossprofitAndLoss:0,
  netProfitAndLoss:0,
  prevnetProfitAndLoss:0,
   // Balance Sheet Totals Variables
   totalForAssets:0,
   totalForLiabilities:0,
   totalForEquity:0,
   prevTotalForAssets:0,
   prevTotalForLiabilities:0,
   prevtotalForEquity:0,
   netAssets:0,
   prevNetAssets:0,
   netEquity:0,
   prevNetEquity:0,
  reportType:"",
  backTo:"",
  searchType:null
};
const mutations = {
  setfilteredReport: (state, payload) => {
    state.filteredReport = payload.data
    state.headerData=payload.headerData
    state.netTotalCredit=payload.netTotalCredit?payload.netTotalCredit:0
    state.netTotalDebit=payload.netTotalDebit?payload.netTotalDebit:0
    state.grossprofitAndLoss=payload.grossprofitAndLoss?payload.grossprofitAndLoss:0
    state.netProfitAndLoss=payload.netProfitAndLoss?payload.netProfitAndLoss:0
    state.prevgrossprofitAndLoss=payload.prevgrossprofitAndLoss?payload.prevgrossprofitAndLoss:0
    state.prevnetProfitAndLoss=payload.prevnetProfitAndLoss?payload.prevnetProfitAndLoss:0
    // Balance Sheet Totals
    state.totalForAssets=payload.totalForAssets?payload.totalForAssets:0
    state.totalForLiabilities=payload.totalForLiabilities?payload.totalForLiabilities:0
    state.totalForEquity=payload.totalForEquity?payload.totalForEquity:0
    state.prevTotalForAssets=payload.prevTotalForAssets?payload.prevTotalForAssets:0
    state.prevTotalForLiabilities=payload.prevTotalForLiabilities?payload.prevTotalForLiabilities:0
    state.prevtotalForEquity=payload.prevtotalForEquity?payload.prevtotalForEquity:0
    state.netAssets=payload.netAssets?payload.netAssets:0
    state.prevNetAssets=payload.prevNetAssets?payload.prevNetAssets:0
    state.netEquity=payload.netEquity?payload.netEquity:0
    state.prevNetEquity=payload.prevNetEquity?payload.prevNetEquity:0
  },
  setFilterData: (state, payload) => {
    state.filterData = payload
  },
  setReportData:(state, payload)=>{
    state.reportType=payload.reportType,
    state.backTo=payload.backTo,
    state.searchType=payload.searchType
  },
  setReportIsFormatValue:(state, payload)=>{
    state.getReportIsFormatValue=payload
    // state.reportType=payload.reportType,
    // state.backTo=payload.backTo,
    // state.searchType=payload.searchType
  }
};
const actions = {
  setReportData({ commit }, payload){
    commit('setReportData',payload)
  },
  
  setIsFormatValueReportData({ commit }, payload){
    commit('setReportIsFormatValue',payload)
  },

  ShowAccountsForReport({ rootGetters },branchId) {
    const options = {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/loginToken"]
        }
      };
      let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make("GET", 
      "ChartOfAccount/ShowAccountsForReport", 
      {businessId:selectedBusiness.businessId,
        branchId:branchId},
         null, options)
      .then(response => {
        return response;
      })
      .catch(err => {
        return err;
      });
  },
  getInvoiceA({ state, getters, commit, rootState, rootGetters }, payload) {
    let data={
      invoiceNo: payload.invoiceNo,
      type: payload.type,
      branchID:payload.branchID
    }
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/journalReportPopUp",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,limit:payload.limit,pageNum:payload.pageNum},
        data,
        options
      )
      .then(res => {
        if(res.data.success=="OK"){
          return res.data;
        }
        
      })
      .catch(err => {
        return err;
      });
  },
  GeneralledgerReport({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/GeneralledgerReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  exportJournalReport({  commit, rootGetters }, payload) {
    console.log("sending data" , payload.selectedFileTypeForAll)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    if(payload.reportName=="General Ledger"){
      return request
      .make(
        "POST",
        "/Reports/ExportGeneralledgerReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }

    if(payload.reportName=="Journal Report"){
      return request
      .make(
        "POST",
        "/Reports/ExportJournalReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    
    if(payload.reportName=="Trial Balance"){
      return request
      .make(
        "POST",
        "/Reports/ExportTrialBalanceReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    if(payload.reportName=="Profit & Loss Statement"){
      return request
      .make(
        "POST",
        "/Reports/ExportProfitAndLossReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    if(payload.reportName=="Comparative Profit & Loss Statement"){
      return request
      .make(
        "POST",
        "/Reports/ExportProfitAndLossReportCompare",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    if(payload.reportName=="Balance Sheet"){
      return request
      .make(
        "POST",
        "/Reports/ExportBalanceSheetReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    if(payload.reportName=="Comparative Balance Sheet"){
      return request
      .make(
        "POST",
        "/Reports/ExportBalanceSheetReportCompare",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,fileType:payload.selectedFileTypeForAll},
        payload.filterDataDetails,
        options
      );
    }
    
  },

  GeneralledgerReportAccountsPayable({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/GeneralledgerReportAccountsPayable",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,refID:payload.refID},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  GeneralledgerReportAccountsRecievable({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/GeneralledgerReportAccountsRecievable",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,refID:payload.refID},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  GeneralledgerReportWagesPayable({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/GeneralledgerReportWagesPayable",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,refID:payload.refID},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  getUserLogReports({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    console.log(payload,"message 42703: column u.FromDate does not exist")
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    let NotUserId ='00000000-0000-0000-0000-000000000000'
    let userId =payload.data.userID ? payload.data.userID: NotUserId
    let branchId = payload.data.branchID ? payload.data.branchID:null
    return request
      .make(
        "POST",
        "/Reports/UserLogReport",
        { businessID: selectedBusiness.businessId, branchID: branchId,fromDate:payload.data.fromDate,toDate:payload.data.toDate,pageNum:payload.data.pageNo},
        {
          logAction:payload.data.logAction,
          logType:payload.data.logType,
          status:payload.data.status,
          UserID:userId
        },
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  getFilteredReportA({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("sending data" , payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/journalReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  TrialBalanceReport({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/TrialBalanceReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  TrialBalanceReportCompare({  commit, rootGetters }, payload) {
   commit('setFilterData',payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/TrialBalanceReportCompare",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  BalanceSheetReport({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/BalanceSheetReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  BalanceSheetReportCompare({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/BalanceSheetReportCompare",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  ProfitAndLossReport({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("DATA TO SEND",payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/ProfitAndLossReport",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        console.log('RESPONSE OF PROFIT AND LOSS',res)
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  ProfitAndLossReportCompare({  commit, rootGetters }, payload) {
    commit('setFilterData',payload.data)
    console.log("DATA TO SEND",payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reports/ProfitAndLossReportCompare",
        { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
        payload.data,
        options
      )
      .then(res => {
        console.log('RESPONSE OF PROFIT AND LOSS',res)
        commit('setfilteredReport', res.data)
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  getChildAccountsA: ({ commit,rootGetters }, payload) => {
    console.log("IN STORE")
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return axios.post(payload.URI, payload.data, {params: {'businessID': selectedBusiness.businessId,'branchID':selectedBusiness.branchId}}).then(response => {
      console.log("RESPONSE OF GET CHILD ACCOUNT",response)
        // if (response.data.success === 'OK') {return response.data }
        // else { "Error",console.log(response.data.success) }
    }).catch(err => {
        console.log("Error",err)
    })
},
    getBranches({ state, getters, commit, rootState, rootGetters, dispatch }) {
        const options = {
            headers: {
              Authorization: "Bearer " + rootGetters["auth/loginToken"]
            }
          };
          let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make("GET", "/User/userbranchview", {businessId:selectedBusiness.businessId}, null, options)
          .then(response => {
            return response;
          })
          .catch(err => {
            return err;
          });
      },
};
const getters = {
  filteredReport(state){
    return state.filteredReport
  },
  getDebitCredit(state){
    let data={
      netTotalCredit:state.netTotalCredit,
      netTotalDebit:state.netTotalDebit
    }
    return data
  },
  getProfitLoss(state){
    let data={
  grossprofitAndLoss:state.grossprofitAndLoss,
  prevgrossprofitAndLoss:state.prevgrossprofitAndLoss,
  netProfitAndLoss:state.netProfitAndLoss,
  prevnetProfitAndLoss:state.prevnetProfitAndLoss
    }
    return data
  },
  getBalanceSheetTotals(state){
   let data={
    totalForAssets:state.totalForAssets,
    totalForLiabilities:state.totalForLiabilities,
    totalForEquity:state.totalForEquity,
    prevTotalForAssets:state.prevTotalForAssets,
    prevTotalForLiabilities:state.prevTotalForLiabilities,
    prevtotalForEquity:state.prevtotalForEquity,
    netAssets:state.netAssets,
    prevNetAssets:state.prevNetAssets,
    netEquity:state.netEquity,
    prevNetEquity:state.prevNetEquity
      }
      return data
  },
  headerData(state){
    return state.headerData
  },
  getReportIsFormatValue(state){
    return state.getReportIsFormatValue
  },
  filterData(state){
    return state.filterData
  },
  getReportData(state){
    let data={
      reportType:state.reportType,
      backTo:state.backTo,
      searchType:state.searchType
    }
    return data
  }
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers
      })
        .then(response => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch(error => {
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          }else if(event.srcElement.status ===403){
            router.push({name:'accessDenied'})
          }
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          }
           else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed."
          }
        };
        resolve({
          error: true,
          message: "server request Failed."
        });
        reject(error);
      });
    }
  }
  /* ----------------- ---------- -------- -------------------- */
};
