import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from "../../router";
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  businessLength: null,
  getOnCheckState: null,
};
const mutations = {
  setBusinessLength(state, payload) {
    state.businessLength = payload;
  },
  setOnCheckState(state, payload) {
    state.getOnCheckState = payload;
  },
};
const actions = {
  setOncheckState(
    { state, getters, commit, rootState, rootGetters },
    onCheckState
  ) {
    commit("setOnCheckState", onCheckState);
  },
  getBusinessUsers({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BusinessUser/ViewBusinessUser",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updatePrefixes({ state, getters, commit, rootState, rootGetters }, prefixes) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Prefix/UpdatePrefixes",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        prefixes,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewPrefixes({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Prefix/ViewPrefixes",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAllRoles({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/UserRoles/ViewAllUserRoles",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAccountSettingForReporting({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
  }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/AccountSettingsForReporting",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  ViewUserRoles({ state, getters, commit, rootState, rootGetters }, roleID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/UserRoles/ViewUserRoles",
        {
          businessID: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          roleID: roleID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // get all users
  ViewBusinessUser({ rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BusinessUser/ViewBusinessUser",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // get user grant access
  viewuseraccess({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/User/viewuseraccess",
        {
          businessID: selectedBusiness.businessId,
          userName: payload,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateRole({ state, getters, commit, rootState, rootGetters }, employeeID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ActivateEmployee",
        {
          businessId: selectedBusiness.businessId,
          employeeID: employeeID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ActivateBusinessUser({ rootGetters }, userID) {
    console.log("ActivateBusinessUser USERID", userID);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/BusinessUser/ActivateBusinessUser",
        {
          businessId: selectedBusiness.businessId,
          userID: userID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateBusinessUser({ rootGetters }, userID) {
    console.log("DeactivateBusinessUser USERID", userID);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/BusinessUser/DeactivateBusinessUser",
        {
          businessId: selectedBusiness.businessId,
          userID: userID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deActivateUser({ rootGetters }, userID) {
    console.log("USERID", userID);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    // let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/User/DeactivateUser",
        {
          // businessId: selectedBusiness.businessId,
          userID: userID,
          // branchId:selectedBusiness.branchId
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ActivateUser({ state, getters, commit, rootState, rootGetters }, roleID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/User/ActivateUser",
        {
          // businessId: selectedBusiness.businessId,
          userID: roleID,
          // branchId:selectedBusiness.branchId
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // User Role Activate
  ActivateUserRole({ state, getters, commit, rootState, rootGetters }, roleID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/UserRoles/ActivateUserRole",
        {
          businessId: selectedBusiness.businessId,
          roleID: roleID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // user role activate
  deActivateUserRole(
    { state, getters, commit, rootState, rootGetters },
    roleID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/UserRoles/DeActivateUserRole",
        {
          businessId: selectedBusiness.businessId,
          roleID: roleID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  addUserRole({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/UserRoles/AddUserRoles",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // user registration
  UserRegistration(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/User/UserRegistration",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // update user access
  updateuseraccess({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/User/updateuseraccess",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          userName: payload.userName,
        },
        {
          grantBranchAccess: payload.grantBranchAccess,
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // grant access
  grantuseraccess({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/User/grantuseraccess",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          userName: payload.userName,
        },
        {
          grantBranchAccess: payload.grantBranchAccess,
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  UpdateUserRoles(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/UserRoles/UpdateUserRoles",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          roleID: formdata.roleID,
        },
        {
          //  roleID: formdata.roleID,
          roleName: formdata.roleName,
          //  rolePermission: formdata.rolePermission
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  UpdateRolePermissions(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "UserRoles/UpdateRolePermissions",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          roleID: formdata.roleID,
        },
        {
          //  roleID: formdata.roleID,
          //  roleName: formdata.roleName,
          updateRolePermissions: formdata.rolePermission,
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  getBusiness({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Business/ViewBusiness",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewTermandConditions({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Business/ViewBusinessTermsAndConditions",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateTermCondtions({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Business/UpdateBusinessTermsAndConditions",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateUserBusinessSetting({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Business/UpdateBusinessSetting",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // update logo
  updatelogo({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Business/UpdateBusinessLogo",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // updatefinancialSetting

  updatefinancialSetting(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    let formdata = JSON.stringify(payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Business/UpdateBusinessFinancialSetting",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  generateScheduleByFixedAsset({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/ScheduleByFixedAsset",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        {
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          fixedAssetID: payload.fixedAssetID ? payload.fixedAssetID : undefined,
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  generateScheduleByGLAccount({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "FixedAsset/ScheduleByAccount",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        {
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          accountID: payload.accountID ? payload.accountID : undefined,
        },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importCOA({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/formdata",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/ImportCOA",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // Export Chart of Account
  ExportCOA({ state, getters, commit, rootState, rootGetters }, fileType) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ExportCOA",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: fileType,
          coaID: "",
        },
        null,
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },

  getUser({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BusinessUser/ViewUserWithBranchAccess",

        {
          branchID: selectedBusiness.branchId,
          businessID: selectedBusiness.businessId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //  getUserRights

  getUserRights({ state, getters, commit, rootState, rootGetters }, userID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/UserRights/showuserrights",
        {
          branchId: selectedBusiness.branchId,
          businessId: selectedBusiness.businessId,
          userID: userID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // userBranchViews
  userBranchViews({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/User/userbranchview",
        {
          businessID: selectedBusiness.businessId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // adduserAccress
  adduserAccress({ state, getters, commit, rootState, rootGetters }, payload) {
    let userName = payload.userName;
    let data = JSON.stringify(payload.formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/User/grantuseraccess",
        {
          userName: userName,
          businessId: selectedBusiness.businessId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addCurrency({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/BusinessCurrency/AddCurrency",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  deleteCurrency({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/BusinessCurrency/DeactivateCurrency",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          currencyName: payload,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  getCurrency(
    { state, getters, commit, rootState, rootGetters },
    currencyName
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BusinessCurrency/ViewCurrency",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          currencyName: currencyName,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addCompanyType({ state, getters, commit, rootState, rootGetters }, type) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Business/CompanyType",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          businessType: type,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCOAForDropDown({ rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCOAForDropDownExceptBank(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/ViewCOAMultipleRefOppositely",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateReportForAccounting(
    { state, getters, commit, rootState, rootGetters },
    payload,
    isdefaultbsformat
  ) {
    let oncheck = payload[0].isdefaultbsformatvalue;
    JSON.stringify(payload);

    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/ChartOfAccount/UpdateAccountSettingsForReporting",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          isdefaultbsformat: oncheck,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCOANoParent({ rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountWithoutParent",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getChartOfAccount(
    { state, getters, commit, rootState, rootGetters },
    accountId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          accountId: accountId,
        },
        null,
        options
      )
      .then((res) => {
        if (
          res.data.success == "Failed" &&
          res.data.response[0].message == "Business id doesnot exists"
        ) {
          router.push({ name: "signout" });
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err;
      });
  },
  editBankCOA({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data.formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/ChartOfAccount/UpdateChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          accountId: data.accountId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editSimpleCOA({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data.formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/ChartOfAccount/UpdateSimpleChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          accountId: data.accountId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addSimpleCOA({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/AddSimpleChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addBankCOA({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/AddChartOfAccountWithBank",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editEmployee({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Employee/UpdateEmployee",
        {
          businessId: selectedBusiness.businessId,
          employeeId: data.empoloyeeID,
          branchId: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateEmployee(
    { state, getters, commit, rootState, rootGetters },
    employeeID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ActivateEmployee",
        {
          businessId: selectedBusiness.businessId,
          employeeID: employeeID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateAccount(
    { state, getters, commit, rootState, rootGetters },
    accountId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/ActivateChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          accountId: accountId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactiveAccount(
    { state, getters, commit, rootState, rootGetters },
    accountid
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ChartOfAccount/DeactivateChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          accountid: accountid,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  setBusinessLength({ commit }, businessLength) {
    commit("setBusinessLength", businessLength);
  },
};
const getters = {
  getBusinessLength(state) {
    return state.businessLength;
  },
  getOnCheckState(state) {
    return state.getOnCheckState;
  },
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state,
};
import store from "../index";
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          if (event.srcElement.status === 401) {
            store.commit("auth/setLoginExpired", true);
          } else if (event.srcElement.status === 403) {
            router.push({ name: "accessDenied" });
          } else if (event.srcElement.status === 500) {
            console.log(
              "Error 500",
              JSON.parse(event.srcElement.response).message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).message
            );
          } else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
