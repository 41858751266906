import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  products: [],
  productsCount: 0,
  items: [],
  itemsCount: 0,
  unpurchasedItems: [],
  unpurchasedItemsCount: 0,
  itemsForSale: [],
  itemsCountForSale: 0,
  vendors: [],
  vendorsCount: 0,
  payrollItems: [],
  payrollItemsCount: 0,
  categories: [],
  dropdownCategores: [],
  productsForDropdown: [],
  categoryCount: 0,
};
const mutations = {
  setCategories: (state, payload) => {
    state.categories = payload;
  },
  setProductsForDropdown(state, payload) {
    state.productsForDropdown = payload;
  },
  setdropdownCategores: (state, payload) => {
    state.dropdownCategores = payload;
  },
  setCategoryCount: (state, payload) => {
    state.categoryCount = payload;
  },
  setPayrollItem: (state, payload) => {
    state.payrollItems = payload;
  },
  setPayrollItemsCount: (state, payload) => {
    state.payrollItemsCount = payload;
  },
  setProducts: (state, payload) => {
    state.products = payload;
  },
  setProductsCount: (state, payload) => {
    state.productsCount = payload;
  },
  setItems: (state, payload) => {
    state.items = payload;
  },
  setItemsCount: (state, payload) => {
    state.itemsCount = payload;
  },
  setUnpurchasedItems: (state, payload) => {
    state.unpurchasedItems = payload;
  },
  setUnpurchasedItemsCount: (state, payload) => {
    state.unpurchasedItemsCount = payload;
  },
  setFAItemsForSale: (state, payload) => {
    state.itemsForSale = payload;
  },
  setFAItemsCountForSale: (state, payload) => {
    state.itemsCountForSale = payload;
  },
  setVendors: (state, payload) => {
    state.vendors = payload;
  },
  setVendorsCount: (state, payload) => {
    state.vendorsCount = payload;
  },
  setDropDowns(state, payload) {
    (state.products = payload),
      (state.productsCount = 0),
      (state.items = payload),
      (state.itemsCount = 0),
      (state.unpurchasedItems = payload),
      (state.unpurchasedItemsCount = 0),
      (state.itemsForSale = payload),
      (state.itemsCountForSale = 0),
      (state.vendors = payload),
      (state.vendorsCount = 0);
  },
};
const actions = {
  setDropDowns({commit },data){
    commit('setDropDowns',data)
  },
  dropdownCategories({  rootGetters,commit }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Category/ShowCategoryForDropDown",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword:data.keyword,
          offset:data.offset
        },
        null,
        options
      )
      .then((res) => {
        if(res.data.success.toLowerCase()=="ok"){
          commit('setCategories',res.data.data)
          commit('setCategoryCount',res.data.offsetAndTotalCount.totalCount)
      }else{
        commit('setCategories',[] )
        commit('setCategoryCount',0)
      }
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownPayrollItem({  rootGetters,commit }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Payroll/ShowPayrollItemsForDropDown",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then(res => {
              console.log('payroll dd dropdown',res.data.data)
        if(res.data.success.toLowerCase()=="ok"){
            commit('setPayrollItem',res.data.data)
            commit('setPayrollItemsCount',res.data.offsetAndTotalCount.totalCount)
        }else{
          commit('setPayrollItem',[] )
          commit('setPayrollItemsCount',0)
        }
      return res;
    })
    .catch(err => {
      return err;
    });
  },
    dropdownProducts({ state, getters, commit, rootState, rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Product/showproductsfordropdown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
              
              if(res.data.success.toLowerCase()=="ok"){
                  commit('setProductsForDropdown', res.data.data);
                  commit('setProducts', res.data.data);
                  commit('setProductsCount',res.data.offsetAndTotalCount.totalCount)
              }else{
                commit('setProducts',[] )
                commit('setProductsCount',0)
              }
            return res;
          })
          .catch(err => {
            return err;
          });
      },
    dropdownBranches({ state, getters, commit, rootState, rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Branch/ShowBranchesForDropDown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownCategories({ rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Category/ShowCategoryForDropdown",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownSalesman({ rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Employee/ShowSalesmanForDropdown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownEmployees({ rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Employee/ShowEmployeesForDropdown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownCustomers({rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Customer/ShowCustomersForDropDown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownVendors({ state, getters, commit, rootState, rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Vendor/ShowVendorsForDropDown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            console.log("in vendor response",res.data.success)
            if(res.data.success.toLowerCase()=="ok"){
              commit('setVendors',res.data.data)
              commit('setVendorsCount',res.data.offsetAndTotalCount.totalCount)
            }else{
              commit('setVendors',[] )
              commit('setVendorsCount',0)
            }
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      fixedAssetItemForPurchase({commit,rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ShowUnPurchasedFixedAsset",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            if(res.data.success=="OK"){
              commit('setUnpurchasedItems',res.data.data)
              commit('setUnpurchasedItemsCount',res.data.offsetAndTotalCount.totalCount)
            }else{
              commit('setUnpurchasedItems',[] )
              commit('setUnpurchasedItemsCount',0 )
            }
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      ShowFixedAssetForFASale({commit,rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ShowFixedAssetForFASale", 
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            if(res.data.success=="OK"){
              commit('setFAItemsForSale',res.data.data)
              commit('setFAItemsCountForSale',res.data.offsetAndTotalCount.totalCount)
            }else{
              commit('setFAItemsForSale',[] )
              commit('setFAItemsCountForSale',0 )
            }
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      dropdownFixedAssetItem({ state, getters, commit, rootState, rootGetters },data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ShowFixedAssetForDropDown",
            {
              businessId: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              keyword:data.keyword,
              offset:data.offset
            },
            null,
            options
          )
          .then(res => {
            if(res.data.success.toLowerCase()=="ok"){
              commit('setItems',res.data.data)
              commit('setItemsCount',res.data.offsetAndTotalCount.totalCount)
            }else{
              commit('setItems',[] )
              commit('setItemsCount',0 )
            }
            return res;
          })
          .catch(err => {
            return err;
          });
      }
};
const getters = {
  getdropdownCategores: state => {
    return state.dropdownCategores;
  },
  getNewProductsForDropdown: state => {
    return state.productsForDropdown;
  },
  getCategories(state) {
    return state.categories;
  },
  getCategoryCount(state) {
    return state.categoryCount;
  },
  getPayrollItems(state) {
    return state.payrollItems;
  },
  getPayrollItemsCount(state) {
    return state.payrollItemsCount;
  },
  getProducts(state) {
    return state.products;
  },
  getProductsCount(state) {
    return state.productsCount;
  },
  getUnpurchasedItems(state) {
    return state.unpurchasedItems;
  },
  getUnpurchasedItemsCount(state) {
    return state.unpurchasedItemsCount;
  },
  getFAItemsForSale(state) {
    return state.itemsForSale;
  },
  getFAItemsCountsForSale(state) {
    return state.itemsCountForSale;
  },
  getItems(state) {
    return state.items;
  },
  getItemsCount(state) {
    return state.itemsCount;
  },
  getVendors(state) {
    return state.vendors;
  },
  getVendorsCount(state) {
    return state.vendorsCount;
  },
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers
      })
        .then(response => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch(error => {
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          }else if(event.srcElement.status ===403){
            router.push({name:'accessDenied'})
          }
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          } else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed."
          }
        };
        resolve({
          error: true,
          message: "server request Failed."
        });
        reject(error);
      });
    }
  }
  /* ----------------- ---------- -------- -------------------- */
};
