<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="payrollOptions"
      :search-input.sync="keyword"
      no-data-text="No Item Available"
      item-text="itemName"
      item-value="itemID"
      clearable
      dense
      hide-selected
      @click:clear="clearData"
      :return-object="true"
      :label="itemLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @change="valueChanged($event)"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>

    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false"  v-bind="attrs"> <v-icon>mdi-close</v-icon> </v-btn>
    </template>
    </v-snackbar>
  </div>
</template>

<script>
import centralizedValues from "../../assets/js/centralizedValues";
export default {
  props: {
    isMultiple: {
      default: false,
    },
    operation: {
      default: "add",
    },
    index: {
      default: null,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: null,
    },
    itemLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: true,
    },
    updatePayrollItem: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      timeout: centralizedValues.timeout,
      itemName: null,
      productForm: false,
      payrollOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updatePayrollItem,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      count: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   payrollOptions: [],
    };
  },

  async created() {
    this.checkPayrollItems();
    // alert(this.$store.getters['dropdowns/getProducts'](this.updatePayrollItemData))
  },
  methods: {
    viewMainProducts() {
      //when updatePayrollItem is cleared from dropdown get all prodycts without sending request
      this.payrollOptions = [];
      this.payrollOptions = [
        ...this.$store.getters["dropdowns/getPayrollItems"],
      ];
      this.totalData = this.$store.getters["dropdowns/getPayrollItemsCount"];
    },
    checkPayrollItems() {
      if (this.updatePayrollItem && this.operation != "add")
        this.payrollOptions.push(this.updatePayrollItem);
      else {
        this.payrollOptions = [
          ...this.$store.getters["dropdowns/getPayrollItems"],
        ];
        this.totalData = this.$store.getters["dropdowns/getPayrollItemsCount"];
      }
    },
    async closeModal(type) {
      if (type == "success") {
        await this.$store
          .dispatch("dropdowns/dropdownPayrollItem", { keyword: "", offset: 0 })
          .then((res) => {
            this.checkPayrollItems();
          });
      }
      this.productForm = false;
    },
    showSnack(msg, color) {
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    async clearData() {
      this.count = -1;
      this.payrollOptions = [];
      await this.closeModal("success");
      this.model = "";
      this.itemName = null;
      this.$emit("payrollSelected", null);
    },
    valueChanged(e) {
      // console.log("this.model.itemName",e)
      if (!e) {
        this.itemName = null;
        this.$emit("payrollSelected", null);
        return;
      }
      this.itemName = e.itemName;
      if (this.returnObject == false) {
        this.$emit("payrollSelected", e.itemID);
      } else {
        this.$emit("payrollSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllPayrollItems();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.payrollOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllPayrollItems();
    },
    getAllPayrollItems() {
      if (this.updatePayrollItem != null && this.updatePayrollItem != "") {
        let updatePayrollItem = this.updatePayrollItem;
        this.payrollOptions.push(updatePayrollItem);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;
      this.$store
        .dispatch("dropdowns/dropdownPayrollItem", {
          keyword: this.keyword,
          offset: this.offset,
        })
        .then((res) => {
          console.log("PRODUCTS FOR DROPDOWN", res);
          if (res.data.success.toLowerCase() == "ok") {
            let oldData = this.payrollOptions;
            let resData = res.data.data;
            console.log("res.data.data", res.data.data);
            this.payrollOptions = [...oldData, ...resData];
            this.totalData = res.data.offsetAndTotalCount.totalCount;
          }
        })
        .finally(() => {
          if (sendingKeyword != this.keyword) {
            this.payrollOptions = [];
            this.offset = 0;
            (this.isLoading = true), this.getAllPayrollItems();
          } else {
            this.isLoading = false;
          }
        });
    },
  },
  watch: {
    immediate: false,
    keyword(val) {
      this.count += 1;
      if (this.updatePayrollItem) {
        if (val && val == this.updatePayrollItem.itemName) {
          return;
        } //prevent searching selected product
      }
      if (val == this.itemName && this.itemName != null) {
        //dont search selected product again
        return;
      }
      if (!val && this.count < 2) {
        //prevent first empty search
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updatePayrollItem: function (val) {
      if (val) {
        this.payrollOptions.push(val);
        this.itemName = val.itemName;
      } else this.itemName = val;
      this.count = 0;
      this.model = val;
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped></style>
