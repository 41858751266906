<template>
  <div>
    <v-card>
      <v-card-title>Add New Category

        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="
          closeCategory();
        loading = false;
        ">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-text-field :error-messages="categoryNameErrors" label="Category Name *" type="text" filled
              v-model.trim="$v.categoryDetails.categoryName.$model" autocomplete="off" />
          </v-col>
        </v-row>
      </v-card-text>
   
    <v-divider></v-divider>
    <v-card-actions class="mx-2">
      <v-btn :loading="loading" color="primary" text @click.prevent="addCategory"><v-icon
          left>mdi-content-save-outline</v-icon>Save</v-btn>
      <v-btn color="error" text @click="
        closeCategory();
      loading = false;
      "><v-icon left>mdi-cancel</v-icon> Cancel</v-btn>
    </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import centralizedValues from "../../assets/js/centralizedValues";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["getAllCategories", "closeCategory", "showCatgSnack"],
  data() {
    return {
      loading: false,
      categoryDetails: {
        categoryName: "",
      },
    };
  },
  computed: {
    categoryNameErrors() {
      const errors = [];
      if (!this.$v.categoryDetails.categoryName.$dirty) return errors;
      !this.$v.categoryDetails.categoryName.required &&
        errors.push("Category name is required");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    categoryDetails: {
      categoryName: {
        required,
      },
    },
  },
  methods: {
    addCategory(type) {
      this.resErrors = [];
      this.$v.$touch();

      if (this.$v.categoryDetails.$anyError) {
        return;
      }
      this.loading = true;
      console.log("details", this.categoryDetails);
      this.$store
        .dispatch("setupItem/addCategory", this.categoryDetails)
        .then((res) => {
          console.log("res is", res);
          if (res.data.success == "OK") {
            this.loading = false;
            this.categoryDetails.categoryName = "";
            this.$v.categoryDetails.categoryName.$reset();
            this.closeCategory("success");
            this.getAllCategories();
            this.showCatgSnack(res.data.response[0].message, "success");
            // this.showAddSnackbar(this.categoryDetails.categoryName)
            // this.addMainCategory = false;
            // this.AddCategorydialog = false;
            // this.$v.$reset();
            // this.categoryDetails.parentCategoryId = null;
            // this.categoryDetails.categoryName = "";
            // this.categoryDetails.categoryDescription = "";
            // this.tableData = [];
            // this.getAllCategories();
          } else {
            this.loading = false;
            this.getAllCategories();
            this.showCatgSnack(res.data.response[0].message, "error");
            // this.addMainCategory = false;
            // this.AddCategorydialog = false;
            // let errResponse = res.data.response;
            // for (let i = 0; i < errResponse.length; i++) {
            //   this.resErrors.push(errResponse[i].message);
          }
          //     document
          //       .getElementById("res")
          //       .scrollIntoView({ block: "start", inline: "start" });
          //   }
        })
        .catch((err) => {
          this.loading = false;
          //   this.addMainCategory = false;
          //   this.AddCategorydialog = false;
          this.resErrors = [];
          this.resErrors.push("Something went wrong, Pleaase try again later");
          document
            .getElementById("res")
            .scrollIntoView({ block: "start", inline: "start" });
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
