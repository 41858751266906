<template>
  <v-app>
    <!-- <TopNav/> -->
    <v-main class="mainBackground">
      <!-- vue-router -->
      <router-view></router-view>
    </v-main>
    <!-- <footer-component/> -->
    <v-footer
    color="footerBgColor footerTextColor--text subtitle-2 d-print-none"
      class="text-center"
      padless
    >
      <v-row no-gutters>
        <v-col class="text-center pa-1" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>R-Sheen Group of Companies</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>

import TopNav from "@/layouts/containers/topNav.vue";
export default {
  components: {
    TopNav,
  },
};
</script>
<style lang="scss" scoped>

</style>
