export default{
    timeout:6000,           //snackbar timeout
    maxRows:100,            //total number of rows to be added in table
    menuItemId:[
        {name:'User',id:1},
        {name:'Business' ,id: 2},
        {name:'companysettings' ,id: 2},
        {name:'branches',id: 3},
        {name:'customer',id: 4},
        {name:'vendor',id: 5},
        {name:'employee',id: 6},
        {name:'categories',id: 7},
        {name:'chartofaccount',id: 8},
        {name:'purchasebill',id: 9},
        {name:'sale',id: 10},
        {name:'managebank',id: 11},
        {name:'payrollitem',id: 12},
        {name:'payrun',id: 13},
        {name:'setuptax',id: 14},
        {name:'manualjournal',id: 15},
        {name:'specialjournal',id: 16},
        {name:'fixedassetitem',id: 17},
        {name:'fixedassetpurchase',id: 18},
        {name:'fixedassetsale',id: 19},
        {name:'BranchRight',id: 20},
        {name:'COARight',id : 21},
        {name:'RoleRight',id : 22},
        {name:'products',id : 23},
        {name:'Groups',id : 24}
    ]
}