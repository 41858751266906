<template>
  <div>
    <view-group
      v-if="showcomponent == 'viewGroup'"
      :changeview="changeview"
      :moduleName="moduleName"
      :refernceID="refernceID"
      :vendorItems="vendorItems"
      :getGroups="getGroups"
      :getTableData="getTableData"
      :showGroupSnack="showGroupSnack"
      :closeGroupDialog="closeGroupDialog"
      :showUpdateDialog="showUpdateDialog"
      :showGroupColumn="showGroupColumn"
      @gpID="groupID = $event"
    />
    <update-group
      v-if="showcomponent == 'updateGroup'"
      :changeview="changeview"
      :moduleName="moduleName"
      :showGroupSnack="showGroupSnack"
      :closeGroupDialog="closeGroupDialog"
      :getTableData="getTableData"
      :gpID="groupID"
    />
  </div>
</template>

<script>
import viewGroup from "@/components/Groups/viewGroup";
import updateGroup from "@/components/Groups/updateGroup";
export default {
  props: [
    "moduleName",
    "vendorItems",
    "refernceID",
    "closeModal",
    "showUpdateDialog",
    "getGroups",
    "showGroupSnack",
    "getTableData",
    "closeGroupDialog",
    "showGroupColumn",
  ],
  data() {
    return {
      groupID: {},
      showcomponent: "viewGroup",
      name: "",
      message: "",
    };
  },
  methods: {
    changeview(component) {
      this.showcomponent = component;
    },
  },
  components: {
    viewGroup: viewGroup,
    updateGroup: updateGroup,
  },
};
</script>

<style lang="scss" scoped></style>
