import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import menuItemId from "../../assets/js/centralizedValues"
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  editVendor: null,
  selectBusiness: null,
  userrights: null,
  previousRoute:null,
};
const mutations = {
  setSelectBusiness(state, payload) {
    state.selectBusiness = payload;
  },
  setUserRights(state, payload) {
    state.userrights = payload;
  },
  setBranch(state, payload) {
    state.selectBusiness.branchName = payload.branchName;
    state.selectBusiness.branchId = payload.branchId;
    state.selectBusiness.isHeadOffice = payload.isHeadOffice;
  },
  setpreviousRoute(state, payload){
    state.previousRoute=payload
  },
  setBusinessName(state, payload) {
    if (
      (state.selectBusiness != "" || state.selectBusiness != null) &&
      payload != ""
    ) {
      state.selectBusiness.businessName = payload;
    }
  },
  setBusinessLogo(state, payload) {
    if (
      (state.selectBusiness != "" || state.selectBusiness != null) &&
      payload != ""
    ) {
      state.selectBusiness.logo = payload;
    }
  }
};

const actions = {
  viewuserdetails({ rootGetters }){
    console.log('viewuserdetails')
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
    .make("GET", "/User/viewuserdetails", 
    {
      businessId: selectedBusiness.businessId,
      branchId: selectedBusiness.branchId
    },
     null,
     options)
    .then(response => {
      console.log("VIEWUSERDETAISL",response)
      return response;
    })
    .catch(err => {
      return err;
    });
  },
  viewPackage({ getters,rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    //let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Package/viewPackage",
        {
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewBusinessPackage({ rootGetters }){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "BusinessPackage/ViewBusinessPackage",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  }
};
export default {
  namespaced,
  plugins,
  actions,
  mutations,
  state,
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          console.log('event',event)
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          }else if(event.srcElement.status === 403){
            console.log('Forbidden',event.srcElement.status)
            router.push({name:'accessDenied'})
          }
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          } else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
