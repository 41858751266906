<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="getNewProductsForDropdown"
      :search-input.sync="keyword"
      no-data-text="No Product Available"
      item-text="productName"
      item-value="productID"
      clearable
      dense
      hide-selected
      append-icon="mdi-plus"
      @click:append="productForm = true"
      @click:clear="clearData"
      :return-object="true"
      :label="productLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @change="valueChanged($event)"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="productForm" max-width="400px" persistent>
      <add-new-product :closeModal="closeModal" :showSnack="showSnack" />
    </v-dialog>
    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <v-btn icon @click="snackbar = false"> <v-icon>mdi-close</v-icon> </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import addNewProduct from "../miniComponent/addProduct";
import { mapGetters, mapMutations } from "vuex";
import centralizedValues from "../../assets/js/centralizedValues";
export default {
  components: {
    addNewProduct,
  },
  props: {
    isMultiple: {
      default: false,
    },
    operation: {
      default: "add",
    },
    setProduct: {
      default: null,
    },
    index: {
      default: null,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: null,
    },
    productLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: true,
    },
    updateProduct: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      timeout: centralizedValues.timeout,
      productName: null,
      productForm: false,
      productsOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateProduct,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      count: 0,
      userRights: null,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   productsOptions: [],
    };
  },

  async created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 23 && val.displayPermission) {
        this.getAllProduct();
      }
    });
    this.checkProducts();
    // this.getAllProduct();
    // alert(this.$store.getters['dropdowns/getProducts'](this.updateProductData)) getNewProductsForDropdown
  },
  computed: {
    ...mapGetters("dropdowns", ["getNewProductsForDropdown"]),
  },
  methods: {
    viewMainProducts() {
      //when updateProduct is cleared from dropdown get all prodycts without sending request
      this.productsOptions = [];
      this.productsOptions = [...this.$store.getters["dropdowns/getProducts"]];
      this.totalData = this.$store.getters["dropdowns/getProductsCount"];
    },
    checkProducts() {
      if (this.updateProduct && this.operation != "add") {
        this.productsOptions.push(this.updateProduct);
      } else {
        this.productsOptions = [
          ...this.$store.getters["dropdowns/getProducts"],
        ];
        this.totalData = this.$store.getters["dropdowns/getProductsCount"];
      }
    },
    closeModal(type) {
      if (type == "success") {
        this.$store
          .dispatch("dropdowns/dropdownProducts", { keyword: "", offset: 0 })
          .then((res) => {
            this.checkProducts();
          });
      }
      this.productForm = false;
    },
    showSnack(msg, color) {
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    async clearData() {
      this.count = -1;
      this.productsOptions = [];
      this.viewMainProducts();
      this.model = "";
      this.productName = null;
      this.$emit("productSelected", null);
    },
    valueChanged(e) {
      // console.log("this.model.productName",e)
      if (!e) {
        this.productName = null;
        this.$emit("productSelected", null);
        return;
      }
      this.productName = e.productName;
      if (this.returnObject == false) {
        console.log("OKok Selected", e.productID);
        this.$emit("productSelected", e.productID);
      } else {
        console.log("OKok Selected else...", e);
        this.$emit("productSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllProduct();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.productsOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllProduct();
    },
    getAllProduct() {
      if (this.updateProduct != null && this.updateProduct != "") {
        let updateProduct = this.updateProduct;
        this.productsOptions.push(updateProduct);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;
      this.$store
        .dispatch("setupItem/dropdownProducts", {
          keyword: this.keyword,
          offset: this.offset,
        })
        .then((res) => {
          console.log("PRODUCTS FOR DROPDOWN", res);
          if (res.data.success.toLowerCase() == "ok") {
            let oldData = this.productsOptions;
            let resData = res.data.data;
            console.log("res.data.data products", res.data.data);
            this.productsOptions = [...oldData, ...resData];
            this.$store.commit(
              "dropdowns/setProductsForDropdown",
              this.productsOptions
            );
            this.totalData = res.data.offsetAndTotalCount.totalCount;
          }
        })
        .finally(() => {
          if (sendingKeyword != this.keyword) {
            this.productsOptions = [];
            this.offset = 0;
            this.isLoading = false;
            // , this.getAllProduct();
          } else {
            this.isLoading = false;
          }
        });
    },
  },

  watch: {
    immediate: false,
    keyword(val) {
      this.count += 1;
      if (this.updateProduct) {
        if (val && val == this.updateProduct.productName) {
          return;
        } //prevent searching selected product
      }
      if (val == this.productName && this.productName != null) {
        //dont search selected product again
        return;
      }
      if (!val && this.count < 2) {
        //prevent first empty search
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updateProduct: function (val) {
      if (val) {
        this.productsOptions.push(val);
        this.productName = val.productName;
      } else this.productName = val;
      this.count = 0;
      this.model = val;
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped></style>
