import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
 
};
const mutations = {
  
};
const actions = {
    ViewBranchRight({rootGetters },data) {
        
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BranchRights/viewbranchrights",
        {
          businessID: selectedBusiness.businessId,
          branchID:data
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  UpdateBranchRight({rootGetters },data) {
        
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/BranchRights/updatebranchrights",
        {
          businessID: selectedBusiness.businessId,
          branchID:data[0].branchID
        },
        data,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
};
const getters = {
    
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers
      })
        .then(response => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch(error => {
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          } else if(event.srcElement.status ===403){
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).response[0].message)
            router.push({name:'accessDenied'})
          }
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          }else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed."
          }
        };
        resolve({
          error: true,
          message: "server request Failed."
        });
        reject(error);
      });
    }
  }
  /* ----------------- ---------- -------- -------------------- */
};
