<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="branchOptions"
      :search-input.sync="keyword"
      no-data-text="No Branches Available"
      item-text="branchName"
      item-value="branchID"
      clearable
      dense
      ref="select"
      hide-selected
      :return-object="true"
      :label="branchLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @click:append="CustomerForm = true"
      @click:clear="clearData"
      @change="valueChanged($event)"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="CustomerForm" max-width="450px" persistent>
      <add-new-customer
        @addedCustomer="keyword = $event"
        :closeModal="closeModal"
      />
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    isMultiple: {
      default: false,
    },
    clickEvent: {
      default: "",
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: [],
    },
    branchLabel: {
      default: "",
    },
    isfilled: {
      default: true,
    },
    isOutlined: {
      default: false,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    returnObject: {
      default: false,
    },
    updateBranch: {
      default: null,
    },
  },
  data() {
    return {
      branchName: null,
      branchOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateBranch,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   branchOptions: [],
    };
  },
  created() {},
  methods: {
    clearData() {
      this.model = "";
      this.$emit("branchSelected", null);
    },
    valueChanged(e) {
      this.branchName = e.branchName;
      if (this.returnObject == false) {
        this.$emit("branchSelected", e.branchID);
      } else {
        this.$emit("branchSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllBranches();
    },
    sendNewRequest() {
      this.$refs.select.menuIsActive = false;
      if (this.isLoading == true) {
        return;
      }
      this.branchOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllBranches();
    },
    getAllBranches() {
      if (this.updateBranch != null && this.updateBranch != "") {
        let updateBranch = this.updateBranch;
        this.branchOptions.push(updateBranch);
      }
      let sendingKeyword = this.keyword;
      this.$store
        .dispatch("setupItem/dropdownBranches", {
          keyword: this.keyword,
          offset: this.offset,
        })
        .then((res) => {
          if (res.data.success == "OK") {
            let oldData = this.branchOptions;
            let resData = res.data.data;
            this.branchOptions = [...oldData, ...resData];
            this.totalData = res.data.offsetAndTotalCount.totalCount;
          }
        })
        .finally(() => {
          if (sendingKeyword != this.keyword) {
            this.$refs.select.menuIsActive = false;
            this.branchOptions = [];
            this.offset = 0;
            (this.isLoading = true), this.getAllBranches();
          } else {
            this.isLoading = false;
            this.$refs.select.menuIsActive = false;
          }
        });
    },
  },
  watch: {
    keyword(val) {
      if (val == this.branchName && this.branchName != null) {
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updateBranch: function (val) {
      this.model = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
