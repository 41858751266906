<template>
  <div>
    <v-card>
      <v-card-title
        >Add New Customer
        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="closeModal(), (loading = false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-text-field
              label="Customer Full Name *"
              type="text"
              :error-messages="CustomerFullNameErrors"
              v-model.trim="$v.form.CustomerFullName.$model"
              :counter="counterOne"
              filled
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-text-field
              label="NIC/SSN *"
              type="text"
              :error-messages="CustomerSSNErrors"
              v-model.trim="$v.form.CustomerSSN.$model"
              filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" :loading="loading" text @click="addCustomer"
          ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
        >
        <v-btn color="error" text @click="closeModal(), (loading = false)"
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import mainValidations from "../../assets/js/validations";
import errorMessages from "@/assets/js/errorMessages";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["showMiniComponent", "getAllCustomer", "closeModal", "showSnack"],
  data() {
    return {
      loading: false,
      counterOne: mainValidations.length1,
      form: {
        CustomerFullName: "",
        CustomerSSN: "",
      },
    };
  },
  computed: {
    CustomerFullNameErrors() {
      const errors = [];
      if (!this.$v.form.CustomerFullName.$dirty) return errors;
      !this.$v.form.CustomerFullName.required &&
        errors.push(errorMessages.requiredMessages("Customer Name"));
      !this.$v.form.CustomerFullName.checkNameLength &&
        errors.push(
          errorMessages.lengthMessages("Customer Name", mainValidations.length1)
        );
      return errors;
    },
    CustomerSSNErrors() {
      const errors = [];
      if (!this.$v.form.CustomerSSN.$dirty) return errors;
      !this.$v.form.CustomerSSN.SSNExists &&
        errors.push("SSN number already exists");
      !this.$v.form.CustomerSSN.required &&
        errors.push("This field is required");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      CustomerFullName: {
        required,
        checkNameLength: (val) => {
          return mainValidations.nameLength(val);
        },
      },
      CustomerSSN: {
        required,
        SSNExists: (val) => {
          if (val == "") {
            return true;
          } else {
            return true;
          }
        },
      },
    },
  },
  methods: {
    addCustomer() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        window.scrollTo(0, 0);
        return;
      }
      this.loading = true;
      this.resErrors = [];
      const formdata = new FormData();
      formdata.append("CustomerFullName", this.form.CustomerFullName);
      formdata.append("CustomerSSN", this.form.CustomerSSN);
      this.$store
        .dispatch("setupItem/addCustomer", formdata)
        .then((res) => {
          console.log(res);
          if (res.data.success == "OK") {
            this.loading = false;
            this.$emit("addedCustomer", this.form.CustomerFullName);
            this.closeModal();
            this.form.CustomerFullName = "";
            this.form.CustomerSSN = "";
            this.$v.$reset();
            this.showSnack(res.data.response[0].message, "success");
          } else {
            this.showSnack(res.data.response[0].message, "error");
          }
        })
        .catch((error) => {
          this.resErrors = [];
          this.resErrors.push("Something went wrong. Try again later");
          this.loading = false;
          // document
          //   .getElementById("res")
          //   .scrollIntoView({ block: "start", inline: "start" });
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
