import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
message:""
};
const mutations = {
  snackbarMessage(state, payload){
    state.message=payload
    console.log("dvnbdfbdfb",state.message)
  }
};
const actions = {
  fixedAssetForPurchase({rootGetters },data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/FixedAsset/ShowUnPurchasedFixedAsset",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword:data.keyword,
          offset:data.offset
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  dropdownFixedAssetItem({ state, getters, commit, rootState, rootGetters },data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/FixedAsset/ShowFixedAssetForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword:data.keyword,
          offset:data.offset
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  // Fixed Asset Sale
  addFASale({  rootGetters }, formdata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/formdata',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FASale/AddFASale",
        {
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId
        },
        formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  deleteAssetMultipleData({ state, getters, commit, rootState, rootGetters }, formData) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/DeActivateMultipleFixedAsset",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
        },
        formData,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  // checkAssetNameExists
  checkAssetNameExists({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/CheckFixedAssetName",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          faName: payload
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  // DeactivateFASaleInvoice
  DeactivateFASaleInvoice({ rootGetters }, faSaleID){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FASale/DeactivateFASale",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          faSaleID:faSaleID
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  // activate multiple fixed asset item
  ActivateFixedAssetMultipleItems({ state, getters, commit, rootState, rootGetters }, formData) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/ActivateMultipleFixedAsset",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
        },
        formData,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },

  // Deactivate FA Revaluation
  DeactiveFARevaluation({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Revaluation/DeactiveFARevaluation",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          favID:payload
        },
       null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },

  // deactive depreciation history
  deactivateDepreciation({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/DeActivateDepreciation",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          depID:payload
        },
       null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  
  // active depreciation history
  activateDepreciation({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/ActivateDepreciation",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          depID:payload
        },
       null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  
  // Activate Revaluation
  ActiveFARevaluation({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Revaluation/ActiveFARevaluation",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          favID:payload
        },
       null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },

  // AddFixedDepreciation
  AddFixedDepreciation({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/FixedAsset/AddFixedDepreciation",
        { 
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId, 
          fixedAssetID:payload.FAssetID,
          consumedUnits: payload.consumedUnits,
          percentage: payload.percentage,
          date: payload.date,
          description: payload.description,
          // amount: payload.amount
        },
       null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },

  exportAdvisorFixedAssetItem({state, getters, commit, rootState, rootGetters}, fileType){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "",
        { 
          businessId: selectedBusiness.businessId,
           branchId: selectedBusiness.branchId,fileType: 
           fileType, 
           ID:'' },
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportMJ({rootGetters}, payload){
    let mjID
    if(payload.mjID) mjID={id:payload.mjID}
    else mjID={}
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManualJournal/ExportMJ",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: payload.fileType, 
          // mjID: payload.mjID,
          fromDate:payload.fromDate,
          toDate:payload.toDate
          },
          mjID,
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSJ({ rootGetters}, payload){
    console.log("Payload",payload)
    let sjID
    if(payload.sjID) sjID={id:payload.sjID}
    else sjID={}
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "SpecialJournal/ExportSJ",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: payload.fileType, 
          // sjID: payload.sjID,
          fromDate:payload.fromDate,
          toDate:payload.toDate
          },
          sjID,
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  exportAdvisorSetUpTax({state, getters, commit, rootState, rootGetters}, fileType){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "",
        { 
          businessId: selectedBusiness.businessId,
           branchId: selectedBusiness.branchId,
           fileType: fileType, 
           ID:'' },
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedFixedAssetItem({state, getters, commit, rootState, rootGetters}, formData){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType, 
          payrollID:  formData.payrollID
      }, 
        {columns: formData.columns},
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedMJ({state, getters, commit, rootState, rootGetters}, formData){
    console.log("FORMDATA RECEIVED",formData)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ManualJournal/ExportMJ",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType, 
          mjID:  formData.mjID
          
      }, 
        {columns: formData.columns},
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedSJ({state, getters, commit, rootState, rootGetters}, formData){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/SpecialJournal/ExportSJ",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType, 
          sjID:  formData.sjID
      }, 
        {columns: formData.columns},
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  searchFaSale({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/FASale/SearchFASale",
        {
          customerID:payload.customerID,
          saleDateFrom:payload.saleDateFrom,
          saleDateTo:payload.saleDateTo,
          businessID: selectedBusiness.businessId,
          branchID:  selectedBusiness.branchId,
          limit:payload.limit,
          pageNum:payload.pageNum
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  ViewFASale({ rootGetters }, faSaleID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/FASale/ViewFASale",
        {
          faSaleID:faSaleID,
          businessID: selectedBusiness.businessId,
          branchID:  selectedBusiness.branchId,
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
 ViewFASaleByInvoice({ rootGetters }, payload) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/FASale/ViewFASaleByInvoice",
      {
        businessID: selectedBusiness.businessId,
        branchID:payload.branchID?payload.branchID:selectedBusiness.branchId,
        // branchID: selectedBusiness.branchId,
        invoiceNum:payload.invoiceNo,
        invoiceType:payload.invoiceType
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
 },
  UpdateFASale({  rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/formdata',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/FASale/UpdateFASale",
        {
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId,
          faSaleID:payload.faSaleID
        },
        payload.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  importSJ({  rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/formdata',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/SpecialJournal/ImportSJ",
        {
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId,
          fileType:data.fileType
        },
        data.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  importMJ({  rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/formdata',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManualJournal/ImportMJ",
        {
          businessID: selectedBusiness.businessId,
          branchID:selectedBusiness.branchId,
          fileType:data.fileType
        },
        data.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  addManualJournal({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManualJournal/AddManualJournal",
        { businessID: selectedBusiness.businessId, 
          branchID: selectedBusiness.branchId,
          
        },
        payload,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
//Activate Manual Journal
ActivateManualJournal({ rootGetters }, manualJournalID) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "POST",
      "ManualJournal/ActivateManualJournal",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        manualJournalID:manualJournalID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
//Deactivate Manual Journal
DeactivateManualJournal({ rootGetters }, manualJournalID) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "POST",
      "/ManualJournal/DeactivateManualJournal",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        manualJournalID:manualJournalID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
//view Manual Journal
viewManualJournal({ rootGetters }, manualJournalID) {
  console.log('id received here',manualJournalID)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/ManualJournal/ViewManualJournal",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        manualJournalID:manualJournalID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
ViewManualJournalByInvoice({ rootGetters }, payload) {
  console.log('payload received here',payload)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/ManualJournal/ViewManualJournalByInvoice",
      {
        businessID: selectedBusiness.businessId,
        branchID: payload.branchID ? payload.branchID :selectedBusiness.branchId,
        invoiceNum:payload.invoiceNo,
        invoiceType:payload.invoiceType
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
// search Manual Jouranl 
SearchManualJournal({ rootGetters }, searchData) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/ManualJournal/SearchManualJournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        MJFromDate:searchData.dateFrom,
        MJToDate:searchData.toDate,
        limit:searchData.limit,
        pageNum:searchData.pageNum
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
// SearchMJ Invoices
SearchInvoices({ rootGetters }, searchData) {

console.warn("Hello InvoicesApi Called....",searchData)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/Common/SearchInvoices",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        invoiceType:searchData.invoiceType,
         limit:searchData.limit,
         pageNum:searchData.pageNum,
        importedInvoiceNum:searchData.importedInvoiceNum,
        invoiceNum:searchData.InvoiceNum,

      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},


//Update Manual Journal
updateManualJournal({ rootGetters }, payload) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"],
      'Content-Type':'multipart/form-data',
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "PUT",
      "/ManualJournal/UpdateManualJournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        MJID:payload.MJID
      },
      payload.formdata,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
//Special JOURNAL ACTIONS
addSpecialJournal({ rootGetters }, payload) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"],
      'Content-Type':'multipart/formdata',
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "POST",
      "/SpecialJournal/AddSpecialJournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId
      },
      payload,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},

// search Special Jouranl 
SearchSpecialJournal({ rootGetters }, searchData) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/SpecialJournal/searchspecialjournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        SJFromDate:searchData.dateFrom,
        SJToDate:searchData.toDate,
        limit:searchData.limit,
        pageNum:searchData.pageNum
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
// View Special Jouranl 
viewSpecialJournal({ rootGetters }, specialJournalID) {
  console.log('id received is',specialJournalID)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/SpecialJournal/ViewSpecialJournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        specialJournalID:specialJournalID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
ViewSpecialJournalByInvoice({ rootGetters }, payload) {
  console.log('payload received here',payload)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/SpecialJournal/ViewSpecialJournalByInvoice",
      {
        businessID: selectedBusiness.businessId,
        // branchID: selectedBusiness.branchId,
        branchID: payload.branchID ? payload.branchID :selectedBusiness.branchId,
        invoiceNum:payload.invoiceNo,
        invoiceType:payload.invoiceType
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
// update Special Jouranl 
updateSpecialJournal({ rootGetters }, data) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"],
      'Content-Type':'mutlipart/form-data',
      
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "PUT",
      "/SpecialJournal/UpdateSpecialJournal",
      { businessID: selectedBusiness.businessId, 
        branchID: selectedBusiness.branchId,
        SJID:data.SJID
      },
      data.formdata,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
DeactivateSpecialJournal({ rootGetters }, specialJournalID) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "POST",
      "/SpecialJournal/DeactiveSpecialJournal",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        specialJournalID:specialJournalID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
    activateTax({ state, getters, commit, rootState, rootGetters }, taxID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/TaxComponent/ActivateTax",
            { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,taxID:taxID },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
    deactiveTax({ state, getters, commit, rootState, rootGetters }, taxID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/TaxComponent/DeActivateTax",
            { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,taxID:taxID },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      ShowTaxForDropDown({ state, getters, commit, rootState, rootGetters }) {
        const options = {
        headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
        }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
        .make(
        "GET",
        "/TaxComponent/ShowTaxForDropDown",
        {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId
        },
        null,
        options
        )
        .then(res => {
        return res;
        })
        .catch(err => {
        return err;
        });
        },
    getTax({ state, getters, commit, rootState, rootGetters }, taxID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/TaxComponent/ViewTax",
            {
              businessId: selectedBusiness.businessId,
              branchId: selectedBusiness.branchId,
              taxID:taxID
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      editSetupTax({ state, getters, commit, rootState, rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        let data=JSON.stringify(payload.data);
        return request
          .make(
            "PUT",
            "/TaxComponent/UpdateTax",
            { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,taxID:payload.taxId },
            data,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
    addSetupTax({ state, getters, commit, rootState, rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        let data=JSON.stringify(payload);
        return request
          .make(
            "POST",
            "/TaxComponent/AddTax",
            { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId },
            data,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      addcoaRight({ state, getters, commit, rootState, rootGetters }, data) {
        let coaRightData=JSON.stringify(data);
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        
        return request
          .make(
            "POST",
            "/CoaRights/addcoarights",
            { 
      
              businessId: selectedBusiness.businessId,
              branchId: selectedBusiness.branchId
            },
            coaRightData,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      addFixedItem({ state, getters, commit, rootState, rootGetters }, data) {
        let fixedData=JSON.stringify(data);
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/FixedAsset/AddFixedAsset",
            { 
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId
            },
            fixedData,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },

      // Add Revaluation
      AddFARevaluation({ state, getters, commit, rootState, rootGetters }, data) {
        let fixedData=JSON.stringify(data);
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/Revaluation/AddFARevaluation",
            { 
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId
            },
            data,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },

      // Get Revaluation
      ViewFARevaluation({ state, getters, commit, rootState, rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Revaluation/ViewFARevaluation",
            { 
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              faID: payload.faID,
              limit:payload.limit,
              pageNum:payload.pageNum
            },
           null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },

      editFixedAssetItem({ state, getters, commit, rootState, rootGetters }, data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'application/json',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "PUT",
            "/FixedAsset/UpdateFixedAsset",
              {
                businessID: selectedBusiness.businessId,
                branchID: selectedBusiness.branchId,
                fixedAssetID:data.fixedAssetID
              },
                data,
                options
           )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      updateFixedAssetByGroup({rootGetters }, data) {
        console.warn("data",data)
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            "Content-Type": "application/json-patch+json",
          },
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "PATCH",
            "/FixedAsset/UpdateFixedAssetByGroup",
            {
              businessID: selectedBusiness.businessId,
              groupID: data.groupID,
              branchID: selectedBusiness.branchId,
            },
            data.data,
            options
          )
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          });
      },
      updateCOARights({rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "PUT",
            "/CoaRights/updatecoarights",
            { 
              businessId: selectedBusiness.businessId,
              branchId:selectedBusiness.branchId, 
              coaRightID: payload.coaRightID, 
              isCoaRightsAllowed:payload.isCoaRightsAllowed
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      deleteCOAright({ state, getters, commit, rootState, rootGetters }, COArightId) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/CoaRights/deactivecoarights",
            { 
              businessId: selectedBusiness.businessId,
              branchId:selectedBusiness.branchId, 
              coaRightID: COArightId, 
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      activateCOAright({ state, getters, commit, rootState, rootGetters }, COArightId) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/CoaRights/activecoarights",
            { 
              businessId: selectedBusiness.businessId,
              branchId:selectedBusiness.branchId, 
              coaRightID: COArightId, 
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },

      deleteAssetData({ state, getters, commit, rootState, rootGetters }, branchID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness =rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/FixedAsset/DeActivateFixedAsset",
            { 
              businessID: selectedBusiness.businessId,
              branchID:selectedBusiness.branchId, 
              fixedAssetID: branchID, 
            },
            branchID,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      activateFixedAsset({ state, getters, commit, rootState, rootGetters }, branchID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/FixedAsset/ActivateFixedAsset",
            {
              businessID: selectedBusiness.businessId,
              branchID:selectedBusiness.branchId,
              fixedassetID: branchID
      
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },   
      ViewFixedAssetItemByGroup({ rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            "Content-Type": "multipart/form-data",
          },
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/FixedAsset/ViewFixedAssetItemByGroup",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              status: payload.status,
              limit: payload.limit,
              pageNum:payload.pageNum
            },
            payload.formdata,
            options
          )
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          });
      },
            getcoaRight({ state, getters, commit, rootState, rootGetters }, assetId) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/CoaRights/viewcoarights",
            {
              userID:assetId,
              businessId: selectedBusiness.businessId,
              branchId:  selectedBusiness.branchId,
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      getFixedItem({ state, getters, commit, rootState, rootGetters }, assetId) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ViewFixedAsset",
            {
              fixedAssetID:assetId,
              businessID: selectedBusiness.businessId,
              branchID:  selectedBusiness.branchId,
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },

      getFixAsset({ state, getters, commit, rootState, rootGetters },refName) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountWithRef",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          refName:refName
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
      },  
  
      // depreciation expense account
depExpenseAccounts({ state, getters, commit, rootState, rootGetters },refName) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]

    }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountWithRef",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          refName:refName
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
      },  
      // Fa Purachse userBranchView
      userBranchView({ state, getters, commit, rootState, rootGetters }) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/User/userbranchview",
            {
              businessID: selectedBusiness.businessId,
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      // view depreciation history
      ViewDepreciationHistory({ state, getters, commit, rootState, rootGetters },payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ViewDepreciationHistory",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              fixedAssetID: payload.fixedAssetID,
              limit:payload.limit,
              pageNum:payload.pageNum
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      ViewDepreciationHistoryByinvoice({ rootGetters }, payload) {
        console.log('payload received here',payload)
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
      
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ViewDepreciationHistoryByinvoice",
            {
              businessID: selectedBusiness.businessId,
              branchID:payload.branchID?payload.branchID:selectedBusiness.branchId,
              // branchID: selectedBusiness.branchId,
              invoiceNum:payload.invoiceNo,
              invoiceType:payload.invoiceType
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
       // view Journal history
       ViewJournalDepHistory({ state, getters, commit, rootState, rootGetters },payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FixedAsset/ViewJournalDepHistory",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              fixedAssetID: payload
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      ViewFARevaluationByInvoice({ rootGetters }, payload) {
        console.log('payload received here',payload)
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
      
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/Revaluation/ViewFARevaluationByInvoice",
            {
              businessID: selectedBusiness.businessId,
              // branchID: selectedBusiness.branchId,
              branchID:payload.branchID?payload.branchID:selectedBusiness.branchId,
              invoiceNum:payload.invoiceNo,
              invoiceType:payload.invoiceType
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      // addFixedPurchase
      addFixedPurchase({ state, getters, commit, rootState, rootGetters }, data) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'multipart/form-data',
          }
        };
        
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        
        return request
          .make(
            "POST",
            "/FAPurchase/AddFAPurchase",
            { 
      
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId
            },
            data,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      }, 
      // viewFAPurchase
      viewFAPurchase({ rootGetters }, faPurchaseID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FAPurchase/ViewFAPurchase",
            { businessID: selectedBusiness.businessId, 
              branchID: selectedBusiness.branchId,
              faPurchaseID:faPurchaseID
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      ViewFAPurchaseByInvoice({ rootGetters }, payload) {
        console.log('payload received here',payload)
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
      
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "GET",
            "/FAPurchase/ViewFAPurchaseByInvoice",
            {
              businessID: selectedBusiness.businessId,
              branchID:payload.branchID?payload.branchID:selectedBusiness.branchId,
              // branchID: selectedBusiness.branchId,
              invoiceNum:payload.invoiceNo,
              invoiceType:payload.invoiceType
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      } ,
      // UpdateFAPurchase
      UpdateFAPurchase({ state, getters, commit, rootState, rootGetters }, payload) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"],
            'Content-Type':'mutlipart/form-data',
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "PUT",
            "/FAPurchase/UpdateFAPurchase",
            { businessID: selectedBusiness.businessId, 
              branchID: selectedBusiness.branchId,
              faPurchaseID:payload.FAPurchaseID
            },
            payload.formdata,
            options
          )
          .then(res => {
            console.log("update response of store",res)
            return res;
          })
          .catch(err => {
            return err;
          });
      },
      DeactivateFAPurchase({ rootGetters }, faPurchaseID) {
        const options = {
          headers: {
            Authorization: "Bearer " + rootGetters["auth/loginToken"]
      
          }
        };
        let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
        return request
          .make(
            "POST",
            "/FAPurchase/DeactivateFAPurchase",
            {
              businessID: selectedBusiness.businessId,
              branchID: selectedBusiness.branchId,
              faPurchaseID:faPurchaseID
            },
            null,
            options
          )
          .then(res => {
            return res;
          })
          .catch(err => {
            return err;
          });
      }
};
const getters = {
    getsnackbarMessage(state){
      return state.message
    }
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers
      })
        .then(response => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch(error => {
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          } else if(event.srcElement.status ===403){
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).response[0].message)
            router.push({name:'accessDenied'})
          }
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          }else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed."
          }
        };
        resolve({
          error: true,
          message: "server request Failed."
        });
        reject(error);
      });
    }
  }
  /* ----------------- ---------- -------- -------------------- */
};
