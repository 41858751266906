<template>
  <div>
    <v-dialog v-model="getloginExpired" fullscreen hide-overlay>
      <v-card color="mainBackground">
        <v-row>
          <v-col cols="12" sm="12" md="8" lg="4" xl="4" class="mx-auto">
            <v-card class="mt-12">
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="d-flex align-center justify-center"
                  >
                    <div class="text-center ma-0 pa-0">
                      <img
                        src="../../assets/images/sessiontimeout.svg"
                        width="300"
                        height="280px"
                        alt=""
                      />
                    </div>
                 
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    v-if="resErrors.length > 0"
                    class="mb-0 pb-0 text-center"
                  >
                  <v-alert
                      id="res"
                      v-if="resErrors.length > 0"
                      type="error"
                      elevation="0"
                    >
                      <ul>
                        <li v-for="(resError, i) in resErrors" :key="i">
                          {{ resError }}
                        </li>
                      </ul>
                    </v-alert>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="12"
                    xl="12"
                    class="mb-0 pb-0 text-center"
                  >
                
                    <h2 class="mt-2 mb-0 font-weight-medium error--text title">
                      Your Session has Expired
                    </h2>
                 
                    <p class="subtitle-1 font-weight-medium">
                      If you want to continue session as
                      <strong class="text--primary subtitle-1 font-weight-bold">
                        {{ getUsername }}</strong
                      >. Please re-write your password
                    </p>
                    <v-text-field
                      prepend-inner-icon="mdi-lock-outline"
                      :error-messages="passwordErrors"
                      label="Password*"
                      :type="show1 ? 'text' : 'password'"
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="show1 = !show1"
                      required
                      filled
                      rounded
                      class="pb-1 pt-3"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-checkbox
                      v-model="onSetToCookie"
                      label="Remember Me"
                      @change="onCheckedRememberMe($event)"
                      hide-details="auto"
                    ></v-checkbox>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <div class="float-left">
                      <v-btn color="error" @click="logoutUser">
                        <v-icon left>mdi-lock-alert</v-icon> Not You
                      </v-btn>
                    </div>
                    <div class="float-right">
                      <v-btn color="primary" @click="fSubmit">
                        <v-icon left>mdi-login-variant</v-icon> Login
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- Error snack Contact your Adminstrator -->
    <v-snackbar :timeout="-1" v-model="getErrorMessage" color="error" transition="scale-transition" origin="center center">
      {{ getErrorMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="setErrorMessage">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  data() {
    return {
      onSetToCookie: false,
      show1: false,
      resErrors: [],
      password: "",
    };
  },
  computed: {
    ...mapGetters("auth", [
      "getloginExpired",
      "getErrorMessage",
      "getUsername",
    ]),
    ...mapState("auth", ["errorMessage", "loginExpired"]),
    passwordErrors() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push("Password is required");
      return errors;
    },
  },
  watch: {
    errorMessage(newValue, oldValue) {
      console.log("newValue", newValue, "oldValue", oldValue);
    },
  },
  mixins: [validationMixin],
  validations: {
    password: {
      required,
    },
  },
  created() {
    this.checkCookie();
  },
  methods: {
    setCredCookie(userName, Password) {
      if (this.onSetToCookie) {
        let cookie = escape(userName) + "=" + escape(Password) + ";";
        document.cookie =
          "fu@123_emanresu_fu@123" + "=" + encodeURIComponent(userName);
        document.cookie =
          "fu@123_drowssap_fu@123" + "=" + encodeURIComponent(Password);
      }
    },
    checkCookie() {
      let getUserNameCookie = this.getCookie("fu@123_emanresu_fu@123");
      let getUserPasswordCookie = this.getCookie("fu@123_drowssap_fu@123");
      this.password = getUserPasswordCookie;
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          this.onSetToCookie = true;
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    onCheckedRememberMe(onChecked) {
      this.onSetToCookie = onChecked;
    },

    async logoutUser() {
      await this.$store.commit("auth/setLoginExpired", false);
      this.$router.push({ name: "signout" });
    },
    async setErrorMessage() {
      await this.$store.commit("auth/setErrorMessage", "");
    },
    fSubmit() {
      this.$v.$touch();
      const formData = {
        userName: this.getUsername,
        password: this.password,
      };
      if (!this.$v.$anyError) {
        this.resErrors = [];
        this.$store
          .dispatch("auth/signin", formData)
          .then((response) => {
            if (response.data.success.toLowerCase() == "ok") {
              this.setCredCookie(this.getUsername, this.password);
              console.log("user was login by", response);
              this.$store.dispatch("auth/setUserrname", formData.userName);
              this.$store.dispatch("auth/setToken", response.data.token);
              this.$store.commit("auth/setLoginExpired", false);
              this.$router.go();
            } else {
              let resErrorData = response.data.response;
              for (let i = 0; i < resErrorData.length; i++) {
                this.resErrors.push(resErrorData[i].message);
              }
              // document.getElementById("res").scrollIntoView();
            }
          })
          .catch((error) => {
            console.log(error);
            this.resErrors = [];
            this.resErrors.push("Something went wrong try again later");
            // document.getElementById("res").scrollIntoView();
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
