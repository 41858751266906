<template>
  <div>
 
    <v-dialog v-model="createGroupDialog" width="750" persistent>
      <v-card>
        <v-card-title
          >Edit Group
          <v-spacer></v-spacer>
          <v-btn class="mt-0 ml-1" icon @click="closeUpdate" color="error"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    label="Group Name"
                    filled
                    dense
                    hide-details="auto"
                    :counter="counterOne"
                    v-model="$v.form.groupName.$model"
                    :error-messages="groupNameErrors"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-textarea
                    :counter="counterTwo"
                    filled
                    rows="6"
                    max-rows="6"
                    hide-details="auto"
                    v-model="$v.form.description.$model"
                    :error-messages="descriptionErrors"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div class="d-flex justify-center mb-6">
                    <div>
                      <v-subheader
                        class="subtitle-1 font-weight-medium pl-0 pt-0 mt-0 mb-4"
                        height="20"
                        style="height: 18px !important "
                      >
                        Select color</v-subheader
                      >
                      <v-color-picker
                        elevation="1"
                        hide-inputs
                        dot-size="40"
                        :swatches="swatches"
                        show-swatches
                        canvas-height="160"
                        hide-sliders="true"
                        class="white"
                        v-model="form.colorCode"
                      ></v-color-picker>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn class="ml-2" color="primary" text @click="updateGroup"
            ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn
          >
          <v-btn color="error" text @click="closeUpdate"
            ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- end create group -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import mainValidations from "@/assets/js/validations";
import errorMessages from "@/assets/js/errorMessages";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["changeview", "showGroupSnack", "gpID", "moduleName", "getTableData"],
  data() {
    return {
      counterOne: mainValidations.length1,
      counterTwo: mainValidations.length2,
      closeOnContentClick: false,
      createGroupDialog: true,
      ex4: "",
      form: {
        groupName: "",
        description: "",
        colorCode: "#DF5353",
        formType: "",
      },
      swatches: [
        ["#f44336", "#E91E63", "#9c27b0"],
        ["#673ab7", "#3f51b5", "#2196F3"],
        ["#03a9f4", "#00bcd4", "#009688"],
        ["#4caf50", "#cddc39", "#ff9800"],
        ["#ffeb3b", "#795548", "#263238"],
      ],
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      groupName: {
        required,
        checkLength: (val) => {
          return mainValidations.nameLength(val);
        },
      },
      description: {
        checkDescriptionLength: (val) => {
          return mainValidations.descriptionLength(val);
        },
      },
    },
  },
  computed: {
    groupNameErrors() {
      const errors = [];
      if (!this.$v.form.groupName.$dirty) return errors;
      !this.$v.form.groupName.required &&
        errors.push("Group Name is required.");
      !this.$v.form.groupName.checkLength &&
        errors.push(
          errorMessages.lengthMessages("Group Name", mainValidations.length1)
        );
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.checkDescriptionLength &&
        errors.push(
          errorMessages.lengthMessages("Description", mainValidations.length2)
        );
      return errors;
    },
  },
  created() {
    //Getting Groups
    this.$store.dispatch("setupItem/viewGroup", this.gpID).then((res) => {
      //this.groupResponse=[]
      console.log("view Group against single id", res);
      let result = res.data.groupRespnse;
      this.form.groupName = result.groupName;
      (this.form.description = result.description),
        (this.form.colorCode = result.colorCode),
        (this.form.formType = result.formType);
      // for(let i=0;i<result.length;i++){
      //     if(result[i].status=="Active"){
      //         this.groupResponse.push(result[i])
      //     }
      // }
    });
  },
  methods: {
    closeUpdate() {
      this.createGroupDialog = false;
      this.changeview("viewGroup");
    },
    updateGroup() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        let colorCode = this.form.colorCode.replace("#", "");
        let data = {
          groupName: this.form.groupName,
          description: this.form.description,
          formType: this.moduleName,
          colorCode: colorCode,
          groupID: this.gpID,
        };
        this.$store
          .dispatch("setupItem/updateGroup", data)
          .then((res) => {
            console.log("resposne is ", res);
            if ((res.data.success = "OK")) {
              this.changeview("viewGroup");
              this.showGroupSnack("Group Updated Successfully", "success");
              this.getTableData();
            }
          })
          .catch((error) => {
            console.log("error is", error);
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
