import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import {
  applicationPrivate,
  applicationPublic,
} from "../../services/applicationJson";
import router from "../../router";
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  token: null,
  branches: [],
  userName: "",
  userFirstName: "",
  userLastName: "",
  email: "",
  password: "",
  userContact: "",
  allBranches: null,
  loginExpired: false,
  errorMessage: "",
  paymentDetail: "",
  purchaseId: "",
  // getIP:'',
};
const mutations = {
  // setIP(state, payload) {
  //   state.getIP = payload
  // },
  setUsername(state, payload) {
    state.userName = payload;
  },

  setPaymentDetail(state, payload) {
    state.paymentDetail = payload;
  },
  setPurchaseId(state, payload) {
    state.purchaseId = payload;
  },

  setOnEmptyMakePayment(state, payload) {
    console.warn("EMPLL,pay", payload);
    state.paymentDetail = payload;
  },

  setLoginExpired(state, payload) {
    state.loginExpired = payload;
  },
  setErrorMessage(state, payload) {
    console.log("seterrormessage", payload);
    state.errorMessage = payload;
  },
  setUserDetails(state, payload) {
    console.log("mutations called", payload, state);
    state.userFirstName = payload.userFirstName;
    state.userLastName = payload.userLastName;
    state.email = payload.email;
    state.password = payload.password;
    state.userContact = payload.userContact;
    console.log("mutations set", state);
  },
  signin(state, payload) {
    state.token = payload;
  },
  setBranches(state, payload) {
    state.branches = payload;
  },
  destroyToken(state) {
    state.token = null;
  },
  clearBusiness(state) {
    state.branches = [];
  },
  setAllBranch(state, payload) {
    console.log("payload of branches", payload);

    state.allBranches = payload;
  },
  clearAllBranch(state, payload) {
    state.allBranches = null;
  },
};
const actions = {
  setViewPurchaseId({ commit }, viewPruchaseId) {
    console.warn("setPurchaseId", viewPruchaseId);
    commit("setPurchaseId", viewPruchaseId);
  },
  setMakePayment({ commit }, paymentDetail) {
    console.warn("setMakePayment", paymentDetail);
    commit("setPaymentDetail", paymentDetail);
  },
  onEmptyMakePayment({ commit }, paymentDetail) {
    commit("setOnEmptyMakePayment", paymentDetail);
    console.warn("setOnEmptyMakePayment", paymentDetail);
  },
  setToken({ commit }, token) {
    console.log("token", token);
    commit("signin", token);
    localStorage.setItem("Bearer_token", token);
  },
  setUserrname({ commit }, userName) {
    console.log("userName", userName);
    commit("setUsername", userName);
  },
  setUserDetails({ commit }, userDetails) {
    console.log("actions called", userDetails);
    commit("setUserDetails", userDetails);
  },
  checkBusinessName(
    { state, getters, commit, rootState, rootGetters, dispatch },
    businessName
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    };
    return request
      .make(
        "GET",
        "/Business/CheckBusinessName",
        { businessName },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  forgotPassword({ state }, email) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json",
      },
    };
    return request
      .make(
        "POST",
        "/User/forgotpassword",
        { email: email.toEmail },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  validateresetpasstoken({ state }, payload) {
    const options = {
      headers: {},
    };
    return request
      .make(
        "POST",
        "/User/validateresetpasstoken",
        { token: payload.token, action: payload.action },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  setpassword({ state }, payload) {
    const options = {
      headers: {},
    };
    return request
      .make(
        "POST",
        "/User/setpassword",
        { token: payload.token, password: payload.password },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  passwordreset({ state }, payload) {
    const options = {
      headers: {},
    };
    console.log("validateresetpasstoken");
    return request
      .make(
        "POST",
        "/User/passwordreset",
        { token: payload.token, password: payload.password },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  activeuser({ state }, token) {
    const options = {
      headers: {},
    };
    console.log("after active", token);
    return request
      .make("POST", "/User/activeuser", { token: token }, null, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  updateUser({ state }, payload) {
    console.log("UPDATE USER STORE", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "multipart/form-data",
      },
    };
    return request
      .make(
        "PUT",
        "/User/UpdateUser",
        { userID: payload.userID },
        payload.formdata,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  accessinvitation({ state }, data) {
    console.warn("accessinvitation", data);
    const options = {
      headers: {},
    };
    return request
      .make(
        "POST",
        "/User/accessinvitation",
        { token: data.token, status: data.action },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  unlockuser({ state }, data) {
    console.warn("accessinvitation", data);
    const options = {
      headers: {},
    };
    return request
      .make("POST", "/User/unlockuser", { token: data }, null, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  checkToken({ state, getters, commit, rootState, rootGetters, dispatch }) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    };
    return request
      .make("POST", "/User/ValidateToken", null, null, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  checkUserEmail: ({ commit }, email) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return request
      .make("POST", "/User/checkuseremail", { userEmail: email }, null, options)
      .then((response) => {
        console.log("checkemail Response", response);
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },
  changePassword({ state }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
        "Content-Type": "application/json",
      },
    };
    return request
      .make(
        "POST",
        "/User/changepassword",
        { oldPassword: payload.oldPassword, newPassword: payload.newPassword },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  signup: ({ commit }, data) => {
    const options = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    return request
      .make("POST", "/User/adduser", null, data, options)
      .then((response) => {
        console.log("reg", response);
        return response;
      })
      .catch((err) => {
        console.log(err);
        return err;
      });
  },

  userbusinessview({
    state,
    getters,
    commit,
    rootState,
    rootGetters,
    dispatch,
  }) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    };
    return request
      .make("GET", "/User/userbusinessview", null, null, options)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  userbranchview(
    { state, getters, commit, rootState, rootGetters, dispatch },
    businessId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + state.token,
      },
    };
    return request
      .make(
        "GET",
        "/User/userbranchview",
        { businessId: businessId },
        null,
        options
      )
      .then((res) => {
        console.log("branches are", res);
        if (res.data.success == "Ok") {
          let resData = res.data.branchView;
          commit("setAllBranch", resData);
        }
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  signin: (
    { state, getters, commit, rootState, rootGetters, dispatch },
    data
  ) => {
    return applicationPublic.post("/UserAuthorization/token", data);
  },
  // signin: (
  //   { state, getters, commit, rootState, rootGetters, dispatch },
  //   data ) => {

  //   return request
  //     .make("POST", "/UserAuthorization/token", null, data,{})
  //     .then(response => {
  //       return response;

  //     })
  //     .catch(err=>{
  //       return err;
  //     });
  // },

  logoutUser(context) {
    localStorage.removeItem("Bearer_token");
    context.commit("destroyToken");
    context.commit("clearBusiness");
  },
};
const getters = {
  // getUserIP(state) {
  //   return state.getIP
  // },
  getPaymentDetails(state) {
    return state.paymentDetail;
  },
  getPurchaseId(state) {
    return state.purchaseId;
  },
  getloginExpired(state) {
    return state.loginExpired;
  },
  loggedIn(state) {
    return state.token !== null && state.token !== undefined;
  },
  loginToken(state) {
    return state.token;
  },
  getBranches(state) {
    return state.branches;
  },
  getUsername(state) {
    return state.userName;
  },
  getUserDetails(state) {
    let data = {
      userFirstName: state.userFirstName,
      userLastName: state.userLastName,
      email: state.email,
      password: state.password,
      userContact: state.userContact,
    };
    return data;
  },
  getallBranches(state) {
    return state.allBranches;
  },
  getErrorMessage(state) {
    return state.errorMessage;
  },
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state,
};
import store from "../index";
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function (resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          if (event.srcElement.status === 401) {
            store.commit("auth/setLoginExpired", true);
          } else if (event.srcElement.status === 403) {
            router.push({ name: "accessDenied" });
          } else if (event.srcElement.status === 500) {
            console.log(
              "Error 500",
              JSON.parse(event.srcElement.response).message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).message
            );
          } else {
            return new Promise(function (resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function (resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
