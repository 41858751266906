import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import companySetup from './modules/companySetup'
import axios from 'axios'
import createPersistedState from "vuex-persistedstate"
import dropdowns from './modules/dropdowns'
import setupItem from './modules/setupItem'
import payroll from './modules/payroll'
import advisor from './modules/advisor'
import branchRight from './modules/branchRight'
import settings from './modules/settings'
import reports from './modules/reports'
import account from './modules/account'
import userAndPackageDetails from './modules/userAndPackageDetails'
Vue.use(Vuex)

const store= new Vuex.Store({
  plugins: [createPersistedState()],
  // state: {
  // },
  mutations: {
    changeLang(state, payload) {
      app.$i18n.locale = payload
      localStorage.setItem('currentLanguage', payload)
    }
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload)
    }
  },
  modules: {
    auth,
    payroll,
    companySetup,
    setupItem,
    settings,
    advisor,
    account,
    userAndPackageDetails,
    reports,
    dropdowns,
    branchRight
  }
})
export default store;