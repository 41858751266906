<template>
  <v-app>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="white"
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <span
          class="hidden-sm-and-down grey--text text--darken-3 font-weight-medium"
        >
          <img
            src="../../assets/images/logo/rsheenlogo.svg"
            width="140"
            style="margin-top: 12px"
        /></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-app-bar-item>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-chip
              pill
              v-on="on"
              color="primaryLight"
              text-color="primary"
              class="font-weight-medium"
            >
              <v-avatar left color="primary" class="white--text">
                <span class="font-weight-medium">{{
                  userName[0].toUpperCase()
                }}</span>
              </v-avatar>
              {{ userName }}
            </v-chip>
          </template>
          <span>User</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn :to="{ name: 'signout' }" v-on="on" icon fab class="ml-1">
              <v-icon>mdi-logout-variant</v-icon>
            </v-btn>
          </template>
          <span>LogOut</span>
        </v-tooltip>
      </v-app-bar-item>
    </v-app-bar>
    <v-main class="mainBackground">
      <v-container fluid>
        <!---router -->
        <v-scroll-x-reverse-transition appear hide-on-leave="true">

        <router-view></router-view>
        </v-scroll-x-reverse-transition>
      </v-container>
    </v-main>
    <!-- <footer-component/> -->
    <v-footer
      color="footerBgColor footerTextColor--text subtitle-2 d-print-none"
      class="text-center"
      padless
    >
      <v-row no-gutters>
        <v-col class="text-center pa-1" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>R-Sheen Group of Companies</strong>
        </v-col>
      </v-row>
    </v-footer>
    <relogin></relogin>
  </v-app>
</template>

<script>
import relogin from "@/components/LoginExpiredModel/relogin";
export default {
  components: {
    relogin,
  },
  computed: {
    userName() {
      return this.$store.getters["auth/getUsername"];
    },
  },
};
</script>


