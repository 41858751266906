<template>
  <div>
    <v-autocomplete
      ref="categoryDD"
      v-model="model"
      :items="getdropdownCategores"
      :search-input.sync="keyword"
      no-data-text="No Category Available"
      item-text="name"
      item-value="categoryID"
      clearable
      hide-selected
      :return-object="true"
      :label="categoryLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :dense="isDense"
      :filled="isfilled"
      :multiple="isMultiple"
      :hide-details="hideDetails"
      @click:clear="clearData"
      @change="valueChanged($event), addCategory($event)"
    >
      <template v-slot:append v-if="showIcon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="openCategoryForm"
              small
              style="margin-top: -2px !important"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Category</span>
        </v-tooltip>
      </template>
      <template v-slot:selection="{ item, index }" v-if="isMultiple">
        <v-chip v-if="index === 0" small>
          <span>{{ item.name }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption"
          >(+{{ model.length - 1 }} others)</span
        >
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="categoryForm" max-width="400px" persistent>
      <add-new-category :closeCategory="closeCategory" />
    </v-dialog>
  </div>
</template>
<script>
import addNewCategory from "../miniComponent/addCategory";
import { mapGetters } from "vuex";
export default {
  components: {
    addNewCategory,
  },
  props: {
    showIcon: {
      default: true,
    },
    isMultiple: {
      default: true,
    },
    categoryLabel: {
      default: "",
    },
    isfilled: {
      default: true,
    },
    isDense: {
      default: true,
    },
    isOutlined: {
      default: false,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    returnObject: {
      default: false,
    },
    updateCategory: {
      default: null,
    },
  },
  data() {
    return {
      model:
        this.updateCategory && this.updateCategory.length
          ? this.updateCategory.map((item) => {
              return {
                categoryID: item.categoryID,
                name: item.name,
              };
            })
          : [],
      categoryOptions: [],
      selectedCategories: [],
      categoryForm: false,
      name: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      userRights: null,
      isAllowed: false,
    };
  },
  async created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 7 && val.displayPermission) {
        this.isAllowed = true;
      }
    });
  },
  computed: {
    ...mapGetters("dropdowns", ["getdropdownCategores"]),
  },
  methods: {
    openCategoryForm() {
      this.categoryForm = true;
    },
    clearData() {
      console.warn("clearCalled");
      this.model = "";
      this.$emit("categorySelected", []);
    },
    addCategory(e) {
      console.log("E....", e);
      if (this.isMultiple) {
        let selectedCategories = [];
        e.forEach((val) => {
          console.log("selectedCategories", this.selectedCategories);
          selectedCategories.push(val);
        });
        this.selectedCategories = [...selectedCategories];
      }
    },
    closeCategory(type) {
      if (type == "success") {
        this.$store
          .dispatch("dropdowns/dropdownCategories", { keyword: "", offset: 0 })
          .then((res) => {
            this.getAllCategories();
          });
      }
      this.categoryForm = false;
    },
    valueChanged(e) {
      this.name = this.model.name;
      if (this.returnObject == false) {
        if (this.isMultiple == true) {
          let data = [];
          this.model.forEach((val) => {
            data.push(val.categoryID);
          });
          this.$emit("categorySelected", data);
        } else {
          this.$emit("categorySelected", this.model.categoryID);
        }
      } else {
        this.$emit("categorySelected", this.model);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllCategories();
    },
    sendNewRequest() {
      this.categoryOptions = [];
      this.offset = 0;
      this.getAllCategories();
    },
    getAllCategories() {
      if (this.updateCategory != null && this.updateCategory != "") {
        let updateCategory = this.updateCategory;
        updateCategory.forEach((val) => {
          this.categoryOptions.push({
            categoryID: val.categoryID,
            name: val.name,
          });
        });
      }
      let sendingKeyword = this.keyword;
      if (this.isAllowed) {
        this.$store
          .dispatch("dropdowns/dropdownCategories", {
            keyword: this.keyword,
            offset: this.offset,
          })
          .then((res) => {
            if (res.data.success == "OK") {
              let oldData = this.categoryOptions;
              let resData = res.data.data;
              this.categoryOptions = [
                ...oldData,
                ...resData,
                ...this.selectedCategories,
              ];
              this.$store.commit(
                "dropdowns/setdropdownCategores",
                this.categoryOptions
              );
              this.totalData = res.data.offsetAndTotalCount.totalCount;
            }
          })
          .finally(() => {
            if (sendingKeyword != this.keyword) {
              this.categoryOptions = [];
              this.offset = 0;
              // (this.isLoading = true),
              this.getAllCategories();
            }
            // else {
            // this.isLoading = false;
            // }
          });
      }
    },
  },
  watch: {
    keyword(val) {
      if (val == this.name && this.name != null) {
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);
    },
    updateCategory: function (val) {
      console.warn("modelcat", val);
      let category = [];
      val.forEach((el) => {
        category.push({
          categoryID: el.categoryID,
          name: el.name,
        });
      });
      this.model = category;
    },
  },
};
</script>
