<template>
  <div>
    <v-autocomplete
      ref="employeeDD"
      v-model="model"
      :items="employeeOptions"
      :search-input.sync="keyword"
      no-data-text="No Employee Available"
      item-text="employeeFullName"
      item-value="employeeID"
      clearable
      dense
      hide-selected
      :return-object="true"
      :label="EmployeeLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @click:clear="clearData"
      @change="valueChanged($event), addEmployee($event)"
    >
      <!--  :append-icon="showIcon?'mdi-plus':undefined"
      @click:append="EmployeeForm = true" -->
      
      <template v-slot:append v-if="showIcon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="EmployeeForm = true"
              small
              style="margin-top: -2px !important"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Employee</span>
        </v-tooltip>
      </template>
      <template v-slot:selection="{ item, index }" v-if="isMultiple">
        <v-chip v-if="index === 0" small>
          <span>{{ item.employeeFullName }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption"
          >(+{{ model.length - 1 }} others)</span
        >
      </template>
      <!-- <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template> -->
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="EmployeeForm" max-width="400px" persistent>
      <add-new-employee
        @addedEmployee="keyword = $event"
        :showSnack="showSnack"
        :closeModal="closeModal"
      />
    </v-dialog>
    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false"  v-bind="attrs"> <v-icon>mdi-close</v-icon> </v-btn>
    </template>
    </v-snackbar>
  </div>
</template>

<script>
import addNewEmployee from "../miniComponent/addEmployee";
import centralizedValues from "../../assets/js/centralizedValues";
export default {
  components: {
    addNewEmployee,
  },
  props: {
    showIcon: {
      default: true,
    },
    isMultiple: {
      default: true,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: [],
    },
    EmployeeLabel: {
      default: "",
    },
    isfilled: {
      default: true,
    },
    isOutlined: {
      default: false,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: false,
    },
    returnObject: {
      default: false,
    },
    updateEmployee: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      color: "success",
      timeout: centralizedValues.timeout,
      //start
      userRights: null,
      isAllowedToViewEmployee: false,
      employeeFullName: null,
      selectedEmployees: [],
      EmployeeForm: false,
      employeeOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateEmployee,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   employeeOptions: [],
    };
  },
  created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 6 && val.displayPermission) {
        this.isAllowedToViewEmployee = true;
      }
    });
  },
  methods: {
    showSnack(msg, color) {
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    clearData() {
      this.model = "";
      this.$emit("employeeSelected", null);
    },
    addEmployee(e) {
      if (this.isMultiple) {
        e.forEach((val) => {
          this.selectedEmployees.push(val);
        });
      }
    },
    closeModal() {
      this.EmployeeForm = false;
    },
    valueChanged(e) {
      this.employeeFullName = this.model.employeeFullName;
      if (this.returnObject == false) {
        if (this.isMultiple == true) {
          let data = [];
          this.model.forEach((val) => {
            data.push(val.employeeID);
          });
          this.$emit("employeeSelected", data);
        } else {
          this.$emit("employeeSelected", e);
        }
      } else {
        this.$emit("employeeSelected", this.model);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllEmployees();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.employeeOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllEmployees();
    },
    getAllEmployees() {
      if (this.updateEmployee != null && this.updateEmployee != "") {
        let updateEmployee = this.updateEmployee;
        this.employeeOptions.push(updateEmployee);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;
      this.isLoading = false;
      if (this.isAllowedToViewEmployee) {
        (this.isLoading = true),
          this.$store
            .dispatch("setupItem/dropdownEmployees", {
              keyword: this.keyword,
              offset: this.offset,
            })
            .then((res) => {
              if (res.data.success == "OK") {
                let oldData = this.employeeOptions;
                let resData = res.data.data;
                this.employeeOptions = [
                  ...oldData,
                  ...resData,
                  ...this.selectedEmployees,
                ];
                this.totalData = res.data.offsetAndTotalCount.totalCount;
              }
            })
            .finally(() => {
              if (sendingKeyword != this.keyword) {
                this.employeeOptions = [];
                this.offset = 0;
                (this.isLoading = true), this.getAllEmployees();
              } else {
                this.isLoading = false;
              }
            });
      }
    },
  },
  watch: {
    keyword(val) {
      if (this.updateEmployee) {
        if (val && val == this.updateEmployee.employeeFullName) {
          return;
        } //prevent searching selected product
      }
      if (val == this.employeeFullName && this.employeeFullName != null) {
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);
    },
    // updateEmployee: function(val) {
    //   this.model = val;
    // },
    updateEmployee: function (val) {
      if (val) {
        this.employeeOptions.push(val);
        this.employeeFullName = val.employeeFullName;
      } else this.employeeFullName = val;
      this.count = 0;
      this.model = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
