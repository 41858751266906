<template>
  <div>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app color="primary" dark flat>
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <span class="hidden-sm-and-down mr-2">R-Sheen</span>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn dark icon @click.stop="drawerRight = !drawerRight">
        <v-icon>mdi-apps</v-icon>
      </v-btn>
      <v-btn dark icon @click.stop="drawerRightNotification = !drawerRightNotification">
        <v-icon>mdi-bell</v-icon>
      </v-btn>

      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item two-line>
            <v-list-item-avatar>
              <img src="https://randomuser.me/api/portraits/women/81.jpg" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{ currentRouteName }}</v-list-item-title>
              <v-list-item-subtitle>usmangul.uu@gmail.com</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>

          <v-list-item link>
            <v-list-item-action>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <router-link to="#" tag="v-list-item-title">Settings</router-link>
            </v-list-item-content>
          </v-list-item>
          <v-list-item router link to="/user/Logout">
            <v-list-item-action>
              <v-icon>mdi-logout-variant</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-content>
      <v-container fluid>
        <!-- vue-router -->
        <router-view></router-view>
      </v-container>
    </v-content>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
