<template>
  <div>
    <v-card>
      <v-card-title
        >Add Currency

        <v-spacer></v-spacer>
        <v-btn
          color="error"
          icon
          @click="
            closeCurrency();
            loading = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-autocomplete
              :items="newcurrencytpions"
              label="Currency *"
              v-model="$v.newCurrency.$model"
              :error-messages="currencyErrors"
              filled
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" :loading="loading" text @click="AddCurrency"
          ><v-icon left>mdi-content-save-outline</v-icon>Save</v-btn
        >
        <v-btn
          color="error"
          text
          @click="
            closeCurrency();
            loading = false;
          "
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-snackbar color="success" v-model="snackBar" transition="scale-transition" origin="center center">
      {{ snackMesg }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import companySetupData from "@/assets/js/companySetup";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["getCurrency", "closeCurrency"],
  data() {
    return {
      loading: false,
      newcurrencytpions: companySetupData.modescurency,
      newCurrency: "",
      snackBar: false,
      snackMesg: "",
    };
  },
  mixins: [validationMixin],
  validations: {
    newCurrency: {
      required,
    },
  },
  computed: {
    currencyErrors() {
      const errors = [];
      if (!this.$v.newCurrency.$dirty) return errors;
      !this.$v.newCurrency.required && errors.push("Select a Currency");
      return errors;
    },
  },
  methods: {
    AddCurrency() {
      this.$v.newCurrency.$touch();
      if (!this.$v.newCurrency.$anyError) {
        const formdata = {
          currency: this.newCurrency,
        };
        this.loading = true;
        this.$store
          .dispatch("settings/addCurrency", formdata)
          .then((res) => {
            console.log("Currency added", res);
            if (res.data.success == "OK") {
              this.loading = false;
              this.snackMesg = res.data.response[0].message;
              this.newCurrency = "";
              this.$v.newCurrency.$reset();
              // this.$emit("currencyAdded")
              this.getCurrency();
              // this.snackBar=true
              // this.$v.$reset()
            } else if ((res.data.success = "Failed")) {
              this.snackBar = true;
              this.snackMesg = res.data.response[0].message;
              this.loading = false;
              // alert("Currency already exists.");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
