<template>
  <div>
    <v-menu
      v-model="exchangeModal"
      :close-on-content-click="false"
      :nudge-width="440"
      :min-width="440"
      :max-width="440"
      offset-y
      transition="scale-transition"
      :close-on-click="false"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          color="primary"
          v-on="on"
          text
          small
          class="mt-1"
          @click="setAmount"
        >
          Exchange Rate
        </v-btn>
        <v-chip
          small
          color="ErrorLight"
          class="ml-2 error--text subtitle-1 font-weight-medium"
          label
          >{{ insertComma(exchangeRateData) }}</v-chip
        >
      </template>
      <v-card>
        <v-card-title class="subtitle-1 font-weight-medium py-3">
          <v-avatar size="34" color="primaryLight" class="mr-2">
            <v-icon color="primary" size="20">
              mdi-arrow-expand-horizontal
            </v-icon>
          </v-avatar>

          Exchange Rate
          <v-spacer></v-spacer>

          <v-btn color="error" icon @click="exchangeModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-0">
          <v-row >
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-subheader class="subtitle-1 font-weight-medium pl-0 pt-0"
                >Other to Default Currency</v-subheader
              >

              <v-text-field
                v-if="!isOtherToDefaultComma"
                 
                v-model="$v.otherToDefaultcurrency.$model"
                :error="$v.otherToDefaultcurrency.$error"
                :error-messages="otherToDefaultcurrencyErrors"
                @input="calculateotherToDefaultcurrency()"
                @blur="insertOtherToDefaultComma(otherToDefaultcurrency)"
                filled
                dense
                label="Enter Amount" 
                hide-details="auto" 
              >
                <template v-slot:prepend>
                  <v-chip class="mr-2 font-weight-medium" label  
                    >1</v-chip
                  >
                  <v-chip color="primary" label>{{ selectedCurrency }}</v-chip>
                </template>
                <template v-slot:append-outer>
                  <v-chip color="success" label>{{ defaultCurrency }}</v-chip>
                </template>
              </v-text-field>
              <v-text-field
                v-else 
                v-model="otherToDefaultcurrencyComma"
                autocomplete="off"
                hide-details="auto"
                filled
                label="Enter Amount"
                dense 
                @click="isOtherToDefaultComma = false"
                ><template v-slot:prepend>
                  <v-chip class="mr-2 font-weight-medium" label  
                    >1</v-chip
                  >
                  <v-chip color="primary" label>{{ selectedCurrency }}</v-chip>
                </template>
                <template v-slot:append-outer>
                  <v-chip color="success" label>{{ defaultCurrency }}</v-chip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-subheader class="subtitle-1 font-weight-medium pl-0 mt-4 "
                >Default to other Currency</v-subheader
              >
              <v-text-field
                v-if="!isDefaultToOtherComma"
                placeholder="Enter Amount"
                label="Enter Amount"
                v-model="$v.DefaultcurrencytoOther.$model"
                :error="$v.DefaultcurrencytoOther.$error"
                :error-messages="DefaultcurrencytoOtherErrors"
                @input="calculateDefaultcurrencytoOther()"
                @blur="insertDefaultToOtherComma(DefaultcurrencytoOther)"
                 
                hide-details="auto"
                filled
                dense
              >
                <template v-slot:prepend>
                  <v-chip class="mr-2 font-weight-medium"   label
                    >1</v-chip
                  >
                  <v-chip color="success" label>{{ defaultCurrency }}</v-chip>
                </template>
                <template v-slot:append-outer>
                  <v-chip color="primary" label>{{ selectedCurrency }}</v-chip>
                </template>
              </v-text-field>
              <v-text-field
                v-else
                placeholder=" Enter Amount"
                v-model="defaultToOthercurrencyComma"
                autocomplete="off"
                hide-details="auto"
                filled
                dense
                label="Enter Amount"
                @click="isDefaultToOtherComma = false"
                >
                <template v-slot:prepend>
                  <v-chip class="mr-2 font-weight-medium" label
                    >1</v-chip
                  >
                  <v-chip color="primary" label>{{ defaultCurrency }}</v-chip>
                </template>
                <template v-slot:append-outer>
                  <v-chip color="success" label>{{ selectedCurrency }}</v-chip>
                </template>
              </v-text-field>
            </v-col>
            <v-scroll-y-transition  >
            <v-col cols="12"  v-if="this.otherToDefaultcurrency != ''">
              <div class="mainBackground ">
              <v-divider class="pb-2" ></v-divider>
              <label class="subtitle-1 font-weight-medium ml-1">Calculated Exchange Rate :</label>
              <label class="ml-2 font-weight-bold subtitle-1 primary--text">{{
                insertComma(otherToDefaultcurrency)
              }}</label>
               <v-divider class="mt-2"></v-divider>
              </div>
            </v-col>
          </v-scroll-y-transition>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-btn color="primary" text @click="CalculateExchangeRate"
            >Save</v-btn
          >
          <v-btn text color="error" @click="exchangeModal = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
const {
  required,
  numeric,
  decimal,
  requiredIf,
} = require("vuelidate/lib/validators");
import { validationMixin } from "vuelidate";
export default {
  props: ["selectedCurrency", "defaultCurrency", "exchangeRate"],
  data() {
    return {
      exchangeRateData: this.exchangeRate,
      exchangeModal: false,
      DefaultcurrencytoOther: 1 / this.exchangeRate,
      otherToDefaultcurrency: this.exchangeRate,
      otherToDefaultcurrencyComma: this.exchangeRate,
      isOtherToDefaultComma: true,
      defaultToOthercurrencyComma: 1 / this.exchangeRate,
      isDefaultToOtherComma: true,
    };
  },
  methods: {
    insertOtherToDefaultComma(amount) {
      this.otherToDefaultcurrencyComma = this.insertComma(amount);
      this.isOtherToDefaultComma = true;
    },
    insertDefaultToOtherComma(amount) {
      this.defaultToOthercurrencyComma = this.insertComma(amount);
      this.isDefaultToOtherComma = true;
    },
    insertComma(amount) {
      return new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 15,
      }).format(amount);
    },
    setAmount() {
      this.otherToDefaultcurrency = this.exchangeRate;
      this.DefaultcurrencytoOther = 1 / this.exchangeRate;
    },
    calculateotherToDefaultcurrency() {
      console.log("check", this.exchangeRate);
      if (this.otherToDefaultcurrency > 0) {
        let data = 1 / this.otherToDefaultcurrency;
        this.DefaultcurrencytoOther = data.toFixed(18);
      }
    },
    calculateDefaultcurrencytoOther() {
      if (this.DefaultcurrencytoOther > 0) {
        let data = 1 / this.DefaultcurrencytoOther;
        this.otherToDefaultcurrency = data.toFixed(18);
      }
    },
    CalculateExchangeRate() {
      this.$v.otherToDefaultcurrency.$touch();
      this.$v.DefaultcurrencytoOther.$touch();
      if (
        !this.$v.otherToDefaultcurrency.$anyError &&
        !this.$v.DefaultcurrencytoOther.$anyError
      ) {
        this.exchangeRateData = this.otherToDefaultcurrency;
        this.$emit("rateCalculated", this.exchangeRateData);
        this.exchangeModal = false;
        console.log("result", this.otherToDefaultcurrency);
      }
    },
  },
  mixins: [validationMixin],
  validations: {
    otherToDefaultcurrency: {
      required,
      decimal,
      greterThenZero: function (val) {
        if (val <= 0) {
          return false;
        } else return true;
      },
    },
    DefaultcurrencytoOther: {
      required,
      decimal,
      greterThenZero: function (val) {
        if (val <= 0) {
          return false;
        } else return true;
      },
    },
  },
  computed: {
    otherToDefaultcurrencyErrors() {
      const errors = [];
      if (!this.$v.otherToDefaultcurrency.$dirty) return errors;
      !this.$v.otherToDefaultcurrency.required &&
        errors.push("Enter Exchage Rate");
      !this.$v.otherToDefaultcurrency.decimal &&
        errors.push("Exchange rate should be numeric value");
      !this.$v.otherToDefaultcurrency.greterThenZero &&
        errors.push("Exchange rate must be greater then zero");
      return errors;
    },
    DefaultcurrencytoOtherErrors() {
      const errors = [];
      if (!this.$v.DefaultcurrencytoOther.$dirty) return errors;
      !this.$v.DefaultcurrencytoOther.required &&
        errors.push("Enter Exchage Rate");
      !this.$v.DefaultcurrencytoOther.decimal &&
        errors.push("Exchange rate should be numeric value");
      !this.$v.DefaultcurrencytoOther.greterThenZero &&
        errors.push("Exchange rate must be greater then zero");
      return errors;
    },
  },
};
</script>

<style lang="scss" scoped></style>
