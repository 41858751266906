import axios from "axios";
import createPersistedState from "vuex-persistedstate";
import {
  applicationPrivate,
  applicationPublic,
} from "../../services/applicationJson";
import menuItemId from "../../assets/js/centralizedValues";
import router from "../../router";
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  editVendor: null,
  selectBusiness: null,
  userrights: null,
  previousRoute: null,
  progressBar: false,
  setupItemID: null,
  payrollItemName: null,
  onUserRightDisplayPermission: null,
  drawer: true,
  invoiceData: null,
  invoiceName: null,
  purchaseInvoiceReturn: null,
  purchseId: null,
  isFromMangeBnk: false,
  // vendorExportFile: null
};
const mutations = {
  onDisplayPermissoinForUserRight(state, payload) {
    state.onUserRightDisplayPermission = payload;
  },
  setIsFromManageBnk(state, payload) {
    state.isFromMangeBnk = payload;
  },
  setPurchseId(state, payload) {
    state.purchseId = payload;
    state.isFromMangeBnk = false;
  },
  setPBInvoiceData(state, payload) {
    if (payload) {
      state.invoiceData = payload.invoiceData;
      state.invoiceName = payload.invoiceName;
    } else {
      state.invoiceData = payload;
      state.invoiceName = payload;
    }
  },
  setPurchaseInvoiceReturn(state, payload) {
    state.purchaseInvoiceReturn = payload;
  },
  setProgressBar(state, payload) {
    state.progressBar = payload;
  },
  setEditVendor(state, payload) {
    state.editVendor = payload;
  },
  setSelectBusiness(state, payload) {
    state.selectBusiness = payload;
  },
  setUserRights(state, payload) {
    state.userrights = payload;
  },
  setBranch(state, payload) {
    state.selectBusiness.branchName = payload.branchName;
    state.selectBusiness.branchId = payload.branchId;
    state.selectBusiness.isHeadOffice = payload.isHeadOffice;
  },
  setpreviousRoute(state, payload) {
    state.previousRoute = payload;
  },
  setBusinessName(state, payload) {
    if (
      (state.selectBusiness != "" || state.selectBusiness != null) &&
      payload != ""
    ) {
      state.selectBusiness.businessName = payload;
    }
  },
  setBusinessLogo(state, payload) {
    if (
      (state.selectBusiness != "" || state.selectBusiness != null) &&
      payload != ""
    ) {
      state.selectBusiness.logo = payload;
    }
  },
  setSetupItemID(state, payload) {
    state.setupItemID = payload;
  },
  setSetupItemDetails(state, payload) {
    state.payrollItemName = payload.name;
    state.setupItemID = payload.payrollItemID;
  },
  setDrawer(state, payload) {
    state.drawer = payload;
  },
  // vendorExportFile(state, payload){
  //   state.vendorExportFile = payload
  // }
};

const actions = {
  setDisplayPermissoinForUserRight(
    { getters, rootGetters, commit },
    onDisplayForUserRight
  ) {
    commit("onDisplayForUserRight", onDisplayForUserRight);
  },
  setProgressBar({ getters, rootGetters, commit }, status) {
    commit("setProgressBar", status);
  },
  setpreviousRoute({ getters, rootGetters, commit }, path) {
    commit("setpreviousRoute", path);
  },
  setBranch({ getters, rootGetters, commit }, data) {
    console.log("formdatea", data);
    commit("setBranch", data);
  },
  setBusinessName({ getters, rootGetters, commit }, businessName) {
    commit("setBusinessName", businessName);
  },
  setBusinessLogo({ getters, rootGetters, commit }, logo) {
    commit("setBusinessLogo", logo);
  },
  setSetupItemID({ commit }, setupItemID) {
    commit("setSetupItemID", setupItemID);
  },
  setSetupItemDetails({ commit }, details) {
    commit("setSetupItemDetails", details);
  },
  setDrawer({ commit }, drawerValue) {
    commit("setDrawer", drawerValue);
  },
  updateuserPassword({ getters, rootGetters }, userEmail) {
    console.log("userEmail", userEmail);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/User/updateuserpassword",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          userEmail: userEmail,
        },
        null,
        options
      )
      .then((response) => {
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  getUserBayBranch({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/User/viewuserbybranch",
        {
          businessId: selectedBusiness.businessId,
          branchId: payload,
        },
        null,
        options
      )
      .then((response) => {
        console.log("VIEWUSERDETAISL", response);
        return response;
      })
      .catch((err) => {
        return err;
      });
  },

  viewuserdetails({ getters, rootGetters }) {
    console.log("viewuserdetails");
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/User/viewuserdetails",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((response) => {
        console.log("VIEWUSERDETAISL", response);
        return response;
      })
      .catch((err) => {
        return err;
      });
  },
  createGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Group/addgroup",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewGroup({ getters, rootGetters }, groupID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Group/viewgroup",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          groupID: groupID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activategroup({ getters, rootGetters }, groupID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Group/activegroup",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          groupID: groupID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactivategroup({ getters, rootGetters }, groupID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Group/deactivegroup",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          groupID: groupID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateGroup({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Group/updategroup",
        {
          businessId: selectedBusiness.businessId,
          groupID: data.groupID,
          branchId: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addGroupDetails({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Group/addgroupdetails",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  changePackageStatus({ getters, rootGetters }, businessID) {
    console.log("CHANGE STATUS NEED BSNSID", businessID);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    // let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/BusinessPackage/ChangePackageStatus",
        {
          businessID: businessID,
          //branchID: "selectedBusiness.branchId",
        },
        null,
        options
      )
      .then((res) => {
        console.warn("ChangePackageStatus", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewPaymentDetails({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/BusinessPackage/ViewPaymentDetails",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          packageID: payload.packageID,
          paymentCycle: payload.paymentCycle,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  payment({ getters, rootGetters }, formdata) {
    console.log("formdata", formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/StripePayment/StripePayment",
        {
          businessID: selectedBusiness.businessId,
          stripeToken: formdata.source,
          packageID: formdata.packageID,
          payCycle: formdata.payCycle,
          stripeEmail: "danikhan458512@gmail.com",
          actionType: formdata.actionType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewPackage({ getters, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    //let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make("GET", "/Package/viewPackage", {}, null, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewBusinessPackage({ getters, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "BusinessPackage/ViewBusinessPackage",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  UpdateBusinessPackage({ getters, rootGetters }, data) {
    console.log("Data against which to update", data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/BusinessPackage/UpdateBusinessPackage",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  RenewBusinessPackage({ getters, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/BusinessPackage/RenewBusinessPackage",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getDefaultCurrency({ getters, rootGetters }, businessID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Business/DefaultCurrency",
        {
          businessID: businessID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewTax({ getters, rootGetters }, taxID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/TaxComponent/ViewTax",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          taxID: taxID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // Export Methods
  exportVendor({ state, getters, commit, rootState, rootGetters }, fileType) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ExportVendor",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: fileType,
        },
        {},
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedVendor(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ExportSelectedVendor",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
        },
        { vendorID: formData.vendorID, columns: formData.columns },
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  getBusinesses({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    return request
      .make("GET", "/User/userloginview", null, null, options)
      .then((response) => {
        commit("auth/setBranches", response.data.userLoginView, {
          root: true,
        });
        return response;
      })
      .catch((error) => {
        return error;
      });
  },
  setEditVendor({ state, getters, commit, rootState, rootGetters }, data) {
    commit("setEditVendor", data);
  },
  setUserRights({ state, getters, commit, rootState, rootGetters }, data) {
    commit("setUserRights", data);
  },
  setSelectBusiness({ state, getters, commit, rootState, rootGetters }, data) {
    commit("setSelectBusiness", data);
    // if (data == null) {
    //   commit("clearAllBranch", null);
    // }
  },

  getUserRights({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/UserRoles/returnuserrights",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        console.log("User rights", res);
        return res;
      })
      .catch((err) => {
        console.log("User rights", err);
        return err;
      });
  },
  updateVendor({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Vendor/UpdateVendor",
        {
          businessID: selectedBusiness.businessId,
          vendorID: data.vendorID,
          branchID: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateVendorByGroup({ getters, rootGetters }, data) {
    console.warn("data", data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PATCH",
        "/Vendor/UpdateVendorByGroup",
        {
          businessID: selectedBusiness.businessId,
          groupID: data.groupID,
          branchID: selectedBusiness.branchId,
        },
        data.data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importVendor({ state, getters, commit, rootState, rootGetters }, data) {
    console.warn("DATA is onfile SetupItem ", data.onfileSelected.file);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
      // config:{
      //     onUploadProgress:ProgressEvent=>{
      //       console.warn("Uploading Progress Event/////",ProgressEvent)
      //     }

      //   },
    };

    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ImportVendor",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        // onUploadProgress()=ProgressEvent=>{
        //   console.warn("1234")

        // },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  addVendor({ state, getters, commit, rootState, rootGetters }, formdata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/AddVendor",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getVendors({ state, getters, commit, rootState, rootGetters }, vendorID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Vendor/ViewVendor",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          vendorID: vendorID,
          pageNo: 0,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewVendorByGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ViewVendorByGroup",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          limit: payload.limit,
          pageNum: payload.pageNum,
          rowPerPage: payload.rowPerPage,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateVendor({ state, getters, commit, rootState, rootGetters }, vendorId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ActivateVendor",
        {
          businessId: selectedBusiness.businessId,
          vendorID: vendorId,
          branchID: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteVendor({ state, getters, commit, rootState, rootGetters }, vendorId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/DeActivateVendor",
        {
          businessId: selectedBusiness.businessId,
          vendorID: vendorId,
          branchID: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importCustomer({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/ImportCustomer",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateMultipleVendors(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Vendor/DeactivateMultipleVendors",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { vendorIDs: formData.vendorID },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // Activate multiple Vendors
  ActivateMultipleVendors(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Vendor/ActivateMultipleVendors",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        { vendorIDs: formData.vendorID },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateMultipleCustomer(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Customer/DeActivateMultipleCustomer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { customerIDs: formData.customerIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // activate multiple customer
  ActivateMultipleCustomer(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Customer/ActivateMultipleCustomer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { customerIDs: formData.customerIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateMultipleProducts(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Product/DeActivateMultipleProduct",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { productIDs: formData.productIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // activate multiple products
  ActivateMultipleProducts(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Product/ActivateMultipleProduct",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { productIDs: formData.productIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateMultipleBranches(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Branch/DeactivateMultipleBranches",
        {
          businessId: selectedBusiness.businessId,
          selfBranch: selectedBusiness.branchId,
        },
        { branchIDs: formData.branchIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  ActivateMultipleBranches(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Branch/ActivateMultipleBranches",
        {
          businessId: selectedBusiness.businessId,
          selfBranch: selectedBusiness.branchId,
        },
        { branchIDs: formData.branchIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  exportCustomer({ state, getters, commit, rootState, rootGetters }, fileType) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/ExportCustomer",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: fileType,
          customerID: "",
        },
        {},
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },

  exportBranch({ state, getters, commit, rootState, rootGetters }, fileType) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          fileType: fileType,
          ID: "",
        },
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportProduct({ state, getters, commit, rootState, rootGetters }, fileType) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/ExportProduct",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: fileType,
          ID: "",
        },
        {},
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedVendor(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Vendor/ExportSelectedVendor",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
        },
        {
          columns: formData.columns,
          mailingAddress: formData.MailingAddress,
          parmanentAddress: formData.ParmanentAddress,
          officeNo: formData.OfficePhone,
          personalNo: formData.PersonalPhone,
          vendorID: formData.vendorID,
        },
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedProduct(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    console.log("FORMDATA", formData);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/ExportSelectedProduct",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
        },
        { vendorID: formData.productID, columns: formData.columns },
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  exportSelectedCustomer(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/ExportSelectedCustomer",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
        },
        {
          columns: formData.columns,
          mailingAddress: formData.MailingAddress,
          parmanentAddress: formData.ParmanentAddress,
          officeNo: formData.OfficePhone,
          personalNo: formData.PersonalPhone,
          // customerID: formData.customerID,
          vendorID: formData.customerID,
        },
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },

  addCustomer({ state, getters, commit, rootState, rootGetters }, formdata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/AddCustomer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editCustomer({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Customer/UpdateCustomer",
        {
          businessId: selectedBusiness.businessId,
          customerID: data.customerID,
          branchId: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateCustomerByGroup({ getters, rootGetters }, data) {
    console.warn("data", data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PATCH",
        "/Customer/UpdateCustomerByGroup",
        {
          businessID: selectedBusiness.businessId,
          groupID: data.groupID,
          branchID: selectedBusiness.branchId,
        },
        data.data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownCustomers({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Customer/ShowCustomersForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCustomerByGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/ViewCustomerByGroup",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getCustomers({ state, getters, commit, rootState, rootGetters }, customerID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Customer/ViewCustomer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          customerID: customerID,
          pageNo: 0,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteCustomer(
    { state, getters, commit, rootState, rootGetters },
    customerID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/DeActivateCustomer",
        {
          businessId: selectedBusiness.businessId,
          customerID: customerID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateCustomer(
    { state, getters, commit, rootState, rootGetters },
    customerID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Customer/ActivateCustomer",
        {
          businessId: selectedBusiness.businessId,
          customerID: customerID,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewBranchByGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Branch/ViewBranchByGroup",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getBranches({ state, getters, commit, rootState, rootGetters }, branchId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Branch/ViewBranch",
        {
          businessId: selectedBusiness.businessId,
          viewBranch: branchId,
          selfBranch: selectedBusiness.branchId,
          // branchID: selectedBusiness.branchId,
          pageNo: 1,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editBranch({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Branch/UpdateBranch",
        {
          businessId: selectedBusiness.businessId,
          updateBranch: data.branchID,
          selfBranch: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  addBranch({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };

    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Branch/AddBranch",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activeBranch({ state, getters, commit, rootState, rootGetters }, branchID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Branch/ActivateBranch",
        {
          businessId: selectedBusiness.businessId,
          selfBranch: selectedBusiness.branchId,
          activateBranch: branchID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteBranch({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Branch/DeactivateBranch",
        {
          businessId: selectedBusiness.businessId,
          selfBranch: selectedBusiness.branchId,
          deactivateBranch: payload.branchID,
          flag: payload.flag,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editProduct({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Product/UpdateServiceProduct",
        {
          businessId: selectedBusiness.businessId,
          ProductID: data.ProductID,
          branchId: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importProduct({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/ImportProduct",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addProduct({ state, getters, commit, rootState, rootGetters }, formdata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/AddServiceProduct",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateProductByGroup({ getters, rootGetters }, data) {
    console.warn("data", data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PATCH",
        "/Product/UpdateProductByGroup",
        {
          businessID: selectedBusiness.businessId,
          groupID: data.groupID,
          branchID: selectedBusiness.branchId,
        },
        data.data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownBranches({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Branch/ShowBranchesForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownSalesman({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Employee/ShowSalesmanForDropdown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownEmployees({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Employee/ShowEmployeesForDropdown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownCustomers({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Customer/ShowCustomersForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownVendors({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Vendor/ShowVendorsForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  dropdownProducts({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Product/showproductsfordropdown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewProductByGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/ViewProductByGroup",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getProducts({ state, getters, commit, rootState, rootGetters }, productID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Product/ViewProduct",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          productID: productID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateProduct(
    { state, getters, commit, rootState, rootGetters },
    productID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/ActivateServiceProduct",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          productID: productID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactiveProduct(
    { state, getters, commit, rootState, rootGetters },
    productID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Product/DeActivateServiceProduct",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          productID: productID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  editCategory({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "PUT",
        "/Category/UpdateCategory",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          categoryName: data.categoryName,
          categoryId: data.categoryId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateCategory(
    { state, getters, commit, rootState, rootGetters },
    categoryId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Category/Activatecategory",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          categoryId: categoryId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactiveCategory(
    { state, getters, commit, rootState, rootGetters },
    categoryId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "POST",
        "/Category/Deactivatecategory",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          categoryId: categoryId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addCategory({ state, getters, commit, rootState, rootGetters }, data) {
    let formdata = JSON.stringify(data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Category/addcategory",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getCategory({ getters, rootGetters }, categoryId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Category/ViewCategory",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          categoryId: categoryId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCategoryForReports({ getters, rootGetters }, branchId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = getters.getSelectedBusiness;
    return request
      .make(
        "GET",
        "/Category/ViewCategoryForReports",
        {
          businessId: selectedBusiness.businessId,
          branchID: branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  gotoPreviousRoute({ state }) {
    let pathComponent = state.previousRoute.path.split("/");
    let module = pathComponent[pathComponent.length - 1];

    console.log(module, state.previousRoute);
    if (module == "invoice" || module == "edit") {
      let path = pathComponent.slice(0, pathComponent.length - 1).join("/");
      router.push(path);
    } else {
      router.push(state.previousRoute.path);
    }
  },
  setonDisplayPermissoinUserRights(
    { commit, state, getters },
    onDisplayForUserRight
  ) {
    commit("onDisplayPermissoinForUserRight", onDisplayForUserRight);
  },
};
const getters = {
  getPBInvoiceData(state) {
    return {
      invoiceData: state.invoiceData,
      invoiceName: state.invoiceName,
    };
  },
  getIsFromMBank: (state) => {
    return state.isFromMangeBnk;
  },
  getPurchaseId: (state) => {
    return state.purchseId;
  },
  getPurchaseInvoiceReturn: (state) => {
    return state.purchaseInvoiceReturn;
  },
  getonUserRightDisplayPermission(state) {
    return state.onUserRightDisplayPermission;
  },
  getProgressBar(state) {
    return state.progressBar;
  },
  getSelectedBusiness(state) {
    return state.selectBusiness;
  },
  getDefaultCurrency(state) {
    return state.selectBusiness.currency;
  },
  getBranch(state) {
    return state.selectBusiness.isHeadOffice;
  },
  getSelectedBusinessBranchId(state) {
    return state.selectBusiness.branchId;
  },
  getSetupItemID(state) {
    return state.setupItemID;
  },
  getPayrollItemName(state) {
    return state.payrollItemName;
  },
  viewUserRights(state) {
    return state.userrights;
  },
  getDrawer(state) {
    return state.drawer;
  },

  getUserRights: (state) => (data) => {
    console.log("Your Homepage is", data);
    let menuItemsId = menuItemId.menuItemId;
    let action = data.action;
    var foundMenuItem = menuItemsId.find(
      (element) => element.name == data.routeName
    ); //if router name is vendor look for menuItemId from data stored in centralized values
    if (!foundMenuItem) {
      return true;
    } else {
      var userPermissions = state.userrights.find(
        (element) => element.menuId == foundMenuItem.id
      ); //get permissions against that id
    }
    console.log("menu item id found having permissions ", userPermissions);
    if (!userPermissions) {
      return true;
    } else {
      let homepage = data.homepage;
      if (homepage == "add" && !action) return userPermissions.insertPermission;
      else if (homepage == "view" && !action)
        return userPermissions.displayPermission;
      else if (data.action == "view") return userPermissions.displayPermission;
      else if (data.action == "add") return userPermissions.insertPermission;
      else if (data.action == "edit") return userPermissions.updatePermission;
      else if (data.action == "delete") return userPermissions.deletePermission;
      else return true;
    }
  },
  // getVendorExportFile(){
  //   return state.vendorExportFile;
  // }
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state,
};
import store from "../index";
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function (resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          console.log("event", event);
          if (event.srcElement.status === 401) {
            store.commit("auth/setLoginExpired", true);
          } else if (event.srcElement.status === 403) {
            console.log("Forbidden", event.srcElement.status);
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).response[0].message
            );
            router.push({ name: "accessDenied" });
          } else if (event.srcElement.status === 500) {
            console.log(
              "Error 500",
              JSON.parse(event.srcElement.response).message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).message
            );
          } else {
            return new Promise(function (resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function (resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
