// import { stream } from "xlsx/types";

export default {
    length1:100,         //  name length 100 Name Country City Province
    length2:250,    //  description length 250 description streetLine1 streetLine2
    length3:30,        //  number length 30
    length4:10,           //  zip length 10
    length5:16,    //  phonenumber length 100
    length6:20,   // prefix length 20
    length7:5,  // setupitem Image Length
    length8:25,
    length9:50,   // Sale purchase
    alphabetsOnly:(val)=>{
        let patr = /^[a-zA-Z ]*$/;
        return patr.test(val);
      },
      removeComma(num,i){
        if(num==""){
          return ""
        }
        else{
            var str = num.toString().replace(/,/g , "")
        console.log('without comma in store',str)
        return str
        }
       
      
      },
      removePerHourComma(num,i){
        if(num==""){
          return ""
        }
        else{
        var str = num.toString().replace(/,/g , "")
        console.log('Remove Comma PerHour in Store comma',str)
        return str
        }
       

      },

      insertComma(num,i){
        if(num==""){
          return ""
        }
        else {
       var amount= parseFloat(num)
           var returnAmount = amount.toLocaleString();
           return returnAmount;
        }
      },
    

      alphabetsOnlyWithoutSpace:(val)=>{
        let patr = /^[a-zA-Z]*$/;
        return patr.test(val);
      },
      alphabetsNumeric:(val)=>{
        let patr = /^[a-zA-Z0-9 ]*$/;
        return patr.test(val);
      },
      userName:(val)=>{
        let patr = /^[._,a-zA-Z0-9]*$/;
        return patr.test(val);
      },
      userNameLength:(val)=>{
        let nameLength=val.length
        console.log("name length is ",nameLength)
        if(nameLength<3) return false
        else return true
      },
      checkSubstring:(val)=>{
        let substring=val.substring(0, 3);
        let patr = /^[a-zA-Z]*$/;
        return patr.test(substring);
      },
      passwordPattern:(val)=>{
        let patr = /^(?=.*[a-zA-Z])(?=.*[0-6])(?=.*[!@#\$%\^&\*])/;//(?=.{8,})
        return patr.test(val);
      },
      // contactNumber:(val)=>{
      //   if(val.length>=7 && val.length<=15 && !isNaN(parseInt(val)) ){
      //       return true
      //   }else{
      //       return false
      //   }
      // },
      checkDate: function(fromDate,val) {
        if (fromDate > val) {
          return false;
        } else {
          return true;
        }
      },
     
      checkAmount: function(balance,amount) {
        if (amount <= 0 || amount>balance || isNaN(amount)) {
          return false;
        } else {
          return true;
        }
      },
      requiredValue:function(val){
            let format=this.data.formatType
          if((val==null || val== -1) && format=="credit"){
              return false
          }else{
            return true
          }
          
        },
        // Names : (length should not be greater then 100)
        nameLength: function(val) {
         if(val){
          if (val.length>this.length1) {
            return false;
          } else {
            return true;
          }
         }else{
           return true
         }
          
        },
        // Codes : (length should not be greater then 5)
        codeLength: function(val) {
          if(val){
           if (val.length>this.length7) {
             return false;
           } else {
             return true;
           }
          }else{
            return true
          }
           
         },
       // Description: (length should not be greater then 250)
        descriptionLength: function(val) {
          if(val){
            if (val.length>this.length2) {
              return false;
            } else {
              return true;
            }
          }else{
            return true
          }
          
        },
        // Currency: (length should not be greater then 50)
        currencyLength: function(val) {
          if(val){
            if (val.length>this.length9) {
              return false;
            } else {
              return true;
            }
          }else{
            return true
          }
          
        },
        //Numbers: (length should not b greater the 30 and contains letters and numbers)
        startwithAlphabet:(val)=>{
          let patr = /^[a-z](?:_?[a-z0-9]+)*$/;
          return patr.test(val);
        },
        alphabetsNumberWithoutSpace:(val)=>{
          let patr = /^[a-zA-Z0-9]*$/;
          return patr.test(val);
        },
        alphabetsNumberDashWithoutSpace:(val)=>{
          let patr = /^[a-zA-Z0-9-]*$/;
          return patr.test(val);
        },

        numberLength: function(val) {
          if(val){
          if (val.length>this.length3) {
            return false;
          } else { 
       
            return true;
          }
        }
        else{
          return true;
          }
        },
       // Zip: (length should not b greater the 10 and doesnot contain special characters)
       zipLength: function(val) {
         if(val){
          if (val.length>this.length4) {
            return false;
          } else {
            return true;
          }
         } 
         else{
          return true;
         }
        
      },

     // PhoneNumbers: (length should not b greater the 16 and only contains -)

     PhoneNumberLength: function(val) {
       if(val){
        if (val.length>this.length5) {
          return false;
        } else {
          return true;
        }
       }
       else{
        return true;
       }
      
    },
    contactNumber:(val)=>{
      let patr=/^[+0-9](?:_?[-0-9])*$/ //+ AT START SHOW FIRST CHAR CAN BE + OR 0-9 
      // let patr = /^[0-9]*$/;
      if(val){
        return patr.test(val);
      }else return true
      
    },
    prefixLength: function(val) {
      if(val){
       if (val.length>this.length6) {
         return false;
       } else {
         return true;
       }
      }
      else{
       return true;
      }
     
   },

  //  Img Validations For setup item
  checkImageSize: function(val){
    if(!val){
      return true
    } else {
  
   let Sum = val.size
  
    let filesSize = this.bytesToSize(Sum)
    console.log("file size is...", filesSize)
    if(parseInt(filesSize.split(" ")[0]) >this.length7 && filesSize.split(" ")[1] == "M"){
      return false
    } else{
      return true
    }
    }
  },
  checkFilesType: function(val){
    console.log("file", val)
    if(!val){
      return true
    } else{
    // console.log("files type are....", val[0].name.split(".").pop())
      let validTypes = ['jpg','jpeg','png']
      if(validTypes.includes(val.name.split(".").pop().toLowerCase())){
        return true
      } else{
        return false
      }
    }
    
  },

   //  Img Validations For Account , Advisor
   checkFileImageSize: function(val){
    if(!val){
      return true
    } else {
  
   let Sum = val.size
    let filesSize = this.bytesToSize(Sum)
    console.log("file size is...", filesSize)
    if(parseInt(filesSize.split(" ")[0]) >this.length8 && filesSize.split(" ")[1] == "M"){
      return false
    } else{
      return true
    }
    }
  },
  checkFileImgType: function(val){
    console.log("file", val)
    if(!val){
      return true
    } else{
    
      let validTypes = ['jpg','jpeg','png','ppt','csv','xls','xlsx','doc','docx','txt','jpg','jpeg',"pdf"]
      if(validTypes.includes(val.name.split(".").pop().toLowerCase())){
        return true
      } else{
        return false
      }
    }
    
  },
  checkFilesTypeS: function(val){
    if(val.length==0){
      return true
    } else{
    // console.log("files type are....", val[0].name.split(".").pop())
    for(let i=0; i<val.length; i++){
      let validTypes = ['jpg','jpeg','png','ppt','csv','xls','xlsx','doc','docx','txt','jpg','jpeg',"pdf"]
      if(validTypes.includes(val[i].name.split(".").pop().toLowerCase())){
        return true
      } else{
        return false
      }
    }
    }
    
  },
  checkAttachmentType:function(val){
    if(val.length==0){
      return true
    }else{
      for(let i=0;i<val.length;i++){
        let validTypes=['jpg','jpeg','png']
        if(validTypes.includes(val[i].name.split(".").pop().toLowerCase())){
          return true
        } else{
          return false
        }
      }
    }
  },
  // Special journal validations

  checkFilesSizeS: function(val){
    if(val.length ==0){
      return true
    } else {
    console.log("image size is...",val)
    let sum;
    sum = val.map(item=> {
      return item.size
    })
    let Sum = sum.reduce((a, b) => a + b, 0)
    console.log("sum isss", sum)
    console.log("sum isss", Sum)
    let filesSize = this.bytesToSize(Sum)
    console.log("file size is...", filesSize)
    if(parseInt(filesSize.split(" ")[0]) >25 && filesSize.split(" ")[1] == "M"){
      return false
    } else{
      return true
    }
    }
  },

  bytesToSize(bytes) {
    let sizes = ['B', 'K', 'M', 'G', 'T', 'P'];
    for (var i = 0; i < sizes.length; i++) {
      if (bytes <= 1024) {
        return bytes + ' ' + sizes[i];
      } else {
        bytes = parseFloat(bytes / 1024).toFixed(2)
      }
    }
    return bytes + ' P';
  },

}