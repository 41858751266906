import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);
const theme = {
    // final color
    // drawer 182335
    // primary: '#fd435a',


    
       primary: '#2886ff',
       secondary: '#606060',
       success: '#00C853',
       error: '#FF5252',
       Warning:'#FFC260',
       accent:"#030303",
       info: '#2196F3',
       chiplight:'#ECEFF1',
       manageUserChip:'#ECEFF1',
       coaChip:'#ECEFF1',
       coaMenuBtn:'#1E293B',
       categoryMenuBtn:'#1E293B',
       mainBackground:'#F1F5F8',
       drawerbgcolor:'#0F172A',
       companyDrawerbgcolor:'#ffffff',
       companyDrawertopbgcolor:'#fafafa',
       companySideDrawerbgcolor:'#0F172A',
       white:'#ffffff',
       topBarBtnColor:"#0F172A",
       footerBgColor:"#ffffff",
       footerTextColor:"#757575", 
       
       // Start Alert Dialog Colors
       alertDialogFooterBg:'#F1F5F8',
       // End Alert Dialog Colors

       // Start Snackbar Colors
       snackbarSuccess: '#00C853', 
       // End Snackbar Colors

       // Start Data Table Select All Checkbox
       datatableCheckbox: '#2886ff',
       // End Data Table Select All Checkbox

       // Start Anchor
       anchor: '#2886ff',
        // End Anchor
 
       // Start Selected true toolbar
       selectedTollbarBg: '#0F172A',
        // End Selected true toolbar




       surface:'#f1f1f1', 
       background:'#F1F5F8',
        


       // light Colors
       primaryLight:"#e8f0ff",
       SuccessLight:'#D9F7E5',
       ErrorLight:'#FFE5E5',
       WarrningLight:'#FFF0D9',
       InfoLight:'#D2EAFD',
       AccentLight:'#ECEFF1',
       
}
const themeDark = { 

    surface:'#303134',
    
    background:'#303134',

    
    

       primary: '#2886ff',
       secondary: '#606060',
       success: '#00C853',
       error: '#FF5252',
       Warning:'#FFC260',
       accent:"#030303",
       info: '#2196F3',
       manageUserChip:'#1E293B',
       coaChip:'#0F172A',
       coaMenuBtn:'#ffffff',
       categoryMenuBtn:'#ffffff',
       chiplight:'#0F172A',
       mainBackground:'#0F172A',
       drawerbgcolor:'#0F172A',
       companyDrawerbgcolor:'#1E293B',
       companyDrawertopbgcolor:'#0F172A',
       companySideDrawerbgcolor:'#0F172A',
       white:'#1E293B',
       topBarBtnColor:"#ffffff",
       footerBgColor:"#1E293B",
       footerTextColor:"#ffffff",
       

       // Start Alert Dialog Colors
       alertDialogFooterBg:'#0F172A',
       // End Alert Dialog Colors

      // Start Snackbar Colors
      snackbarSuccess: '#00C853', 
      // End Snackbar Colors
     
       // Start Data Table Select All Checkbox
       datatableCheckbox: '#F1F5F8',
        // End Data Table Select All Checkbox

       // Start Anchor
       anchor: '#FF5252',
      // End Anchor

       // Start Selected true toolbar
       selectedTollbarBg: '#F1F5F8',
       selectedTollbartext: '#0F172A',
        // End Selected true toolbar



       // light Colors in dark mode
       primaryLight:"#e8f0ff",
       SuccessLight:'#D9F7E5',
       ErrorLight:'#FFE5E5',
       WarrningLight:'#FFF0D9',
       InfoLight:'#D2EAFD',
       AccentLight:'#ECEFF1',
      
}

export default new Vuetify({
    icons: {
        iconfont: 'mdi', // default - only for display purposes
      },
    
    theme: {
        dark: true,
        themes: {
            dark: themeDark,
            light: theme,
        },
      
    },
      // options: {
        //     customProperties: true
        //   },
 
});
