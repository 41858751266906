<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="vendorOptions"
      :search-input.sync="keyword"
      no-data-text="No Vendor Available"
      item-text="companyName"
      item-value="vendorID"
      clearable
      :dense="isDense"
      hide-selected
      :return-object="true"
      :label="vendorLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @click:clear="clearData"
      @change="valueChanged($event)"
    >
      <!--   :append-icon="showIcon?'mdi-plus':undefined"
      @click:append="vendorForm = true" -->
      <template v-slot:append v-if="showIcon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="vendorForm = true"
              small
              style="margin-top: -2px !important"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Vendor</span>
        </v-tooltip>
      </template>
      <template v-slot:selection="{ item, index }" v-if="isMultiple">
        <v-chip v-if="index === 0" small>
          <span>{{ item.companyName }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption"
          >(+{{ model.length - 1 }} others)</span
        >
      </template>
      <!-- <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template> -->
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="vendorForm" max-width="400px" persistent>
      <add-vendor :closeModal="closeModal" :showSnack="showSnack" />
    </v-dialog>
    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false" v-bind="attrs"> <v-icon>mdi-close</v-icon> </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import addVendor from "../miniComponent/addVendor";
import centralizedValues from "../../assets/js/centralizedValues";

export default {
  components: {
    addVendor,
  },
  props: {
    showIcon: {
      default: true,
    },
    isDense: {
      default: true,
    },
    isMultiple: {
      default: false,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: null,
    },
    vendorLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: true,
    },
    updateVendor: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      color: "success",
      timeout: centralizedValues.timeout,
      vendorForm: false,
      vendorName: null,
      vendorOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateVendor,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      count: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   vendorOptions: [],
    };
  },

  async created() {
    this.checkVendors();
    // alert(this.$store.getters['dropdowns/getProducts'](this.updateVendorData))
  },
  methods: {
    showSnack(msg, color) {
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    viewMainVendors() {
      //when updateVendor is cleared from dropdown get all prodycts without sending request
      this.vendorOptions = [];
      this.vendorOptions = [...this.$store.getters["dropdowns/getVendors"]];
      this.totalData = this.$store.getters["dropdowns/getVendorsCount"];
    },
    checkVendors() {
      console.log(
        "check vendor condition",
        this.updateVendor && this.operation != "add"
      );
      if (this.updateVendor && this.operation != "add") {
        this.vendorOptions.push(this.updateVendor);
      } else {
        this.vendorOptions = [...this.$store.getters["dropdowns/getVendors"]];
        this.totalData = this.$store.getters["dropdowns/getVendorsCount"];
      }
    },
    closeModal(type) {
      if (type == "success") {
        this.$store
          .dispatch("dropdowns/dropdownVendors", { keyword: "", offset: 0 })
          .then((res) => {
            this.checkVendors();
          });
      }
      this.vendorForm = false;
    },
    async clearData() {
      this.count = -1;

      this.vendorOptions = [];
      this.viewMainVendors();
      this.model = "";
      this.vendorName = null;
      this.$emit("vendorSelected", null);
    },
    valueChanged(e) {
      // console.log("this.model.vendorName",e)
      if (!e) {
        this.vendorName = null;
        this.$emit("vendorSelected", null);
        return;
      }
      console.log("selected vendor is", e);
      this.vendorName = e.companyName;
      if (this.returnObject == false) {
        if (this.isMultiple == true) {
          let data = [];
          this.model.forEach((val) => {
            data.push(val.vendorID);
          });
          this.$emit("vendorSelected", data);
        }
        // this.$emit("vendorSelected", data);
      } else {
        // alert("returning object")
        console.log("vendorSelected", e);
        this.$emit("vendorSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllVendors();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.vendorOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllVendors();
    },
    getAllVendors() {
      if (this.updateVendor != null && this.updateVendor != "") {
        let updateVendor = this.updateVendor;
        this.vendorOptions.push(updateVendor);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;
      this.$store
        .dispatch("setupItem/dropdownVendors", {
          keyword: this.keyword,
          offset: this.offset,
        })
        .then((res) => {
          if (res.data.success.toLowerCase() == "ok") {
            console.log("PRODUCTS FOR DROPDOWN", res);
            let oldData = this.vendorOptions;
            let resData = res.data.data;
            console.log("res.data.data", res.data.data);
            this.vendorOptions = [...oldData, ...resData];
            this.totalData = res.data.offsetAndTotalCount.totalCount;
          }
        })
        .finally(() => {
          if (sendingKeyword != this.keyword) {
            this.vendorOptions = [];
            this.offset = 0;
            (this.isLoading = true), this.getAllVendors();
          } else {
            this.isLoading = false;
          }
        });
    },
  },
  watch: {
    immediate: false,
    keyword(val) {
      this.count += 1;
      if (this.updateVendor) {
        if (val && val == this.updateVendor.companyName) {
          return;
        } //prevent searching selected product
      }
      if (val == this.vendorName && this.vendorName != null) {
        //dont search selected product again
        return;
      }
      if (!val && this.count < 2) {
        //prevent first empty search
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updateVendor: function (val) {
      if (val) {
        this.vendorOptions.push(val);
        this.vendorName = val.companyName;
      } else this.vendorName = val;
      this.count = 0;
      this.model = val;
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped></style>
