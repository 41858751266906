<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-text-field
          class="mt-2"
          label="Currency Name *"
          v-model="$v.otherCurrency.$model"
          :error-messages="otherCurrencyErrors"
          :counter="counter"
          filled
          dense
          hide-details="auto"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" class="my-0 py-0">
        <v-btn text color="primary" @click="addCurrency">Save</v-btn>
      </v-col>
    </v-row>
    <v-snackbar color="success" v-model="snackBar" transition="scale-transition" origin="center center">
      {{ snackMesg }}

      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackBar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import mainValidations from "../../assets/js/validations";
import errorMessages from "@/assets/js/errorMessages";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["getCurrency", "closeCurrency"],
  data() {
    return {
      loading: false,
      otherCurrency: "",
      snackBar: false,
      snackMesg: "",
      counter: mainValidations.length9,
    };
  },
  mixins: [validationMixin],
  validations: {
    otherCurrency: {
      required,
      checklength: (val) => {
        return mainValidations.currencyLength(val);
      },
    },
  },
  computed: {
    otherCurrencyErrors() {
      const errors = [];
      if (!this.$v.otherCurrency.$dirty) return errors;
      !this.$v.otherCurrency.required &&
        errors.push("Currency Name is required");
      !this.$v.otherCurrency.checklength &&
        errors.push(
          errorMessages.lengthMessages("Currency Name", mainValidations.length9)
        );
      return errors;
    },
  },
  methods: {
    addCurrency() {
      this.$v.otherCurrency.$touch();
      if (!this.$v.otherCurrency.$anyError) {
        const formdata = {
          currency: this.otherCurrency,
        };
        this.loading = true;
        this.$store
          .dispatch("settings/addCurrency", formdata)
          .then((res) => {
            console.log("Currency added", res);
            if (res.data.success == "OK") {
              this.loading = false;
              this.snackMesg = res.data.response[0].message;
              this.snackBar = true;
              this.otherCurrency = "";
              this.$v.otherCurrency.$reset();
              // this.$emit("currencyAdded")
              this.getCurrency();
              this.closeCurrency();
              // this.snackBar=true
              // this.$v.$reset()
            } else if ((res.data.success = "Failed")) {
              this.snackBar = true;
              this.snackMesg = res.data.response[0].message;
              this.loading = false;
              // alert("Currency already exists.");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
