import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import masterSubLayout from '../layouts/masterLayout/masterSubLayout'
Vue.use(VueRouter)

  const routes = [
    {
      path: '/',
      name: 'signin',
      meta: { layout: 'authLayout' ,requiresVisitor: true},
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Login')
    },
    {
      path: '/signup',
      name: 'signup',
      meta: { layout: 'authLayout' ,requiresVisitor: true},
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Register')
    },
    {
      path: '/forgotpassword',
      name: 'forgotpassword',
      meta: { layout: 'authLayout' ,requiresVisitor: true},
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ForgotPassword')
    },
    {
      path: '/signout',
      name: 'signout',
      meta: { layout: 'authLayout' },
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/Logout')
    },
    {
      path: '/resetPassword',
      name: 'resetPassword',
      meta: { layout: 'authLayout' ,requiresVisitor: true},
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/ResetPassword')
    },
    {
      path: '/setPassword',
      name: 'setPassword',
      meta: { layout: 'authLayout' ,requiresVisitor: true},
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/SetPassword')
    },
    {
      path: '/activateUser',
      name: 'activateUser',
      meta: { layout: 'authLayout',requiresVisitor: true },
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/activateUser')
    },
    {
      path: '/accessinvitation',
      name: 'accessinvitation',
      meta: { layout: 'authLayout',requiresVisitor: true },
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/accessinvitation')
    },
    {
      path: '/unlockuser',
      name: 'unlockuser',
      meta: { layout: 'authLayout',requiresVisitor: true },
      component: () => import(/* webpackChunkName: "auth" */ '../views/auth/unlockuser')
    },
    {
      path: '/businessSetup',
      name: 'businessSetup',
      meta: { layout: 'businessSetupLayout' ,requiresAuth: true},
      component: () => import(/* webpackChunkName: "system" */ '../views/businessSetup/companySetup')
    },
    {
      path: '/selectBusiness',
      name: 'selectBusiness',
      meta: { layout: 'selectBusinessLayout' ,requiresAuth: true},
      component: () => import(/* webpackChunkName: "system" */ '../views/system/selectBusiness')
    },
    {
      path: '/403',
      name: 'accessDenied',
      meta: { layout: 'errorStatusLayout' },
      component: () => import(/* webpackChunkName: "system" */ '../components/errorComponents/403'),
    },
    {
      path: '/notfound',
      name: 'notFound',
      meta: { layout: 'errorStatusLayout' },
      component: () => import(/* webpackChunkName: "system" */ '../components/errorComponents/404'),
    },
    {
      path: '/app',
      name: 'app',
      meta: { layout: 'masterLayout' ,requiresAuthAndBusiness: true},
      redirect:'/app/dashboard',
      component:masterSubLayout,
      children:[
        {
        path: 'dashboard',
        name: 'dashboard',
        meta: { layout: 'masterLayout' },
        component: () => import(/* webpackChunkName: "system" */ '../views/system/Dashboard'),
        },
        {
          path: 'vendor/:showComponent?',
          props:true,
          name: 'vendor',
          meta: { layout: 'masterLayout' , homepage:'view' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Vendor'),
        },
        {
          path: 'customer/:showComponent?',
          props:true,
          name: 'customer',
          meta: { layout: 'masterLayout' , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Customer'),
        },
       
        {
          path: 'branches/:showComponent?',
          props:true,
          name: 'branches',
          meta: { layout: 'masterLayout' , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Branches'),
        },
        {
          path: 'products/:showComponent?',
          props:true,
          name: 'products',
          meta: { layout: 'masterLayout' , homepage:'view' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Products'),
        },
        {
          path: 'categories',
          name: 'categories',
          meta: { layout: 'masterLayout' , homepage:'view' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Category'),
        },
        {
          path: 'purchase-bill/:showComponent?',
          props:true,
          name: 'purchasebill',
          meta: { layout: 'masterLayout' , homepage:'add' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Purchasebill'),
        },
        {
          path: 'purchase-quotation/:showComponent?',
          props:true,
          name: 'purchasequotation',
          meta: { layout: 'masterLayout' , homepage:'add' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/PurchaseQuotation'),
        },
        {
          path: 'purchase-order/:showComponent?',
          props:true,
          name: 'purchaseorder',
          meta: { layout: 'masterLayout' , homepage:'add' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/PurchaseOrder'),
        },
        {
          path: 'sale-invoice/:showComponent?',
          props:true,
          name: 'sale',
          meta: { layout: 'masterLayout' , homepage:'add' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Sale'),
        },
        {
          path: 'sale-quotation/:showComponent?',
          props:true,
          name: 'salequotation',
          meta: { layout: 'masterLayout' , homepage:'add' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/SaleQuotation'),
        },
        {
          path: 'manage-bank/:showComponent?',
          props:true,
          name: 'managebank',
          meta: { layout: 'masterLayout'  , homepage:'any'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/ManageBank'),
        },
        {
          path: 'reconciliationRule/:showComponent?',
          props:true,
          name: 'reconciliationRule',
          meta: { layout: 'masterLayout' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Rules'),
        },
        {
          path: 'employee/:showComponent?',
          props:true,
          name: 'employee',
          meta: { layout: 'masterLayout' , homepage:'view' },
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Employee'),
        },
        {
          path: 'payroll-item/:showComponent?',
          props:true,
          name: 'payrollitem',
          meta: { layout: 'masterLayout'  , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/PayrollItem'),
        },
        {
          path: 'payrun/:showComponent?',
          props:true,
          name: 'payrun',
          meta: { layout: 'masterLayout'  , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Payrun'),
        },
        {
          path: 'payslip/:showComponent?',
          props:true,
          name: 'payslip',
          meta: { layout: 'masterLayout'  , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Payslip'),
        },
        {
          path: 'manualjournal/:showComponent?',
          props:true,
          name: 'manualjournal',
          meta: { layout: 'masterLayout'  , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/ManualJournal'),
        },
        {
          path: 'special-journal/:showComponent?',
          props:true,
          name: 'specialjournal',
          meta: { layout: 'masterLayout'  , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/SpecialJournal'),
        },
        {
          path: 'setup-tax/:showComponent?',
          props:true,
          name: 'setuptax',
          meta: { layout: 'masterLayout'  , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/SetupTax'),
        },
        {
          path: 'fixed-asset-purchase/:showComponent?',
          props:true,
          name: 'fixedassetpurchase',
          meta: { layout: 'masterLayout' , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/FixedAssetPurchase'),
        },
        {
          path: 'fixed-asset-sale/:showComponent?',
          props:true,
          name: 'fixedassetsale',
          meta: { layout: 'masterLayout' , homepage:'add'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/FixedAssetSale'),
        },
        {
          path: 'fixed-asset-item/:showComponent?',
          props:true,
          name: 'fixedassetitem',
          meta: { layout: 'masterLayout' , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/FixedAssetItem'),
        },
        {
          path: 'company-settings/:showComponent?',
          props:true,
          name: 'companysettings',
          meta: { layout: 'compaySettingLayouts'  , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/CompanySettings'),
        },
        {
          path: 'chart-of-account/:showComponent?',
          props:true,
          name: 'chartofaccount',
          meta: { layout: 'masterLayout'  , homepage:'view'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/ChartOfAccount'),
        },
        {
          path: 'manage-user/:showComponent?',
          props:true,
          name: 'manageuser',
          meta: { layout: 'masterLayout'  , homepage:'any'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/ManageUser'),
        },
        {
          path: 'search/:showComponent?',
          props:true,
          name: 'search',
          meta: { layout: 'masterLayout'  , homepage:'any'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/SearchInvoices'),
        },
        {
          path: 'reports/:showComponent?',
          props:true,
          name: 'reports',
          meta: { layout: 'masterLayout'  , homepage:'any'},
          component: () => import(/* webpackChunkName: "system" */ '../views/system/Reports'),
        }
      ]
    },
    {
      path: '*',
      meta: { layout: 'errorStatusLayout' },
      component: () => import(/* webpackChunkName: "system" */ '../components/errorComponents/404'),
    },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.afterEach((to, from, next) => {
  store.dispatch('setupItem/setProgressBar',false)
})
router.beforeEach((to, from, next) => {
  store.dispatch('setupItem/setProgressBar',true)
  
  let action=null
  console.log("to in beforeEach",to)
  store.dispatch('setupItem/setpreviousRoute',{path:from.fullPath,name:from.name})
  if(Object.keys(to.params).includes("showComponent")){
    action=to.params.showComponent
  }
  let isPermitted=store.getters['setupItem/getUserRights']({routeName:to.name,action,homepage:to.meta.homepage})
  console.log("result from if user is permistted",isPermitted)
  if(!isPermitted){
    next({
      name: 'accessDenied',
    })
    return
  }
  if (to.matched.some(record => record.meta.requiresAuth)) {
    let isLogin = store.getters['auth/loggedIn']
    if (!store.getters['auth/loggedIn']) {
      next({
        name: 'signin',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresAuthAndBusiness)) {
    let isBusiness = store.getters['setupItem/getSelectedBusiness']
    let isLogin = store.getters['auth/loggedIn']
    if (!store.getters['auth/loggedIn']) {
      next({
        name: 'signin',
      })
    } else if (isBusiness == null || isBusiness == '') {
      next({
        name: 'selectBusiness',
      })
    } else {
      next()
    }
  } else if (to.matched.some(record => record.meta.requiresVisitor)) {
    if (store.getters['auth/loggedIn']) {
      next({name:'dashboard'})
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

export default router
