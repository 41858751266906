<template>
  <div id="app">
    <v-app>
      <component :is="layout">
        <router-view />
      </component>
    </v-app>
  </div>
</template>
<script>
export default {
  name: "app",
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  created() {
    if (this.$route.meta.requiresAuth) {
      this.$store.dispatch("auth/checkToken").then((res) => {
        console.log("Check for invalid token", res);
        if (res.data.success != "OK") {
          this.$router.push({ name: "signout" });
        }
      });
    }
  },
};
</script>
