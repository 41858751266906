<template>
  <v-app>
    <!-- <v-navigation-drawer
    v-model="drawer"
    app
  >
   
  </v-navigation-drawer>

  <v-app-bar app>
    <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title>Application</v-toolbar-title>
  </v-app-bar> -->
    <v-app-bar app color="white">
      <v-toolbar-title>
        <v-app-bar-nav-icon @click="drawer = !drawer" color="topBarBtnColor">
          <v-icon v-if="drawer == true">mdi-menu-open</v-icon>
          <v-icon v-else class="mdi-flip-h">mdi-menu-open</v-icon>
        </v-app-bar-nav-icon>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <div class="text-center">
        <v-list-item class="mb-0 pb-0">
          <v-list-item-avatar>
            <v-avatar color="primaryLight" size="40">
              <span class="primary--text subtitle-1 font-weight-medium">a</span>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="subtitle-1 font-weight-medium">
              abc
            </v-list-item-title>
            <v-list-item-subtitle>ss</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      width="330"
      hide-overlay
      color="companyDrawerbgcolor"
    >
      <v-navigation-drawer
        v-model="drawer"
        absolute
        mini-variant
        mini-variant-width="64"
        color="companySideDrawerbgcolor"
        dark
        hide-overlay
      >
        <v-avatar
          class="d-block text-center mx-auto mt-4"
          color="white"
          size="36"
        >
          <v-img src="../../assets/images/companySettingLogo.png"></v-img>
          <v-icon>mdi-account-convert</v-icon>
        </v-avatar>

        <v-divider class="my-3"></v-divider>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1 mb-2"
              fab
              text
              v-bind="attrs"
              v-on="on"
              :to="{ name: 'dashboard' }"
            >
              <v-icon> mdi-home </v-icon>
            </v-btn>
          </template>
          <span>Dashboard</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1 mb-2"
              fab
              text
              v-bind="attrs"
              v-on="on"
              :to="{ name: 'search' }"
            >
              <v-icon dark> mdi-magnify </v-icon>
            </v-btn>
          </template>
          <span>search</span>
        </v-tooltip>
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="mx-1 mb-2"
              fab
              text
              v-bind="attrs"
              v-on="on"
              :to="{ name: 'reports' }"
            >
              <v-icon> mdi-newspaper </v-icon>
            </v-btn>
          </template>
          <span>Reports</span>
        </v-tooltip>

        <template v-slot:append>
          
          
          <div>
            <v-tooltip right v-if="!$vuetify.theme.dark">
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="toggle_dark_mode"
                  class="mx-1 mb-1"
                  v-on="on"
                  fab
                  text
                >
                <v-icon>mdi-weather-sunny</v-icon>
                </v-btn>
              </template>
              <span>Switch to dark mode</span>
            </v-tooltip>
            <v-tooltip right  v-else>
              <template v-slot:activator="{ on }">
                <v-btn
                  @click="toggle_dark_mode"
                  class="mx-1 mb-1"
                  v-on="on"
                  fab
                  text
                >
                <v-icon>mdi-moon-waxing-crescent</v-icon>
                </v-btn>
              </template>
              <span>Switch to light mode</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn
                  class="mx-1 mb-1"
                  fab
                  text
                  @click="handleFullScreen()"
                  v-on="on"
                >
                  <v-icon>mdi-fullscreen</v-icon>
                </v-btn>
              </template>
              <span>Full Screen</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="mx-1 mb-2"
                  fab
                  text
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'signout' }"
                >
                  <v-icon>mdi-logout-variant</v-icon>
                </v-btn>
              </template>
              <span>Logout</span>
            </v-tooltip>
          </div>
        </template>
      </v-navigation-drawer>

      <v-sheet
        color="companyDrawertopbgcolor"
        height="64"
        width="100%"
        class="pl-15"
      >
        <v-list-item two-line>
          <v-list-item-avatar v-if="logo != null">
            <img :src="logo" />
          </v-list-item-avatar>
          <v-avatar v-else color="primaryLight" size="46" class="mr-6">
            <span class="primary--text subtitle-1 font-weight-medium">{{
              avatar
            }}</span>
          </v-avatar>
          <v-list-item-content>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-list-item-title
                  v-on="on"
                  class="subtitle-1 font-weight-medium"
                  >{{ businessName }}</v-list-item-title
                >
              </template>
              <span>{{ businessName }}</span>
            </v-tooltip>
            <v-list-item-subtitle>{{ branchName }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
      </v-sheet>

      <v-list class="pl-14 companyDrawerbgcolor">
        <v-subheader class="font-weight-medium">Company Settings</v-subheader>
        <v-list-item-group v-model="item" color="primary">
          <v-list-item @click="triggerChangeView('organizationSetting')">
            <v-list-item-icon>
              <v-icon>mdi-briefcase-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Organization Settings </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="triggerChangeView('currencySetting')">
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Currency Settings </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="triggerChangeView('financialsSetting')">
            <v-list-item-icon>
              <v-icon>mdi-account-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Financials Settings </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="triggerChangeView('invoiceSetting')">
            <v-list-item-icon>
              <v-icon>mdi-file-document-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Invoice Settings </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="triggerChangeView('coaRights')">
            <v-list-item-icon>
              <v-icon>mdi-check-decagram-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>COA Rights </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="triggerChangeView('accountReport')">
            <v-list-item-icon>
              <v-icon>mdi-text-box-check-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Accounts for Report </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="d-flex flex-row-reverse">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                :to="{ name: 'dashboard' }"
                class="mr-3 mb-3"
                color="error"
              >
                <v-icon left>mdi-location-exit</v-icon>Exit
              </v-btn>
            </template>
            <span>Back To Home</span>
          </v-tooltip>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main class="mainBackground">
      <!-- vue-router -->

      <router-view></router-view>
    </v-main>
    <!-- <footer-component/> -->
    <relogin></relogin>
    <v-footer
      color="footerBgColor footerTextColor--text subtitle-2 d-print-none"
      class="text-center"
      padless
    >
      <v-row no-gutters>
        <v-col class="text-center pa-1" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>R-Sheen Group of Companies</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import relogin from "@/components/LoginExpiredModel/relogin";
import fullscreen from "../../util/fullscreen";
import { mapMutations } from "vuex";
export default {
  data: () => ({ drawer: null, item: 0 }),
  components: {
    relogin,
  },
  methods: {
    toggle_dark_mode: function () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },
    ...mapMutations("companySetup", ["setonchangeValue"]),
    triggerChangeView(view) {
      this.setonchangeValue(view);
    },
    handleFullScreen() {
      fullscreen.toggleFullScreen();
    },
  },

  computed: {
    businessName() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.businessName;
      } else {
        return "";
      }
    },
    branchName() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.branchName;
      } else {
        return "";
      }
    },
    logo() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.logo;
      } else {
        return null;
      }
    },
    avatar() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        let businessName = allBusinessData.businessName;
        let arr = businessName.split(" ");
        let name = "";
        for (let i = 0; i < arr.length; i++) {
          if (i > 1) {
            break;
          }
          if (arr[i] != "") {
            name += arr[i][0];
          }
        }
        return name;
      } else {
        return "";
      }
    },
  },
};
</script>
