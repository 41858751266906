<template>
  <div>
    <v-app>
      <v-main class="mainBackground">
        <v-container fluid>
          <v-scale-transition appear hide-on-leave="true" origin="center center">
          <router-view />
          </v-scale-transition>
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
<script>
export default {};
</script>
