import axios from 'axios';
import router from '../router'
import store from '../store'
import config from "../config";

// creating custom instances
export const applicationPublic = axios.create()
export const applicationPrivate = axios.create()
applicationPrivate.defaults.baseURL = config.baseURL
applicationPublic.defaults.baseURL = config.baseURL

applicationPrivate.interceptors.request.use(request => {
    request.headers['Content-Type'] = 'application/json';
    request.headers['Authorization'] = 'Bearer'.concat(' ', store.getters['auth/loginToken']);
    return request;
})
applicationPublic.interceptors.request.use(request => {
    request.headers['Content-Type'] = 'application/json';
    return request;
})

//define response interceptors
applicationPrivate.interceptors.response.use(response => {
    // Any status codes that falls in 2xx cause this function to trigger
  return response;
}, (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    //check for 401 unauthorized
    // if (error.response.status === 401) {
    //     // remove cookies
    //     Cookies.remove('access_token')
    //     // push to login
    //     router.push({name: 'Login'})
    // }
    // returning errors to then/catch for further use
    return error;
})

