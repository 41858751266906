<template>
  <span>
    <v-breadcrumbs :items="items" divider="/" x-small class="ma-0 pa-0">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :exact="true" :disabled="item.disabled">
          <v-icon v-if="item.icon">{{ item.icon }}</v-icon>
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
  </span>
</template>
<script>
export default {
  props: ["module"],
  data() {
    return {
      items: [],
    };
  },
  methods: {
    getUrl(path, sub, index) {
      var pathToGo = "/" + path.split(sub)[0] + sub;
      if (pathToGo === "/app") {
        pathToGo = "/";
      }
      return pathToGo;
    },
  },
  mounted() {
    let path = this.$route.path.substr(1);
    let rawPaths = path.split("/");
    rawPaths.map((sub, index) => {
      let text = "";
      let isDisabled = false;
      let light = false;
      let href = "";
      let icon = "";

      if (index == 0) {
        text = "";
        (icon = "mdi-home-outline"), (href = this.getUrl(path, sub, index));
      } else if (index == 1) {
        text = sub.replace(/-/g, " ");
        href = this.getUrl(path, sub, index);
      } else if (index == rawPaths.length - 1) {
        text = sub.replace(/-/g, " ");
        href = this.getUrl(path, sub, index);
        isDisabled = false;
      }

      this.items.push({
        exact: true,
        icon,
        text,
        disabled: isDisabled,
        href,
      });
      if (index == 0 && this.module) {
        this.items.push({
          exact: true,
          icon: "",
          text: this.module,
          disabled: true,
          href: "",
        });
      }
    });
  },
};
</script>
