import { render, staticRenderFns } from "./vendorDropdown.vue?vue&type=template&id=70d4a49a&scoped=true"
import script from "./vendorDropdown.vue?vue&type=script&lang=js"
export * from "./vendorDropdown.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70d4a49a",
  null
  
)

export default component.exports