<template>
  <div>
    <v-card>
      <v-card-title class="subtitle-1 font-weight-medium py-3">
        Add Discount
        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="closeModal">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-1">
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <v-text-field
              label="Enter Discount %"
              v-model="$v.discount_applied.$model"
              :error-messages="discountErrors"
              @input="checkDiscount"
              @keyup="calculateDiscount"
              filled
              type="number"
              hint="Please Enter Discount in Percentage"
              clearable
            >
            </v-text-field>
            <v-slide-y-transition
       
      
        tag="v-subheader"

      >
            <v-subheader class="subtitle-1 font-weight-medium mt-4 mb-4 pa-0" style="height: 18px !important;"  v-if="calculatedDiscount > 0"> Discount Value is &nbsp; <span class="font-weight-bold error--text">{{ insertComma(calculatedDiscount) }}</span>
             </v-subheader>
             </v-slide-y-transition>
          </v-col>
          
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" text @click="saveDiscount"
          ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
        >
        <v-btn text color="error" @click="closeModal"
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
const { required } = require("vuelidate/lib/validators");
import { validationMixin } from "vuelidate";
export default {
  props: [
    "grossTotal",
    "onClose",
    "closeDiscountModal",
    "discount_applied",
    "totalDiscount",
    "prevDiscount",
  ],
  data() {
    return {
      calculatedDiscount: this.totalDiscount,
      disc_type: "Percent",
      discount_applied: this.discount_applied,
    };
  },
  updated() {
    this.calculateDiscount();
  },
  methods: {
    insertComma(amount) {
      return new Intl.NumberFormat("en-US", {
        maximumSignificantDigits: 15,
      }).format(amount);
    },
    checkDiscount() {
      if (!this.discount_applied) {
        this.discount_applied = 0;
      }
    },
    //       calculateDiscount(){
    // //  if (this.disc_type == "value") {
    // //         this.discount_applied = this.calculatedDiscount;
    // //       } else if(this.disc_type == "Percent"){
    // //         this.discount_applied = (this.discount_applied /this.grossTotal)*100;
    // //       }
    // this.discount_applied = (this.discount_applied /this.grossTotal)*100;
    //       },
    calculateDiscount() {
      this.$v.discount_applied.$touch();
      if (!this.$v.discount_applied.$anyError) {
        let disc = 0;
        // if (this.disc_type == "value") {
        //   disc = this.discount_applied;
        // } else if(this.disc_type == "Percent"){
        //   disc = (this.discount_applied / 100) * this.grossTotal;
        // }
        disc = (this.discount_applied / 100) * this.grossTotal;
        this.calculatedDiscount = disc;
      }
    },
    changeValue(val, e) {
      this.discount_applied = val;
      this.calculatedDiscount = e;
    },
    closeModal() {
      // this.discount_applied = 0;
      // this.calculatedDiscount = 0;
      this.closeDiscountModal(this.disc_type);
      // this.$emit("discountCalculated", 0);
      // this.$v.discount_applied.$reset();
    },
    saveDiscount() {
      this.$v.discount_applied.$touch();
      if (!this.$v.discount_applied.$anyError) {
        console.log("no error");
        let disc = 0;
        // if (this.disc_type == "value") {
        //   disc = this.discount_applied;
        // } else if(this.disc_type == "Percent"){
        //   disc = (this.discount_applied / 100) * this.grossTotal;
        // }
        disc = (this.discount_applied / 100) * this.grossTotal;
        this.calculatedDiscount = disc;
        this.$emit("discountCalculated", this.discount_applied);
        this.closeDiscountModal(this.disc_type);
      }
    },
  },
  computed: {
    //         calcDiscount(){
    //             let disc=0
    //             if(this.grossTotal>0){
    // if (this.disc_type == "value") {
    //         disc = this.discount_applied;
    //       } else {
    //         disc = (this.discount_applied / 100) * this.grossTotal;
    //       }
    //       this.calculatedDiscount= disc;
    //             }

    //       return disc
    //         },
    size() {
      const size = { xs: "x-small", sm: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },
    discountErrors() {
      const errors = [];
      if (!this.$v.discount_applied.$dirty) return errors;
      !this.$v.discount_applied.required && errors.push("Enter Discount");
      if (!this.$v.discount_applied.$dirty) return errors;
      !this.$v.discount_applied.greaterThenZero &&
        errors.push("Discount can't be less than 0");
      !this.$v.discount_applied.greaterThenHundred &&
        errors.push("Discount can't be greater than 100");
      // !this.$v.discount_applied.greaterThanGrossTotal &&
      //   errors.push("Discount can't be greater than Gross Total");
      //     !this.$v.discount_applied.greaterThanInvoiceDiscount &&
      //   errors.push("Discount can't be greater than Invoice Discount");
      return errors;
    },
  },
  mixins: [validationMixin],
  validations: {
    discount_applied: {
      required,
      greaterThenZero: function (val) {
        if (val < 0) {
          return false;
        } else return true;
      },
      greaterThenHundred: function (val) {
        if (this.disc_type == "Percent" && val >= 100) {
          return false;
        } else return true;
      },
      // greaterThanGrossTotal: function(val) {
      // if(this.disc_type=="value" && val >this.grossTotal && !this.prevDiscount){
      //   return false;
      // } else return true;
      // },
      // greaterThanInvoiceDiscount: function(val) {
      // if(this.disc_type=="value" && val >this.grossTotal && val>this.prevDiscount){
      //   return false;
      // } else return true;
      // },
    },
  },
};
</script>

<style lang="scss" scoped></style>
