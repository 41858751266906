<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="salesmanOptions"
      :search-input.sync="keyword"
      no-data-text="No Saleman Available"
      item-text="employeeFullName"
      item-value="employeeID"
      clearable
      dense
      hide-selected
      @click:clear="clearData"
      :return-object="true"
      :label="salesmanLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @change="valueChanged($event)"
    >
      <template v-slot:append v-if="showIcon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="EmployeeForm = true"
              small
              style="margin-top: -2px !important"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Category</span>
        </v-tooltip>
      </template>
      <!--   append-icon="mdi-plus"
      @click:append="EmployeeForm = true" -->
      <!-- <template v-slot:append>
                <v-progress-circular
                  v-if="isLoading"
                  color="primary"
                  :width="3"
                  size="24"
                  indeterminate
        ></v-progress-circular>
            </template> -->
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="EmployeeForm" max-width="400px" persistent>
      <add-new-employee
        :isSalesman="true"
        @addedEmployee="keyword = $event"
        :showSnack="showSnack"
        :closeModal="closeModal"
      />
    </v-dialog>
    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false" v-bind="attrs"> <v-icon>mdi-close</v-icon> </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import addNewEmployee from "../miniComponent/addEmployee";
import centralizedValues from "../../assets/js/centralizedValues";
export default {
  components: {
    addNewEmployee,
  },
  props: {
    showIcon: {
      default: true,
    },
    isMultiple: {
      default: false,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: [],
    },
    salesmanLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: false,
    },
    updateSalesman: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      color: "success",
      timeout: centralizedValues.timeout,
      //start
      userRights: null,
      isAllowedToViewSalesMan: false,
      employeeFullName: null,
      EmployeeForm: false,
      salesmanOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateSalesman,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
    };
  },
  created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 6 && val.displayPermission) {
        this.isAllowedToViewSalesMan = true;
      }
    });
  },
  methods: {
    showSnack(msg, color) {
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    clearData() {
      this.model = "";
      this.$emit("salesmanSelected", null);
    },
    closeModal() {
      this.EmployeeForm = false;
    },
    valueChanged(e) {
      this.employeeFullName = e.employeeFullName;
      if (this.returnObject == false) {
        this.$emit("salesmanSelected", e.employeeID);
      } else {
        this.$emit("salesmanSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllSalesman();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.salesmanOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllSalesman();
    },
    getAllSalesman() {
      if (this.updateSalesman != null && this.updateSalesman != "") {
        let updateSalesman = this.updateSalesman;
        this.salesmanOptions.push(updateSalesman);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;

      if (this.isAllowedToViewSalesMan) {
        this.$store
          .dispatch("setupItem/dropdownSalesman", {
            keyword: this.keyword,
            offset: this.offset,
          })
          .then((res) => {
            if (res.data.success == "OK") {
              let oldData = this.salesmanOptions;
              let resData = res.data.data;
              this.salesmanOptions = [...oldData, ...resData];
              this.totalData = res.data.offsetAndTotalCount.totalCount;
            }
          })
          .finally(() => {
            if (sendingKeyword != this.keyword) {
              this.salesmanOptions = [];
              this.offset = 0;
              (this.isLoading = true), this.getAllSalesman();
            } else {
              this.isLoading = false;
            }
          });
      }
    },
  },
  watch: {
    keyword(val) {
      if (val == this.employeeFullName && this.employeeFullName != null) {
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);
    },
    updateSalesman: function (val) {
      this.model = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
