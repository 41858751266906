<template>
  <v-app>
    <TopNav />
    <v-main class="pb-15 mainBackground">
      <!-- vue-router -->
      <v-scroll-x-reverse-transition appear hide-on-leave="true">
        <router-view></router-view>
      </v-scroll-x-reverse-transition>
     
    </v-main>
    <!-- <footer-component/> -->
    <relogin></relogin>
    <v-footer
      color="footerBgColor footerTextColor--text subtitle-2 d-print-none"
      class="text-center"
      padless
    >
      <v-row no-gutters>
        <v-col class="text-center pa-1" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>R-Sheen Group of Companies</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import relogin from "@/components/LoginExpiredModel/relogin";
import TopNav from "@/layouts/containers/topNav.vue";
export default {
  components: {
    TopNav,
    relogin,
  },
};
</script>
<style>



</style>
