export default {
    setupItemImgHintMesg:"Please upload .jpeg .jpg .png image which is smaller than 5 MB in size.",
    AdvisorFileImgHintMesg:"Please upload .ppt .csv .xls .xlsx .doc .docx .txt .jpg .jpeg .png image/file which is smaller than 25 MB in size.",


    lengthMessages(name,length){
        return `${name} should be less than equal to ${length}`
        },
    onlyAlphabetsMessages(name){
            return `${name} Must contain alphabets only`
        },
        onlyAlphabetsWithoutSpaceMessages(){
            return ` Must contain alphabets only without space`
        },   
    requiredMessages(name){
        return `${name} is required`
    },
    alreadyExistsMessages(name){
        return `${name} already exists`
    },
    onlyAlphabetsNumberMessages(name){
        return `${name} must be alphabets & numbers without spaces`
    },
    onlyAlphabetsNumberDashMessages(name){
        return `${name} must be alphabets & numbers without spaces only - (dash) is allowed as special character`
    },
    AlphabetsNumberMessage(name){
        return `${name} must be alphabets & numbers`
    },
    startwithAlphabetsNumberMessage(name){
        return `${name} must be alphabets & numbers no space or special character allowed`
    },
    contactNumbersMessages(){
        return `Number must be numeric and can contain +, - or white-spaces are not allowed`
    },
    emailErrors(){
        return `Invalid Email format`
    },
    uniqueValue(){
        return `Value must be unique`
    },
    usernameErrors(){
        return `Username should contain numbers, alphabets and '. and _' as special characters only`
    },
    usernameCheckSubstringMesg(){
        return `First three characters must be alphabets`
    },
    usernameMinLengthMesg(){
        return `Minimum three characters allowed`
    },
    usernameMaxLengthMesg(){
        return `Maximum 100 characters allowed`
    },
   
    setupItemImgTypeMesg(){
        return `Please select a valid image. png, jpg and jpeg`
    },
    setupItemImgSizeMesg(length){
        return `The files size must be less then ${length}MB`
    },
    fileImgSizeMesg(length){
        return `The files size must be less then ${length}MB`
    },
    FilesImagesTypeMesg(){
        return `Please select a valid file .ppt .csv .xls .xlsx .doc .docx .txt .jpg .jpeg .png`
    }

}