import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import vuetify from "./plugins/vuetify";
import Vuelidate from "vuelidate";
import VueTelInput from "vue-tel-input";
import ElementUI from "element-ui";
import { Table, TableColumn } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";
import config from "./config";
import JsonCSV from 'vue-json-csv';
import VueGoogleCharts from 'vue-google-charts'
import VueZoomer from 'vue-zoomer'
import "@/assets/scss/_global.scss";
Vue.component('downloadCsv', JsonCSV)
Vue.use(ElementUI, { locale });
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Vuelidate);
Vue.use(VueTelInput);
Vue.use(VueGoogleCharts);
Vue.use(VueZoomer);
axios.defaults.baseURL = config.baseURL;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
//Global components in this project
import categoryDropdown from '@/components/Dropdowns/categoryDD'
import contactsDropdown from '@/components/Dropdowns/contactsDD'
import vendorsDropdown from '@/components/Dropdowns/vendorDropdown'
import customersDropdown from '@/components/Dropdowns/customerDD'
import employeesDropdown from '@/components/Dropdowns/employeeDD'
import productsDropdown from '@/components/Dropdowns/productsDropdown'
import salesmanDropdown from '@/components/Dropdowns/salesmanDD'
import branchDropdown from '@/components/Dropdowns/branchesDD'
import payrollDropdown from '@/components/Dropdowns/payrollDD'
import fixedAssetDropdown from '@/components/Dropdowns/fixedAssetDropdown'
import fixedAssetForPurchase from '@/components/Dropdowns/fixedAssetForPurchase'
import fixedAssetForSale from '@/components/Dropdowns/fixedAssetForSale'
import addNewCurrency from "@/components/miniComponent/addCurrency";
import addOtherCurrency from "@/components/miniComponent/otherCurrency";
import addNewCategory from "@/components/miniComponent/addCategory";
import Breadcrumb from "@/components/miniComponent/Breadcrumb";
import discount from "@/components/miniComponent/discount";
import groups from "@/views/system/Groups";
import notFound from "@/components/errorComponents/404";
import exchangeRate from "@/components/miniComponent/exchangeRate";
import amountValidation from "@/mixins/amountValidation.js"
Vue.component('notFound',notFound)
Vue.component('Breadcrumb',Breadcrumb)
Vue.component('categoryDropdown',categoryDropdown)
Vue.component('payrollDropdown',payrollDropdown)
Vue.component('contactsDropdown',contactsDropdown)
Vue.component('fixedAssetDropdown',fixedAssetDropdown)
Vue.component('fixedAssetForPurchase',fixedAssetForPurchase)
Vue.component('fixedAssetForSale',fixedAssetForSale)
Vue.component('vendorsDropdown',vendorsDropdown)
Vue.component('branchDropdown',branchDropdown)
Vue.component('customersDropdown',customersDropdown)
Vue.component('employeesDropdown',employeesDropdown)
Vue.component('productsDropdown',productsDropdown)
Vue.component('salesmanDropdown',salesmanDropdown)
Vue.component('addNewCurrency',addNewCurrency)
Vue.component('addOtherCurrency',addOtherCurrency)
Vue.component('addNewCategory',addNewCategory)
Vue.component('exchangeRate',exchangeRate)
Vue.component('groups',groups)
Vue.component('discount',discount)

// Vue.mixin({
//     mixins: [amountValidation],
//     mounted(){
//       console.log("MIXIN CALLED>>>>")
//     }
// })
//End of global registeration
//Layouts import only
import masterLayout from './layouts/masterLayout/masterLayout'
import compaySettingLayouts from './layouts/masterLayout/compaySettingLayouts'
import errorStatusLayout from './layouts/masterLayout/errorStatusLayout'
import searchLayout from './layouts/masterLayout/searchLayout'
import selectBusinessLayout from './layouts/masterLayout/selectBusinessLayout'
import authLayout from './layouts/authLayout'
import businessSetupLayout from './layouts/businessSetupLayout'
Vue.component('masterLayout',masterLayout)
Vue.component('compaySettingLayouts',compaySettingLayouts)
Vue.component('errorStatusLayout',errorStatusLayout)
Vue.component('searchLayout',searchLayout)
Vue.component('selectBusinessLayout',selectBusinessLayout)
Vue.component('authLayout',authLayout)
Vue.component('businessSetupLayout',businessSetupLayout)
//layouts ends
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
