export default {
  companyType: [
    'Company',
    'Partnership',
    'Sole Trader',
    'Non Profit',
    'Government Agency',
    'Club or Society',
     'Trust',
     'Other'
     
     
  ],
  date: [
    {
      value: '01',
      text: '1'
    },
    {
      value: '02',
      text: '2'
    },
    {
      value: '03',
      text: '3'
    },
    {
      value: '04',
      text: '4'
    },
    {
      value: '05',
      text: '5'
    },
    {
      value: '06',
      text: '6'
    },
    {
      value: '07',
      text: '7'
    },
    {
      value: '08',
      text: '8'
    },
    {
      value: '09',
      text: '9'
    }, '10', '11', '12', '13', '14',
    '15', '16', '17', '18', '19',
    '20', '21', '22', '23', '24',
    '25', '26', '27', '28', '29',
    '30', '31'
  ],
  month: [
    {
      value: '01',
      text: 'January'
    },
    {
      value: '02',
      text: 'February'
    },
    {
      value: '03',
      text: 'March'
    },
    {
      value: '04',
      text: 'April'
    },
    {
      value: '05',
      text: 'May'
    },
    {
      value: '06',
      text: 'June'
    },
    {
      value: '07',
      text: 'July'
    },
    {
      value: '08',
      text: 'August'
    },
    {
      value: '09',
      text: 'September'
    },
    {
      value: '10',
      text: 'October'
    },
    {
      value: '11',
      text: 'November'
    },
    {
      value: '12',
      text: 'December'
    }
  ],
  timePeriod: [
    {
      value: 1,
      text: '1 Month'
    },
    {
      value: 2,
      text: '3 Months'
    },
    {
      value: 3,
      text: '6 Months'
    },
    {
      value: 4,
      text: '9 Months'
    },
    {
      value: 5,
      text: 'Annual'
    }
  ],
  modescurency: [
    {
      value: "USD",
      text: "US dollar"
    },
    {
      value: "EUR",
      text: "Euro"
    },
    {
      value: "JPY",
      text: "Japanese yen"
    },
    {
      value: "GBP",
      text: "Pound sterling"
    },
    {
      value: "AED",
      text: "United Arab Emirates dirham"
    },
    {
      value: "AFN",
      text: "Afghan afghani"
    },
    {
      value: "ALL",
      text: "Albanian lek"
    },
    {
      value: "AMD",
      text: "Armenian dram"
    },
    {
      value: "ANG",
      text: "Netherlands Antillean guilder"
    },
    {
      value: "AOA",
      text: "Angolan kwanza"
    },
    {
      value: "ARS",
      text: "Argentine peso"
    },
    {
      value: "AUD",
      text: "Australian dollar"
    },
    {
      value: "AWG",
      text: "Aruban florin"
    },
    {
      value: "AZN",
      text: "Azerbaijani manat"
    },
    {
      value: "BAM",
      text: "Bosnia and Herzegovina convertible mark"
    },
    {
      value: "BBD",
      text: "Barbadian dollar"
    },
    {
      value: "BDT",
      text: "Bangladeshi taka"
    },
    {
      value: "BGN",
      text: "Bulgarian lev"
    },
    {
      value: "BHD",
      text: "Bahraini dinar"
    },
    {
      value: "BIF",
      text: "Burundian franc"
    },
    {
      value: "BMD",
      text: "Bermudian dollar"
    },
    {
      value: "BND",
      text: "Brunei dollar"
    },
    {
      value: "BOB",
      text: "Bolivian boliviano"
    },
    {
      value: "BRL",
      text: "Brazilian real"
    },
    {
      value: "BSD",
      text: "Bahamian dollar"
    },
    {
      value: "BTN",
      text: "Bhutanese ngultrum"
    },
    {
      value: "BWP",
      text: "Botswana pula"
    },
    {
      value: "BYN",
      text: "Belarusian ruble"
    },
    {
      value: "BZD",
      text: "Belize dollar"
    },
    {
      value: "CAD",
      text: "Canadian dollar"
    },
    {
      value: "CDF",
      text: "Congolese franc"
    },
    {
      value: "CHF",
      text: "Swiss franc"
    },
    {
      value: "CLP",
      text: "Chilean peso"
    },
    {
      value: "CNY",
      text: "Chinese yuan"
    },
    {
      value: "COP",
      text: "Colombian peso"
    },
    {
      value: "CRC",
      text: "Costa Rican colón"
    },
    {
      value: "CUC",
      text: "Cuban convertible peso"
    },
    {
      value: "CUP",
      text: "Cuban peso"
    },
    {
      value: "CVE",
      text: "Cape Verdean escudo"
    },
    {
      value: "CZK",
      text: "Czech koruna"
    },
    {
      value: "DJF",
      text: "Djiboutian franc"
    },
    {
      value: "DKK",
      text: "Danish krone"
    },
    {
      value: "DOP",
      text: "Dominican peso"
    },
    {
      value: "DZD",
      text: "Algerian dinar"
    },
    {
      value: "EGP",
      text: "Egyptian pound"
    },
    {
      value: "ERN",
      text: "Eritrean nakfa"
    },
    {
      value: "ETB",
      text: "Ethiopian birr"
    },
    {
      value: "EUR",
      text: "EURO"
    },
    {
      value: "FJD",
      text: "Fijian dollar"
    },
    {
      value: "FKP",
      text: "Falkland Islands pound"
    },
    {
      value: "GBP",
      text: "British pound"
    },
    {
      value: "GEL",
      text: "Georgian lari"
    },
    {
      value: "GGP",
      text: "Guernsey pound"
    },
    {
      value: "GHS",
      text: "Ghanaian cedi"
    },
    {
      value: "GIP",
      text: "Gibraltar pound"
    },
    {
      value: "GMD",
      text: "Gambian dalasi"
    },
    {
      value: "GNF",
      text: "Guinean franc"
    },
    {
      value: "GTQ",
      text: "Guatemalan quetzal"
    },
    {
      value: "GYD",
      text: "Guyanese dollar"
    },
    {
      value: "HKD",
      text: "Hong Kong dollar"
    },
    {
      value: "HNL",
      text: "Honduran lempira"
    },
    {
      value: "HKD",
      text: "Hong Kong dollar"
    },
    {
      value: "HRK",
      text: "Croatian kuna"
    },
    {
      value: "HTG",
      text: "Haitian gourde"
    },
    {
      value: "HUF",
      text: "Hungarian forint"
    },
    {
      value: "IDR",
      text: "Indonesian rupiah"
    },
    {
      value: "ILS",
      text: "Israeli new shekel"
    },
    {
      value: "IMP",
      text: "Manx pound"
    },
    {
      value: "INR",
      text: "Indian rupee"
    },
    {
      value: "IQD",
      text: "Iraqi dinar"
    },
    {
      value: "IRR",
      text: "Iranian rial"
    },
    {
      value: "ISK",
      text: "Icelandic króna"
    },
    {
      value: "JEP",
      text: "Jersey pound"
    },
    {
      value: "JMD",
      text: "Jamaican dollar"
    },
    {
      value: "JOD",
      text: "Jordanian dinar"
    },
    {
      value: "JPY",
      text: "Japanese yen"
    },
    {
      value: "KES",
      text: "Kenyan shilling"
    },
    {
      value: "KGS",
      text: "Kyrgyzstani som"
    },
    {
      value: "KHR",
      text: "Cambodian riel"
    },
    {
      value: "KID",
      text: "Kiribati dollar"
    },
    {
      value: "KMF",
      text: "Comorian franc"
    },
    {
      value: "KPW",
      text: "North Korean won"
    },
    {
      value: "KRW",
      text: "South Korean won"
    },
    {
      value: "KWD",
      text: "Kuwaiti dinar"
    },
    {
      value: "KYD",
      text: "Cayman Islands dollar"
    },
    {
      value: "KZT",
      text: "Kazakhstani tenge"
    },
    {
      value: "LAK",
      text: "Lao kip"
    },
    {
      value: "LBP",
      text: "Lebanese pound"
    },
    {
      value: "LKR",
      text: "Sri Lankan rupee"
    },
    {
      value: "LRD",
      text: "Liberian dollar"
    },
    {
      value: "LSL",
      text: "Lesotho loti"
    },
    {
      value: "LYD",
      text: "Libyan dinar"
    },
    {
      value: "MAD",
      text: "Moroccan dirham"
    },
    {
      value: "MDL",
      text: "Moldovan leu"
    },
    {
      value: "MGA",
      text: "Malagasy ariary"
    },
    {
      value: "MKD",
      text: "Macedonian denar"
    },
    {
      value: "MMK",
      text: "Burmese kyat"
    },
    {
      value: "MNT",
      text: "Mongolian tögrög"
    },
    {
      value: "MOP",
      text: "Macanese pataca"
    },
    {
      value: "MRU",
      text: "Mauritanian ouguiya"
    },
    {
      value: "MUR",
      text: "Mauritian rupee"
    },
    {
      value: "MVR",
      text: "Maldivian rufiyaa"
    },
    {
      value: "MWK",
      text: "Malawian kwacha"
    },
    {
      value: "MXN",
      text: "Mexican peso"
    },
    {
      value: "MYR",
      text: "Malaysian ringgit"
    },
    {
      value: "MZN",
      text: "Mozambican metical"
    },
    {
      value: "NAD",
      text: "Namibian dollar"
    },
    {
      value: "NGN",
      text: "Nigerian naira"
    },
    {
      value: "NIO",
      text: "Nicaraguan córdoba"
    },
    {
      value: "NOK",
      text: "Norwegian krone"
    },
    {
      value: "NPR",
      text: "Nepalese rupee"
    },
    {
      value: "NZD",
      text: "New Zealand dollar"
    },
    {
      value: "OMR",
      text: "Omani rial"
    },
    {
      value: "PAB",
      text: "Panamanian balboa"
    },
    {
      value: "PEN",
      text: "Peruvian sol"
    },
    {
      value: "PGK",
      text: "Papua New Guinean kina"
    },
    {
      value: "PHP",
      text: "Philippine peso"
    },
    {
      value: "PKR",
      text: "Pakistani rupee"
    },
    {
      value: "PLN",
      text: "Polish złoty"
    },
    {
      value: "PRB",
      text: "Transnistrian ruble"
    },
    {
      value: "PYG",
      text: "Paraguayan guaraní"
    },
    {
      value: "QAR",
      text: "Qatari riyal"
    },
    {
      value: "RON",
      text: "Romanian leu"
    },
    {
      value: "RON",
      text: "Romanian leu"
    },
    {
      value: "RSD",
      text: "Serbian dinar"
    },
    {
      value: "RUB",
      text: "Russian ruble"
    },
    {
      value: "RWF",
      text: "Rwandan franc"
    },
    {
      value: "SAR",
      text: "Saudi riyal"
    },
    {
      value: "SEK",
      text: "Swedish krona"
    },
    {
      value: "SGD",
      text: "Singapore dollar"
    },
    {
      value: "SHP",
      text: "Saint Helena pound"
    },
    {
      value: "SLL",
      text: "Sierra Leonean leone"
    },
    {
      value: "SLS",
      text: "Somaliland shilling"
    },
    {
      value: "SOS",
      text: "Somali shilling"
    },
    {
      value: "SRD",
      text: "Surinamese dollar"
    },
    {
      value: "SSP",
      text: "South Sudanese pound"
    },
    {
      value: "STN",
      text: "São Tomé and Príncipe dobra"
    },
    {
      value: "SYP",
      text: "Syrian pound"
    },
    {
      value: "SZL",
      text: "Swazi lilangeni"
    },
    {
      value: "THB",
      text: "Thai baht"
    },
    {
      value: "TJS",
      text: "Tajikistani somoni"
    },
    {
      value: "TMT",
      text: "Turkmenistan manat"
    },
    {
      value: "TND",
      text: "Tunisian dinar"
    },
    {
      value: "TOP",
      text: "Tongan paʻanga"
    },
    {
      value: "TRY",
      text: "Turkish lira"
    },
    {
      value: "TTD",
      text: "Trinidad and Tobago dollar"
    },
    {
      value: "TVD",
      text: "Tuvaluan dollar"
    },
    {
      value: "TWD",
      text: "New Taiwan dollar"
    },
    {
      value: "TZS",
      text: "Tanzanian shilling"
    },
    {
      value: "UAH",
      text: "Ukrainian hryvnia"
    },
    {
      value: "UGX",
      text: "Ugandan shilling"
    },
    {
      value: "USD",
      text: "United States dollar"
    },
    {
      value: "UYU",
      text: "Uruguayan peso"
    },
    {
      value: "UZS",
      text: "Uzbekistani soʻm"
    },
    {
      value: "VES",
      text: "Venezuelan bolívar soberano"
    },
    {
      value: "VND",
      text: "Vietnamese đồng"
    },
    {
      value: "VUV",
      text: "Vanuatu vatu"
    },
    {
      value: "WST",
      text: "Samoan tālā"
    },
    {
      value: "XAF",
      text: "Central African CFA franc"
    },
    {
      value: "XCD",
      text: "Eastern Caribbean dollar"
    },
    {
      value: "XOF",
      text: "West African CFA franc"
    },
    {
      value: "XPF",
      text: "CFP franc"
    },
    {
      value: "ZAR",
      text: "South African rand"
    },
    {
      value: "ZMW",
      text: "Zambian kwacha"
    },
    {
      value: "ZWB",
      text: "Zimbabwean bonds"
    }
  ],
  currencyModes: [
    {
      value: "USD",
      text: "US dollar"
    },
    {
      value: "EUR",
      text: "Euro"
    },
    {
      value: "JPY",
      text: "Japanese yen"
    },
    {
      value: "GBP",
      text: "Pound sterling"
    },
    {
      value: "AED",
      text: "United Arab Emirates dirham"
    },
    {
      value: "AFN",
      text: "Afghan afghani"
    },
    {
      value: "ALL",
      text: "Albanian lek"
    },
    {
      value: "AMD",
      text: "Armenian dram"
    },
    {
      value: "ANG",
      text: "Netherlands Antillean guilder"
    },
    {
      value: "AOA",
      text: "Angolan kwanza"
    },
    {
      value: "ARS",
      text: "Argentine peso"
    },
    {
      value: "AUD",
      text: "Australian dollar"
    },
    {
      value: "AWG",
      text: "Aruban florin"
    },
    {
      value: "AZN",
      text: "Azerbaijani manat"
    },
    {
      value: "BAM",
      text: "Bosnia and Herzegovina convertible mark"
    },
    {
      value: "BBD",
      text: "Barbadian dollar"
    },
    {
      value: "BDT",
      text: "Bangladeshi taka"
    },
    {
      value: "BGN",
      text: "Bulgarian lev"
    },
    {
      value: "BHD",
      text: "Bahraini dinar"
    },
    {
      value: "BIF",
      text: "Burundian franc"
    },
    {
      value: "BMD",
      text: "Bermudian dollar"
    },
    {
      value: "BND",
      text: "Brunei dollar"
    },
    {
      value: "BOB",
      text: "Bolivian boliviano"
    },
    {
      value: "BRL",
      text: "Brazilian real"
    },
    {
      value: "BSD",
      text: "Bahamian dollar"
    },
    {
      value: "BTN",
      text: "Bhutanese ngultrum"
    },
    {
      value: "BWP",
      text: "Botswana pula"
    },
    {
      value: "BYN",
      text: "Belarusian ruble"
    },
    {
      value: "BZD",
      text: "Belize dollar"
    },
    {
      value: "CAD",
      text: "Canadian dollar"
    },
    {
      value: "CDF",
      text: "Congolese franc"
    },
    {
      value: "CHF",
      text: "Swiss franc"
    },
    {
      value: "CLP",
      text: "Chilean peso"
    },
    {
      value: "CNY",
      text: "Chinese yuan"
    },
    {
      value: "COP",
      text: "Colombian peso"
    },
    {
      value: "CRC",
      text: "Costa Rican colón"
    },
    {
      value: "CUC",
      text: "Cuban convertible peso"
    },
    {
      value: "CUP",
      text: "Cuban peso"
    },
    {
      value: "CVE",
      text: "Cape Verdean escudo"
    },
    {
      value: "CZK",
      text: "Czech koruna"
    },
    {
      value: "DJF",
      text: "Djiboutian franc"
    },
    {
      value: "DKK",
      text: "Danish krone"
    },
    {
      value: "DOP",
      text: "Dominican peso"
    },
    {
      value: "DZD",
      text: "Algerian dinar"
    },
    {
      value: "EGP",
      text: "Egyptian pound"
    },
    {
      value: "ERN",
      text: "Eritrean nakfa"
    },
    {
      value: "ETB",
      text: "Ethiopian birr"
    },
    {
      value: "EUR",
      text: "EURO"
    },
    {
      value: "FJD",
      text: "Fijian dollar"
    },
    {
      value: "FKP",
      text: "Falkland Islands pound"
    },
    {
      value: "GBP",
      text: "British pound"
    },
    {
      value: "GEL",
      text: "Georgian lari"
    },
    {
      value: "GGP",
      text: "Guernsey pound"
    },
    {
      value: "GHS",
      text: "Ghanaian cedi"
    },
    {
      value: "GIP",
      text: "Gibraltar pound"
    },
    {
      value: "GMD",
      text: "Gambian dalasi"
    },
    {
      value: "GNF",
      text: "Guinean franc"
    },
    {
      value: "GTQ",
      text: "Guatemalan quetzal"
    },
    {
      value: "GYD",
      text: "Guyanese dollar"
    },
    {
      value: "HKD",
      text: "Hong Kong dollar"
    },
    {
      value: "HNL",
      text: "Honduran lempira"
    },
    {
      value: "HKD",
      text: "Hong Kong dollar"
    },
    {
      value: "HRK",
      text: "Croatian kuna"
    },
    {
      value: "HTG",
      text: "Haitian gourde"
    },
    {
      value: "HUF",
      text: "Hungarian forint"
    },
    {
      value: "IDR",
      text: "Indonesian rupiah"
    },
    {
      value: "ILS",
      text: "Israeli new shekel"
    },
    {
      value: "IMP",
      text: "Manx pound"
    },
    {
      value: "INR",
      text: "Indian rupee"
    },
    {
      value: "IQD",
      text: "Iraqi dinar"
    },
    {
      value: "IRR",
      text: "Iranian rial"
    },
    {
      value: "ISK",
      text: "Icelandic króna"
    },
    {
      value: "JEP",
      text: "Jersey pound"
    },
    {
      value: "JMD",
      text: "Jamaican dollar"
    },
    {
      value: "JOD",
      text: "Jordanian dinar"
    },
    {
      value: "JPY",
      text: "Japanese yen"
    },
    {
      value: "KES",
      text: "Kenyan shilling"
    },
    {
      value: "KGS",
      text: "Kyrgyzstani som"
    },
    {
      value: "KHR",
      text: "Cambodian riel"
    },
    {
      value: "KID",
      text: "Kiribati dollar"
    },
    {
      value: "KMF",
      text: "Comorian franc"
    },
    {
      value: "KPW",
      text: "North Korean won"
    },
    {
      value: "KRW",
      text: "South Korean won"
    },
    {
      value: "KWD",
      text: "Kuwaiti dinar"
    },
    {
      value: "KYD",
      text: "Cayman Islands dollar"
    },
    {
      value: "KZT",
      text: "Kazakhstani tenge"
    },
    {
      value: "LAK",
      text: "Lao kip"
    },
    {
      value: "LBP",
      text: "Lebanese pound"
    },
    {
      value: "LKR",
      text: "Sri Lankan rupee"
    },
    {
      value: "LRD",
      text: "Liberian dollar"
    },
    {
      value: "LSL",
      text: "Lesotho loti"
    },
    {
      value: "LYD",
      text: "Libyan dinar"
    },
    {
      value: "MAD",
      text: "Moroccan dirham"
    },
    {
      value: "MDL",
      text: "Moldovan leu"
    },
    {
      value: "MGA",
      text: "Malagasy ariary"
    },
    {
      value: "MKD",
      text: "Macedonian denar"
    },
    {
      value: "MMK",
      text: "Burmese kyat"
    },
    {
      value: "MNT",
      text: "Mongolian tögrög"
    },
    {
      value: "MOP",
      text: "Macanese pataca"
    },
    {
      value: "MRU",
      text: "Mauritanian ouguiya"
    },
    {
      value: "MUR",
      text: "Mauritian rupee"
    },
    {
      value: "MVR",
      text: "Maldivian rufiyaa"
    },
    {
      value: "MWK",
      text: "Malawian kwacha"
    },
    {
      value: "MXN",
      text: "Mexican peso"
    },
    {
      value: "MYR",
      text: "Malaysian ringgit"
    },
    {
      value: "MZN",
      text: "Mozambican metical"
    },
    {
      value: "NAD",
      text: "Namibian dollar"
    },
    {
      value: "NGN",
      text: "Nigerian naira"
    },
    {
      value: "NIO",
      text: "Nicaraguan córdoba"
    },
    {
      value: "NOK",
      text: "Norwegian krone"
    },
    {
      value: "NPR",
      text: "Nepalese rupee"
    },
    {
      value: "NZD",
      text: "New Zealand dollar"
    },
    {
      value: "OMR",
      text: "Omani rial"
    },
    {
      value: "PAB",
      text: "Panamanian balboa"
    },
    {
      value: "PEN",
      text: "Peruvian sol"
    },
    {
      value: "PGK",
      text: "Papua New Guinean kina"
    },
    {
      value: "PHP",
      text: "Philippine peso"
    },
    {
      value: "PKR",
      text: "Pakistani rupee"
    },
    {
      value: "PLN",
      text: "Polish złoty"
    },
    {
      value: "PRB",
      text: "Transnistrian ruble"
    },
    {
      value: "PYG",
      text: "Paraguayan guaraní"
    },
    {
      value: "QAR",
      text: "Qatari riyal"
    },
    {
      value: "RON",
      text: "Romanian leu"
    },
    {
      value: "RON",
      text: "Romanian leu"
    },
    {
      value: "RSD",
      text: "Serbian dinar"
    },
    {
      value: "RUB",
      text: "Russian ruble"
    },
    {
      value: "RWF",
      text: "Rwandan franc"
    },
    {
      value: "SAR",
      text: "Saudi riyal"
    },
    {
      value: "SEK",
      text: "Swedish krona"
    },
    {
      value: "SGD",
      text: "Singapore dollar"
    },
    {
      value: "SHP",
      text: "Saint Helena pound"
    },
    {
      value: "SLL",
      text: "Sierra Leonean leone"
    },
    {
      value: "SLS",
      text: "Somaliland shilling"
    },
    {
      value: "SOS",
      text: "Somali shilling"
    },
    {
      value: "SRD",
      text: "Surinamese dollar"
    },
    {
      value: "SSP",
      text: "South Sudanese pound"
    },
    {
      value: "STN",
      text: "São Tomé and Príncipe dobra"
    },
    {
      value: "SYP",
      text: "Syrian pound"
    },
    {
      value: "SZL",
      text: "Swazi lilangeni"
    },
    {
      value: "THB",
      text: "Thai baht"
    },
    {
      value: "TJS",
      text: "Tajikistani somoni"
    },
    {
      value: "TMT",
      text: "Turkmenistan manat"
    },
    {
      value: "TND",
      text: "Tunisian dinar"
    },
    {
      value: "TOP",
      text: "Tongan paʻanga"
    },
    {
      value: "TRY",
      text: "Turkish lira"
    },
    {
      value: "TTD",
      text: "Trinidad and Tobago dollar"
    },
    {
      value: "TVD",
      text: "Tuvaluan dollar"
    },
    {
      value: "TWD",
      text: "New Taiwan dollar"
    },
    {
      value: "TZS",
      text: "Tanzanian shilling"
    },
    {
      value: "UAH",
      text: "Ukrainian hryvnia"
    },
    {
      value: "UGX",
      text: "Ugandan shilling"
    },
    {
      value: "USD",
      text: "United States dollar"
    },
    {
      value: "UYU",
      text: "Uruguayan peso"
    },
    {
      value: "UZS",
      text: "Uzbekistani soʻm"
    },
    {
      value: "VES",
      text: "Venezuelan bolívar soberano"
    },
    {
      value: "VND",
      text: "Vietnamese đồng"
    },
    {
      value: "VUV",
      text: "Vanuatu vatu"
    },
    {
      value: "WST",
      text: "Samoan tālā"
    },
    {
      value: "XAF",
      text: "Central African CFA franc"
    },
    {
      value: "XCD",
      text: "Eastern Caribbean dollar"
    },
    {
      value: "XOF",
      text: "West African CFA franc"
    },
    {
      value: "XPF",
      text: "CFP franc"
    },
    {
      value: "ZAR",
      text: "South African rand"
    },
    {
      value: "ZMW",
      text: "Zambian kwacha"
    },
    {
      value: "ZWB",
      text: "Zimbabwean bonds"
    },
    {
      value: "Other",
      text: "Other"
    }
  ],
  timeZones: [
    {
      value: '-12:00',
      text: "(GMT -12:00) Eniwetok, Kwajalein"
    },
    {
      value: '+14:00',
      text: '(GMT +14:00) Line Islands, Tokelau'
    },
    {
      value: '+13:00',
      text: '(GMT +13:00) Apia, Nukualofa'
    },
    {
      value: '+12:45',
      text: '(GMT +12:45) Chatham Islands'
    },
    {
      value: '+12:00',
      text: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka'
    },
    {
      value: '+11:30',
      text: '(GMT +11:30) Norfolk Island'
    },
    {
      value: '+11:00',
      text: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia'
    },
    {
      value: '+10:30',
      text: '(GMT +10:30) Lord Howe Island'
    },
    {
      value: '+10:00',
      text: '(GMT +10:00) Eastern Australia, Guam, Vladivostok'
    },
    {
      value: '+09:30',
      text: '(GMT +9:30) Adelaide, Darwin'
    },
    {
      value: '+09:00',
      text: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk'
    },
    {
      value: '+08:45',
      text: '(GMT +8:45) Eucla'
    },
    {
      value: '+08:00',
      text: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong'
    },
    {
      value: '+07:00',
      text: '(GMT +7:00) Bangkok, Hanoi, Jakarta'
    },
    {
      value: '+06:30',
      text: '(GMT +6:30) Yangon, Mandalay'
    },
    {
      value: '+06:00',
      text: '(GMT +6:00) Almaty, Dhaka, Colombo'
    },
    {
      value: '+05:45',
      text: '(GMT +5:45) Kathmandu, Pokhara'
    },
    {
      value: '+05:30',
      text: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi'
    },
    {
      value: '+05:00',
      text: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent'
    },
    {
      value: '+04:30',
      text: '(GMT +4:30) Kabul'
    },
    {
      value: '+04:00',
      text: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi'
    },
    {
      value: '+03:30',
      text: '(GMT +3:30) Tehran'
    },
    {
      value: '+03:00',
      text: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg'
    },
    {
      value: '+02:00',
      text: '(GMT +2:00) Kaliningrad, South Africa'
    },
    {
      value: '+01:00',
      text: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris'
    },
    {
      value: '+00:00',
      text: '(GMT) Western Europe Time, London, Lisbon, Casablanca'
    },
    {
      value: '-01:00',
      text: '(GMT -1:00) Azores, Cape Verde Islands'
    },
    {
      value: '-02:00',
      text: '(GMT -2:00) Mid-Atlantic'
    },
    {
      value: '-03:00',
      text: '(GMT -3:00) Brazil, Buenos Aires, Georgetown'
    },
    {
      value: '-03:30',
      text: '(GMT -3:30) Newfoundland'
    },
    {
      value: '-04:00',
      text: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz'
    },
    {
      value: '-04:30',
      text: '(GMT -4:30) Caracas'
    },
    {
      value: '-05:00',
      text: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima'
    },
    {
      value: '-06:00',
      text: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City'
    },
    {
      value: '-07:00',
      text: '(GMT -7:00) Mountain Time (US &amp; Canada)'
    },
    {
      value: '-08:00',
      text: '(GMT -8:00) Pacific Time (US &amp; Canada)'
    },
    {
      value: '-09:00',
      text: '(GMT -9:00) Alaska'
    },
    {
      value: '-09:30',
      text: '(GMT -9:30) Taiohae'
    },
    {
      value: '-10:00',
      text: '(GMT -10:00) Hawaii'
    },
    {
      value: '-11:00',
      text: '(GMT -11:00) Midway Island, Samoa'
    }
  ],
  taxPeriods: [
    '1 Month',
    '3 Months',
    '6 Months',
    '9 Months',
    'Annual'
  ],
  methods: [
    'Accrual Basis',
    'Cash Basis'
  ],
}
