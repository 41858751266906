
import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from '../../router'
const namespaced = true;
const plugins = [createPersistedState()];
const state = {

};
const mutations = {

};
const actions = {
  ViewEmployeeByGroup({ getters, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ViewEmployeeByGroup",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          limit: payload.limit,
          pageNum:payload.pageNum
        },
        payload.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getEmployee({ state, getters, commit, rootState, rootGetters }, employeeID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Employee/ViewEmployee",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          employeeID: employeeID
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  DeactivateMultipleEmployees(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/DeActivateMultipleEmployee",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { employeeIDs: formData.employeeIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // activate multiple employees
  ActivateMultipleEmployees(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ActivateMultipleEmployee",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { employeeIDs: formData.employeeIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  DeactivateMultiplePayRollItems(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Payroll/DeactivateMultiplePayrollItem",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { payrollIDs: formData.payrollIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // activate multiple payroll items
  ActivateMultiplePayRollItems(
    { state, getters, commit, rootState, rootGetters },
    formData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Payroll/ActivateMultiplePayrollItem",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        { payrollIDs: formData.payrollIDs },
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importEmployee({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/form-data',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ImportEmployee",
        { businessId: selectedBusiness.businessId, branchId: selectedBusiness.branchId,fileType:data.fileType},
        data.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  ExportEmployee({state, getters, commit, rootState, rootGetters}, fileType){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ExportEmployee",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: fileType, 
          employeeID:'' },
          {},
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  ExportSelectedEmployee({state, getters, commit, rootState, rootGetters}, formData){
    console.log('hello',formData)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ExportSelectedEmployee",
        { 
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
      }, 
        {
          columns: formData.columns,
          mailingAddress: formData.MailingAddress,
          parmanentAddress: formData.ParmanentAddress,
          officeNo: formData.OfficePhone,
          personalNo: formData.PersonalPhone,
          vendorID:  formData.employeeid
        },
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  importPayslip({rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/form-data',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Payslip/ImportPayslip",
        { businessId: selectedBusiness.businessId, branchId: selectedBusiness.branchId ,fileType:data.fileType},
        data.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  exportPayroll({rootGetters}, fileType){
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "",
        { 
          businessId: selectedBusiness.businessId,
           branchId: selectedBusiness.branchId,fileType: 
           fileType, 
           ID:'' },
        options
      )
      .then(res => {
        // console.log("response is this:",res)
        return res
        // commit("vendorExportFile", res.data)
      })
      .catch(err => {
        // console.log("error is:",err)
        return err;
      });
  },
  addEmployee({ state, getters, commit, rootState, rootGetters }, formdata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/form-data',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/AddEmployee",
        { businessId: selectedBusiness.businessId, 
          branchId: selectedBusiness.branchId
        },
        formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  editEmployee({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'multipart/form-data',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Employee/UpdateEmployee",
        { businessId: selectedBusiness.businessId,
          employeeId:data.empoloyeeID, 
          branchId: selectedBusiness.branchId,
        },
        data.formdata,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  updateEmployeeByGroup({ rootGetters }, data) {
    console.warn("data",data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json-patch+json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PATCH",
        "/Employee/UpdateEmployeeByGroup",
        {
          businessID: selectedBusiness.businessId,
          groupID: data.groupID,
          branchID: selectedBusiness.branchId,
        },
        data.data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  activateEmployee({ rootGetters }, employeeID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/ActivateEmployee",
        { businessId: selectedBusiness.businessId, employeeID: employeeID,branchId:selectedBusiness.branchId },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  deleteEmployee({ state, getters, commit, rootState, rootGetters }, employeeID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Employee/DeActivateEmployee",
        { businessId: selectedBusiness.businessId, employeeID: employeeID,branchId:selectedBusiness.branchId },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  dropdownEmployees({ rootGetters }, employeeID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Employee/ShowEmployeesForDropDown",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  // Payroll Actions
  addPayrollItem({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    let payItemDetail=JSON.stringify(payload);
    return request
      .make(
        "POST",
        "/Payroll/AddPayrollItem",
        { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId },
        payItemDetail,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  ShowPayrollItemsForDropDown({  rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Payroll/ShowPayrollItemsForDropDown",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          keyword: data.keyword,
          offset: data.offset,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getPayrollItem({ state, getters, commit, rootState, rootGetters }, payrollID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Payroll/ViewPayrollItems",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          itemName:payrollID
        },
        payrollID,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  deactivePayroll({ state, getters, commit, rootState, rootGetters }, payrollDetails) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Payroll/DeactivatePayrollItem",
        { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,itemName:payrollDetails },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  activePayroll({ state, getters, commit, rootState, rootGetters }, payrollDetails) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Payroll/ActivatePayrollItem",
        { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,itemName:payrollDetails },
        payrollDetails,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  editPayrollItem({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        'Content-Type':'application/json',
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    let payItemDetail=JSON.stringify(data.formdata);
    return request
      .make(
        "PUT",
        "/Payroll/UpdatePayrollItem",
        { businessId: selectedBusiness.businessId,branchId:selectedBusiness.branchId,itemName:data.itemName},
        payItemDetail,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
  },
  
  
 // AddPaySlip
 AddPaySlip({ state, getters, commit, rootState, rootGetters }, data) {
  //  let fromdata= JSON.stringify(data)
  const options = {
   headers: {
   Authorization: "Bearer " + rootGetters["auth/loginToken"],
   'Content-Type':'application/json',
   }
   };
   let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
   return request
   .make(
   "POST",
   "/PaySlip/AddPaySlip",
   { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId,flag:data.flag},
   data,
   options
   )
   .then(res => {
   return res;
   })
   .catch(err => {
   return err;
   });
   },
   searchPayslipForSchedule({  rootGetters }, searchData) {
    console.log("Searchdata",searchData)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/PaySlip/ViewPaySlipForSchedule", 
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        month: searchData.month,
        year:searchData.year,
        frequency:searchData.frequency?searchData.frequency:undefined,
        employeeLists:searchData.employeeLists?searchData.employeeLists:undefined,
        limit:searchData.limit,
        pageNum:searchData.pageNum
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
   },
   GeneratePaySlipSchedule({  rootGetters }, data) {
    const options = {
     headers: {
     Authorization: "Bearer " + rootGetters["auth/loginToken"],
     'Content-Type':'application/json-patch+json',
     }
     };
     let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
     return request
     .make(
     "POST",
     "/PaySlip/GeneratePaySlipSchedule",
     { businessID: selectedBusiness.businessId, branchID: selectedBusiness.branchId},
     data,
     options
     )
     .then(res => {
     return res;
     })
     .catch(err => {
     return err;
     });
   },
  //searchPayslip
   
  searchPayslip({  rootGetters }, searchData) {
    console.log("Searchdata",searchData)
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/PaySlip/SearchPaySlip",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        invoiceNum:searchData.invoice,
        employeeID: searchData.employeeID,
        payFromDate:searchData.payFromDate,
        payToDate:searchData.payToDate,
        limit:searchData.limit,
        pageNum:searchData.pageNum,
        amount:searchData.amount,
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
//viewPayslip
viewPayslip({ getters, rootGetters }, slipID) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/PaySlip/ViewPaySlip",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        slipID: slipID
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
ViewPaySlipByInvoice({ rootGetters }, payload) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "GET",
      "/PaySlip/ViewPaySlipByInvoice",
      { businessID: selectedBusiness.businessId, 
        branchID:payload.branchID?payload.branchID:selectedBusiness.branchId,
        // branchID: selectedBusiness.branchId,
        invoiceNum:payload.invoiceNo,
        invoiceType:payload.invoiceType
      },
      null,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
},
//updatePaySlip
updatePaySlip({ getters, rootGetters }, formdata) {
  const options = {
    headers: {
      Authorization: "Bearer " + rootGetters["auth/loginToken"]
    }
  };
  let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  return request
    .make(
      "PUT",
      "PaySlip/UpdatePaySlip",
      {
        businessID: selectedBusiness.businessId,
        branchID: selectedBusiness.branchId,
        slipID: formdata.slipID,
        // flag:formdata.data.flag
      },
      formdata.data,
      options
    )
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
    
},
  // DeactivatePayslip
  DeactivatePayslip({ getters, rootGetters }, slipID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "PaySlip/DeactivatePaySlip",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          slipID: slipID
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
     },
  // ActivatePayslip
  ActivatePayslip({ getters, rootGetters }, slipID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"]
      }
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "PaySlip/ActivatePaySlip",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          slipID: slipID
        },
        null,
        options
      )
      .then(res => {
        return res;
      })
      .catch(err => {
        return err;
      });
     },
     ExportPaySlip({rootGetters}, payload){
      let psID
      if(payload.paySlipID) psID={id:payload.paySlipID}
      else psID={}
      const options = {
        headers: {
          Authorization: "Bearer " + rootGetters["auth/loginToken"]
        }
      };
      let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
      return request
        .make(
          "POST",
          "/Payslip/ExportPaySlip",
          { 
            businessID: selectedBusiness.businessId,
            branchID: selectedBusiness.branchId,
            fileType: payload.fileType, 
            // paySlipID: payload.paySlipID,
            fromDate:payload.fromDate,
            toDate:payload.toDate
            },
            // {paySlipID: payload.paySlipID},
            psID,
          options
        )
        .then(res => {
          // console.log("response is this:",res)
          return res
          // commit("vendorExportFile", res.data)
        })
        .catch(err => {
          // console.log("error is:",err)
          return err;
        });
     }

};
const getters = {

};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state
};
import store from '../index'
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers
      })
        .then(response => {
          return new Promise(function(resolve, reject) {
            resolve(response);
          });
        })
        .catch(error => {
          if (event.srcElement.status === 401) {
            store.commit('auth/setLoginExpired',true)
          }else if(event.srcElement.status ===403){
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).response[0].message)
            router.push({name:'accessDenied'})
          } 
          else if(event.srcElement.status ===500){
            console.log("Error 500",JSON.parse( event.srcElement.response).message)
            store.commit('auth/setErrorMessage',JSON.parse( event.srcElement.response).message)
          }else {
            return new Promise(function(resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function(resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed."
          }
        };
        resolve({
          error: true,
          message: "server request Failed."
        });
        reject(error);
      });
    }
  }
  /* ----------------- ---------- -------- -------------------- */
};
