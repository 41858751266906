<template>
  <div>
    <v-card>
      <v-card-title
        >Add New Employee
        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="closeModal(), (loading = false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12" xl="12">
            <v-text-field
              label="Employee Name *"
              type="text"
              :error-messages="EmployeeFullNameErrors"
              v-model.trim="$v.form.EmployeeFullName.$model"
              :counter="counterOne"
              filled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" lg="12" sm="12" md="12" xl="12">
            <v-text-field
              label="NIC/SSN *"
              type="text"
              :error-messages="EmployeeSSNErrors"
              v-model.trim="$v.form.EmployeeSSN.$model"
              :state="!$v.form.EmployeeSSN.$error"
              filled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" :loading="loading" text @click="addEmployee"
          ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
        >
        <v-btn color="error" text @click="closeModal(), (loading = false)"
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import errorMessages from "@/assets/js/errorMessages";
import mainValidations from "../../assets/js/validations";
import config from "../../config";
import store from "@/store";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  // props:['closeModal','isSalesman'],
  props: {
    closeModal: {
      default: null,
    },
    isSalesman: {
      default: false,
    },
    showSnack: {
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      counterOne: mainValidations.length1,
      resErrors: [],
      form: {
        EmployeeFullName: "",
        EmployeeSSN: "",
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      EmployeeFullName: {
        required,
        checkNameLength: (val) => {
          return mainValidations.nameLength(val);
        },
        // employeeNameExists: (val) => {
        //   axios.defaults.baseURL = config.baseURL;
        //   let selectedBusiness = store.getters["setupItem/getSelectedBusiness"];
        //   let token = store.getters["auth/loginToken"];
        //   let headers = {
        //     Authorization: "Bearer " + token,
        //   };
        //   if (val == "") {
        //     return true;
        //   } else {
        //     return axios({
        //       method: "GET",
        //       url: "/Employee/DoesEmployeeNameExists",
        //       params: {
        //         businessID: selectedBusiness.businessId,
        //         branchID: selectedBusiness.branchId,
        //         employeeName: val,
        //       },
        //       data: null,
        //       headers: headers,
        //     })
        //       .then((res) => {
        //         if (res.data.success == "OK") {
        //           return false;
        //         } else if (res.data.success == "Failed") {
        //           return true;
        //         }
        //       })
        //       .catch((err) => {
        //         console.log(err);
        //         return true;
        //       });
        //   }
        // },
      },
      EmployeeSSN: {
        required,
        EmployeeSSNExists: (val) => {
          if (val == "") {
            return true;
          } else {
            return true;
          }
        },
      },
    },
  },
  computed: {
    EmployeeFullNameErrors() {
      const errors = [];
      if (!this.$v.form.EmployeeFullName.$dirty) return errors;
      !this.$v.form.EmployeeFullName.required &&
        errors.push(errorMessages.requiredMessages("Employee Name"));
      // !this.$v.form.EmployeeFullName.employeeNameExists &&
      //   errors.push(errorMessages.alreadyExistsMessages("Employee Name"));
      !this.$v.form.EmployeeFullName.checkNameLength &&
        errors.push(
          errorMessages.lengthMessages("Employee Name", mainValidations.length1)
        );
      console.log("errors", errors);
      return errors;
      // if (!this.$v.form.EmployeeFullName.$dirty) return errors
      // !this.$v.form.EmployeeFullName.required && errors.push('Employee name is required')
      // !this.$v.form.EmployeeFullName.alphaspace && errors.push('Employee name must contain alphabets only')
      // return errors
    },
    EmployeeSSNErrors() {
      const errors = [];
      if (!this.$v.form.EmployeeSSN.$dirty) return errors;
      !this.$v.form.EmployeeSSN.EmployeeSSNExists &&
        errors.push("SSN/NIC already exists");
      !this.$v.form.EmployeeSSN.required &&
        errors.push("This field is required");
      return errors;
    },
  },
  methods: {
    addEmployee() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.resErrors = [];
        this.loading = true;
        const formdata = new FormData();
        formdata.append("EmployeeFullName", this.form.EmployeeFullName);
        formdata.append("EmployeeSSN", this.form.EmployeeSSN);
        formdata.append("IsSalesman", this.isSalesman);
        this.$store
          .dispatch("payroll/addEmployee", formdata)
          .then((res) => {
            console.log("Employee added", res);
            if (res.data.success == "OK") {
              this.loading = false;
              this.$emit("addedEmployee", this.form.EmployeeFullName);
              this.closeModal();
              (this.form.EmployeeFullName = ""), (this.form.EmployeeSSN = "");
              this.$v.$reset();
              this.showSnack(res.data.response[0].message, "success");
            } else {
              this.showSnack(res.data.response[0].message, "error");
              this.loading = false;
            }
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
