<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="itemOptions"
      :search-input.sync="keyword"
      no-data-text="No Items Available"
      item-text="name"
      item-value="fixedAssetID"
      clearable
      dense
      hide-selected
      @click:clear="clearData"
      :return-object="true"
      :label="fixedAssetLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @change="valueChanged($event)"
    >
      <template v-slot:append>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          :width="3"
          size="24"
          indeterminate
        ></v-progress-circular>
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
export default {
  props: {
    isMultiple: {
      default: false,
    },
    operation: {
      default: "add",
    },
    setProduct: {
      default: null,
    },
    index: {
      default: null,
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: null,
    },
    fixedAssetLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: true,
    },
    updateItem: {
      default: null,
    },
  },
  data() {
    return {
      userRights: null,
      isAllowedToViewItems: false,
      itemName: null,
      itemOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateItem,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      count: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   itemOptions: [],
    };
  },

  async created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 17 && val.displayPermission) {
        this.isAllowedToViewItems = true;
        this.checkItemsForSale();
      }
    });
    // this.checkItemsForSale()
    // alert(this.$store.getters['dropdowns/getProducts'](this.updateItemData))
  },
  methods: {
    viewMainItems() {
      //when updateItem is cleared from dropdown get all prodycts without sending request
      this.itemOptions = [];
      this.itemOptions = [
        ...this.$store.getters["dropdowns/getFAItemsForSale"],
      ];
      this.totalData = this.$store.getters["dropdowns/getFAItemsCountsForSale"];
    },
    checkItemsForSale() {
      if (this.updateItem && this.operation != "add")
        this.itemOptions.push(this.updateItem);
      else {
        this.itemOptions = [
          ...this.$store.getters["dropdowns/getFAItemsForSale"],
        ];
        this.totalData =
          this.$store.getters["dropdowns/getFAItemsCountsForSale"];
      }
    },
    closeModal() {
      this.productForm = false;
    },
    async clearData() {
      this.count = -1;

      this.itemOptions = [];
      this.viewMainItems();
      this.model = "";
      this.itemName = null;
      this.$emit("fixedAssetSelected", null);
    },
    valueChanged(e) {
      // console.log("this.model.itemName",e)
      if (!e) {
        this.itemName = null;
        this.$emit("fixedAssetSelected", null);
        return;
      }
      this.itemName = e.name;
      if (this.returnObject == false) {
        this.$emit("fixedAssetSelected", e.fixedAssetID);
      } else {
        this.$emit("fixedAssetSelected", e);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllItems();
    },
    sendNewRequest() {
      if (this.isLoading == true) {
        return;
      }
      this.itemOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllItems();
    },
    getAllItems() {
      if (this.updateItem != null && this.updateItem != "") {
        let updateItem = this.updateItem;
        this.itemOptions.push(updateItem);
      }
      let sendingKeyword = this.keyword;
      this.productForm = false;
      if (this.isAllowedToViewItems) {
        this.$store
          .dispatch("dropdowns/ShowFixedAssetForFASale", {
            keyword: this.keyword,
            offset: this.offset,
          })
          .then((res) => {
            if (res.data.success == "OK") {
              console.log("PRODUCTS FOR DROPDOWN", res);
              let oldData = this.itemOptions;
              let resData = res.data.data;
              console.log("res.data.data", res.data.data);
              this.itemOptions = [...oldData, ...resData];
              this.totalData = res.data.offsetAndTotalCount.totalCount;
            }
          })
          .finally(() => {
            if (sendingKeyword != this.keyword) {
              this.itemOptions = [];
              this.offset = 0;
              (this.isLoading = true), this.getAllItems();
            } else {
              this.isLoading = false;
            }
          });
      }
    },
  },
  watch: {
    immediate: false,
    keyword(val) {
      this.count += 1;
      if (this.updateItem) {
        if (val && val == this.updateItem.name) {
          return;
        } //prevent searching selected product
      }
      if (val == this.itemName && this.itemName != null) {
        //dont search selected product again
        return;
      }
      if (!val && this.count < 2) {
        //prevent first empty search
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updateItem: function (val) {
      if (val) {
        this.itemOptions.push(val);
        this.itemName = val.name;
      } else this.itemName = val;
      this.count = 0;
      this.model = val;
    },
    immediate: true,
  },
};
</script>

<style lang="scss" scoped></style>
