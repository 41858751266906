<template>
  <div>
    <v-autocomplete
      v-model="model"
      :items="customerOptions"
      :search-input.sync="keyword"
      no-data-text="No Customer Available"
      item-text="customerName"
      item-value="customerID"
      clearable
      dense
      ref="select"
      hide-selected
      :return-object="true"
      :label="customerLabel"
      :disabled="isDisabled"
      :outlined="isOutlined"
      :filled="isfilled"
      :multiple="isMultiple"
      :error-messages="errorMessages"
      :error="anyError"
      :hide-details="hideDetails"
      @click:clear="clearData"
      @change="valueChanged($event)"
    >
      <template v-slot:append v-if="showIcon">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              @click="CustomerForm = true"
              small
              style="margin-top: -2px !important"
              v-bind="attrs"
              v-on="on"
            >
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
          <span>Add Customer</span>
        </v-tooltip>
       
      </template>
      <template v-slot:selection="{ item, index }" v-if="isMultiple">
        <v-chip v-if="index === 0" small>
          <span>{{ item.customerName }}</span>
        </v-chip>
        <span v-if="index === 1" class="grey--text text-caption"
          >(+{{ model.length - 1 }} others)</span
        >
      </template>
      <template v-slot:append-item v-if="(offset + 1) * 200 < totalData">
        <v-divider class="mt-1"></v-divider>
        <v-list-item ripple @click="getMoreData">
          <v-list-item-action>
            <v-icon>mdi-autorenew</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Load More</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-autocomplete>
    <v-dialog v-model="CustomerForm" max-width="450px" persistent>
      <add-new-customer
        @addedCustomer="keyword = $event"
        :showSnack="showSnack"
        :closeModal="closeModal"
      />
    </v-dialog>
    <v-snackbar :color="color" :timeout="timeout" v-model="snackbar" transition="scale-transition" origin="center center">
      {{ message }}
      <template v-slot:action="{ attrs }">
      <v-btn icon @click="snackbar = false" v-bind="attrs"> <v-icon>mdi-close</v-icon> </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import addNewCustomer from "../miniComponent/addCustomer";
import centralizedValues from "../../assets/js/centralizedValues";
export default {
  components: {
    addNewCustomer,
  },
  props: {
    showIcon: {
      default: true,
    },
    isMultiple: {
      default: false,
    },
    clickEvent: {
      default: "",
    },
    anyError: {
      default: false,
    },
    errorMessages: {
      default: [],
    },
    customerLabel: {
      default: "",
    },
    isfilled: {
      default: false,
    },
    isOutlined: {
      default: true,
    },
    isDisabled: {
      default: false,
    },
    hideDetails: {
      default: true,
    },
    returnObject: {
      default: false,
    },
    updateCustomer: {
      default: null,
    },
  },
  data() {
    return {
      snackbar: false,
      message: "",
      color: "success",
      timeout: centralizedValues.timeout,
      userRights: null,
      isAllowedToViewCustomer: false,
      customerName: null,
      CustomerForm: false,
      customerOptions: [],
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      model: this.updateCustomer,
      search: null,
      keyword: "",
      offset: 0,
      totalData: 0,
      //end
      //   searchInput: "",
      //   items: 100,
      //   product: "",
      //   customerOptions: [],
    };
  },
  created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 4 && val.displayPermission) {
        this.isAllowedToViewCustomer = true;
      }
    });
  },
  methods: {
    showSnack(msg, color) {
      console.warn("Snack called");
      this.message = msg;
      this.color = color;
      this.snackbar = true;
    },
    closeModal() {
      this.CustomerForm = false;
    },
    clearData() {
      this.model = "";
      this.$emit("customerSelected", null);
    },
    valueChanged(e) {
      this.customerName = this.model.customerName;
      if (this.returnObject == false) {
        if (this.isMultiple == true) {
          let data = [];
          this.model.forEach((val) => {
            data.push(val.customerID);
          });
          this.$emit("customerSelected", data);
        }
        // this.$emit("customerSelected", this.model.customerID);
      } else {
        this.$emit("customerSelected", this.model);
      }
    },
    getMoreData() {
      this.isLoading = true;
      this.offset += 1;
      this.getAllCustomers();
    },
    sendNewRequest() {
      this.$refs.select.menuIsActive = false;
      if (this.isLoading == true) {
        return;
      }
      this.customerOptions = [];
      this.offset = 0;
      (this.isLoading = true), this.getAllCustomers();
    },
    getAllCustomers() {
      if (this.updateCustomer != null && this.updateCustomer != "") {
        let updateCustomer = this.updateCustomer;
        this.customerOptions.push(updateCustomer);
      }
      let sendingKeyword = this.keyword;
      if (this.isAllowedToViewCustomer) {
        this.$store
          .dispatch("setupItem/dropdownCustomers", {
            keyword: this.keyword,
            offset: this.offset,
          })
          .then((res) => {
            if (res.data.success == "OK") {
              let oldData = this.customerOptions;
              let resData = res.data.data;
              this.customerOptions = [...oldData, ...resData];
              this.totalData = res.data.offsetAndTotalCount.totalCount;
              // this.isAllowedToViewCustomer=false
            }
          })
          .finally(() => {
            if (sendingKeyword != this.keyword) {
              this.$refs.select.menuIsActive = false;
              this.customerOptions = [];
              this.offset = 0;
              (this.isLoading = true), this.getAllCustomers();
            } else {
              this.isLoading = false;
              this.$refs.select.menuIsActive = false;
            }
          });
      }
    },
  },
  watch: {
    keyword(val) {
      if (val == this.customerName && this.customerName != null) {
        return;
      }
      setTimeout(() => {
        this.sendNewRequest();
      }, 500);

      return;
    },
    updateCustomer: function (val) {
      this.model = val;
    },
  },
};
</script>

<style lang="scss" scoped></style>
