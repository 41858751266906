<template>
  <div>
   
    <v-navigation-drawer
    class="d-print-none"
      v-model="getDrawer"
      app
      color="drawerbgcolor"
      dark
    >
    <!-- style="background-image: linear-gradient(195deg, #42424a, #0b0f1f) !important;" -->
      <template v-slot:prepend>
        <div class="d-flex justify-center mt-0 mb-0">
          <div>
            <img src="../../assets/images/logo/rsheenlogoLight.svg" width="153" />
          </div>
        </div>
      </template>
      
      <v-divider></v-divider>
      <span v-if="hideDetails">{{ userDetails() }}</span>
      <v-list-item class="px-4">
        <v-list-item-avatar v-if="logo != null">
          <img :src="logo" />
        </v-list-item-avatar>
        <v-avatar v-else color="white" size="40" class="mr-6">
          <span class="primary--text title font-weight-medium">{{
            avatar
          }}</span>
        </v-avatar>
        <v-list-item-content>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-list-item-title
                v-on="on"
                class="subtitle-1 font-weight-medium"
                >{{ businessName }}</v-list-item-title
              >
            </template>
            <span>{{ businessName }}</span>
          </v-tooltip>
          <v-list-item-subtitle>{{ branchName }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list nav>
        <v-list-item link :to="{ name: 'dashboard' }" class="mb-1" color="primary">
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <!-- 1st -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-clipboard-check-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Setup Items</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- vendor -->
          <v-list-item
            :key="$route.fullPath"
            :to="{ name: 'vendor' }"
            link
            class="mb-1"
          >
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Vendors</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- customer -->
          <v-list-item :to="{ name: 'customer' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Customers</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- branches -->
          <v-list-item :to="{ name: 'branches' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Branches</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Product -->
          <v-list-item :to="{ name: 'products' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item :to="{ name: 'categories' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Categories</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!--  -->
        </v-list-group>
        <!-- 2nd -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-book-account-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Accounts</v-list-item-title>
            </v-list-item-content>
          </template>

          <!-- Purchase Quotation -->
          <!-- <v-list-item :to="{ name: 'purchasequotation' }" link>
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Purchase Quote</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <!-- Purchase Quotation -->
          <!-- Manage Bank -->
          <v-list-item :to="{ name: 'managebank' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Bank</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- -->
          <!-- Purchase Order -->
          <v-list-item :to="{ name: 'purchaseorder' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Purchase Order</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Purchase Quotation -->
          <!-- Purchase Bill -->
          <v-list-item :to="{ name: 'purchasebill' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Purchase Bill</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Purchase Bill -->

          <!-- Sale Quotation-->
          <v-list-item :to="{ name: 'salequotation' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sales Quote</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Sale Invoice-->
          <!-- Sale Invoice-->
          <v-list-item :to="{ name: 'sale' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Sales Invoice</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Sale Invoice-->
        </v-list-group>
        <!-- 3nd -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-currency-usd-circle-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Payroll</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- Employee Registration -->
          <v-list-item :to="{ name: 'employee' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Employees</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Setup Payroll Items -->
          <v-list-item :to="{ name: 'payrollitem' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Payroll Items</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Pay Run -->
          <v-list-item :to="{ name: 'payrun' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Pay Run</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- Pay Run -->
        <!-- <v-list-item :to="{ name: 'payslip' }" link>
          <v-list-item-icon> </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Pay Slip</v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->
        <!-- 4th -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-id-card</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Advisor</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- Manual Journal-->
          <v-list-item :to="{ name: 'manualjournal' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manual Journal</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Special Journal -->
          <v-list-item :to="{ name: 'specialjournal' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Special Journal</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Setup Tax-->
          <v-list-item :to="{ name: 'setuptax' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Setup Tax</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Fixed Asset Purchase-->
          <v-list-item :to="{ name: 'fixedassetpurchase' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Fixed Asset Purchase</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Fixed Asset Sale-->
          <v-list-item :to="{ name: 'fixedassetsale' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Fixed Asset Sale</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Fixed Asset Item-->
          <v-list-item :to="{ name: 'fixedassetitem' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Fixed Asset Items</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <!-- 5th -->
        <v-list-group>
          <template v-slot:activator>
            <v-list-item-action>
              <v-icon>mdi-cog-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Settings</v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- Company Settings -->
          <v-list-item :to="{ name: 'companysettings' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Company Settings</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Chart of Accounts -->
          <v-list-item :to="{ name: 'chartofaccount' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Chart of Accounts</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- Manage Users -->
          <v-list-item :to="{ name: 'manageuser' }" link class="mb-1">
            <v-list-item-icon> </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manage Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item :to="{ name: 'search' }" link class="mb-1" color="primary">
          <v-list-item-action>
            <v-icon>mdi-magnify</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Search</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item :to="{ name: 'reports' }" link class="mb-1" color="primary">
          <v-list-item-action>
            <v-icon>mdi-newspaper</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- end new data -->
      <template v-slot:append>
    
        <div class="pa-2 pb-4">
          <!-- <div class="text-center">
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
          <v-btn fab :to="{ name: 'search' }" class="mr-2" text  v-bind="attrs"
          v-on="on">
            <v-icon >mdi-magnify</v-icon> 
          </v-btn>
          </template>
          <span>Search</span>
          </v-tooltip>
          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
          <v-btn fab :to="{ name: 'reports' }" text  v-bind="attrs" v-on="on">
            <v-icon>mdi-newspaper</v-icon>  
          </v-btn>
        </template>
        <span>Reports</span>
        </v-tooltip>
      </div> -->
          <v-menu offset-x transition="slide-x-transition" top>
            <template v-slot:activator="{ on }">
              <!-- <v-btn text fab icon v-on="on" class="mr-0" v-if="hasImage">
              <v-avatar color="primary" size="48">
                <img :src="imageSrc" /> </v-avatar
            ></v-btn>
            <v-btn text fab icon v-on="on" class="mr-0" v-else>
              <v-avatar color="primaryLight" size="48">
                <span class="primary--text subtitle-1 font-weight-medium">{{
                  userAvatar
                }}</span>
              </v-avatar></v-btn
            > -->
              <v-list nav  class="ma-0 pa-0">
                <v-list-item
                  v-on="on"
                  style="background-color: rgb(255 255 255 / 8%)"
                >
                  <v-list-item-avatar v-if="hasImage" class="mr-2 ml-0">
                    <v-avatar size="60">
                      <img :src="imageSrc" />
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-avatar v-else class="mr-2 ml-0">
                    <v-avatar color="primaryLight" size="60">
                      <span class="primary--text font-weight-medium">
                        {{ userAvatar }}</span
                      >
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1 font-weight-medium">
                      {{ form.firstName + " " + form.lastName }}
                    </v-list-item-title>
                    <v-list-item-subtitle class="caption">{{
                      form.email
                    }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="ma-0">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          :to="{ name: 'signout' }"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-logout-variant</v-icon>
                        </v-btn>
                      </template>
                      <span>Logout</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </template>

            <v-list>
              <v-list-item class="mb-0 pb-0">
                <v-list-item-avatar>
                  <v-avatar v-if="hasImage" size="80">
                    <img :src="imageSrc" />
                  </v-avatar>
                  <v-avatar color="primary" v-else size="80">
                    <span class="white--text font-weight-medium">
                      {{ userAvatar }}</span
                    >
                  </v-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium">
                    {{ form.firstName + " " + form.lastName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ form.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1 mb-1"></v-divider>
              <v-list-item @click="showDetails">
                <v-list-item-icon>
                  <v-icon>mdi-face-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> User Profile</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="showChangePass">
                <v-list-item-icon>
                  <v-icon>mdi-key</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Change Password </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="viewBillingInfo">
                <v-list-item-icon>
                  <v-icon>mdi-currency-usd-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title> Billing information </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-1 mb-1"></v-divider>
              <v-list-item :to="{ name: 'signout' }">
                <v-list-item-icon>
                  <v-icon>mdi-logout-variant</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-regular">
                    Logout
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="ErrorLight text-center" v-if="showExpiry">
                <v-list-item-content>
                  <v-list-item-subtitle style="font-size: 14px !important">
                    <span class="error--text font-weight-bold">{{
                      currentPackage ? currentPackage.packageName : ""
                    }}</span>
                    <span class="error--text"> will Expire on &nbsp;</span>

                    <span class="error--text font-weight-bold">{{
                      currentPackage ? currentPackage.payExpiryDate : ""
                    }}</span></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- <v-navigation-drawer
      v-model="drawerRight"
      app
      right
      temporary
      width="320"
      flat
      hide-overlay
      class="elevation-2"
    >
    <template v-slot:prepend>
        <div>
          <v-card tile flat>
            <v-card-title>
              <v-icon left @click="drawerRight = !drawerRight" class="ml-2"
                >mdi-apps</v-icon
              >
              <span>All Branches</span>
            </v-card-title>
          </v-card>
          <v-divider></v-divider>
        </div>
      </template>

      <v-list class="pa-0">
        <v-list-item
          v-for="(store, i) in stores"
          :key="i"
          @click="changeBranch(store)"
        >
          <v-list-item-avatar color="primaryLight">
            <v-icon color="primary" size="18">mdi-home-city</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-text="store.branchName"></v-list-item-title>

            <v-list-item-subtitle
              v-text="store.cityName"
            ></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <template v-slot:append>
        <v-list class="pa-0">
          <v-divider></v-divider>
          <v-list-item @click="changeCompany">
            <v-list-item-avatar class="primary" size="38">
              <v-icon class="white--text title" small
                >mdi-camera-switch-outline</v-icon
              >
            </v-list-item-avatar>
            <v-list-item-content class="font-weight-medium">
              Switch Business
            </v-list-item-content>
            <v-list-item-action>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-icon color="grey lighten-1" v-on="on"
                    >mdi-information</v-icon
                  >
                </template>
                <span>Switch To Another Business</span>
              </v-tooltip>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer> -->
    <v-app-bar
      class="d-print-none"
      app
      color="white"
      dark
    >
      <v-toolbar-title>
        <v-app-bar-nav-icon
          @click.stop="setDrawer"
          color="topBarBtnColor"
         
        >
        <v-icon v-if="getDrawer == true" >mdi-menu-open</v-icon>  
        <v-icon v-else class="mdi-flip-h">mdi-menu-open</v-icon> 

      </v-app-bar-nav-icon>
     
      </v-toolbar-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        bottom
        color="primary"
      ></v-progress-linear>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
              <template v-slot:activator="{ on }">
      <v-btn icon @click="toggle_dark_mode" dark color="topBarBtnColor" class="mr-1" v-on="on"> 
        <v-icon>mdi-theme-light-dark</v-icon>
    </v-btn>
  </template> 
              <span>Switch to dark mode</span>
            </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            @click="handleFullScreen()"
            v-on="on"
            text
            class="mr-1"
            color="topBarBtnColor"
          >
            <v-icon>mdi-fullscreen</v-icon>
          </v-btn>
        </template>
        <span>Full Screen</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            dark
            icon
            @click.stop="drawerRight = !drawerRight"
            v-on="on"
            class="grey--text text--darken-3 mr-1"
          >
            <v-icon>mdi-apps</v-icon>
          </v-btn>
        </template>
        <span>Switch Branch</span>
      </v-tooltip> -->
      <v-menu offset-y>
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn
                dark
                icon
                color="topBarBtnColor"
                class="mr-1"
                v-bind="attrs"
                v-on="{ ...tooltip, ...menu }"
              >
                <v-icon>mdi-apps</v-icon>
              </v-btn>
            </template>
            <span>Switch Branch</span>
          </v-tooltip>
        </template>
        <v-card
          flat
          style="
            height: 100%;
            max-height: calc(100vh - 235px);
            overflow-x: hidden;
          "
        >
          <v-list>
            <v-subheader>All Branches </v-subheader>
            <v-list-item
              v-for="(store, i) in stores"
              :key="i"
              @click="changeBranch(store)"
            >
              <v-list-item-avatar color="primaryLight">
                <v-icon color="primary" size="18">mdi-home-city</v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-text="store.branchName"
                ></v-list-item-title>

                <v-list-item-subtitle
                  v-text="store.cityName"
                ></v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="changeCompany">
              <v-list-item-avatar class="primary" size="38">
                <v-icon class="white--text title" small
                  >mdi-camera-switch-outline</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Switch Business</v-list-item-title>
                <v-list-item-subtitle
                  >Switch To Another Business</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

      <!-- <v-menu
        offset-y
        transition="scroll-y-transition"
        absolute
        max-width="280"
        mim-width="280"
        nudge-width="270"
      >
        <template v-slot:activator="{ on }">
          <v-btn text fab icon v-on="on" class="mr-0" v-if="hasImage">
            <v-avatar color="primary" size="48">
              <img :src="imageSrc" /> </v-avatar
          ></v-btn>
          <v-btn text fab icon v-on="on" class="mr-0" v-else>
            <v-avatar color="primaryLight" size="48">
              <span class="primary--text subtitle-1 font-weight-medium">{{
                userAvatar
              }}</span>
            </v-avatar></v-btn
          >
        </template>
        <v-list class="pb-0">
          <div class="text-center">
            <div class="mb-0 mt-4">
              <v-avatar v-if="hasImage" size="80">
                <img :src="imageSrc" />
              </v-avatar>
              <v-avatar color="primary" v-else size="80">
                <span class="white--text headline font-weight-medium">
                  {{ userAvatar }}</span
                >
              </v-avatar>
            </div>
            <v-list class="pa-0 ma-0">
              <v-list-item class="mb-0 pb-0">
                <v-list-item-content>
                  <v-list-item-title class="subtitle-1 font-weight-medium">
                    {{ form.firstName + " " + form.lastName }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ form.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
         

          <v-list class="pa-0">
            <v-divider></v-divider>
            <v-list-item @click="showDetails">
              <v-list-item-avatar color="primaryLight" size="38">
                <v-icon color="primary" size="22">mdi-face-outline</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> User Profile</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="showChangePass">
              <v-list-item-avatar color="primaryLight" size="38">
                <v-icon color="primary" size="22">mdi-key</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Change Password </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="viewBillingInfo">
              <v-list-item-avatar color="primaryLight" size="38">
                <v-icon color="primary" size="22"
                  >mdi-currency-usd-circle-outline</v-icon
                >
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title> Billing information </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>

          <v-divider></v-divider>
        
          <div class="text-center mt-2 mb-2 pl-4 pr-4">
            <v-btn
              depressed
              outlined
              block
              large
              class="text-capitalize"
              color="primary"
              :to="{ name: 'signout' }"
            >
              <v-icon left>mdi-logout-variant</v-icon>logout</v-btn
            >
          </div>
          <v-divider style="border-color: #e8eaed"></v-divider>
          <v-list-item class="grey lighten-4 text-center" v-if="showExpiry">
            <v-list-item-content>
              <v-list-item-subtitle style="font-size: 12px !important">
                <span class="error--text font-weight-bold">{{
                  currentPackage ? currentPackage.packageName : ""
                }}</span>
                will Expire on
                <span class="error--text font-weight-bold">{{
                  currentPackage ? currentPackage.payExpiryDate : ""
                }}</span></v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>
      
        </v-list>
      </v-menu> -->
    </v-app-bar>
    <!-- //USER DETAILS Dialog//  -->
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-sheet  class="primaryLight"  height="145">
                   
                  </v-sheet>
                
        <div class="d-flex justify-center pb-8 mt-n70">
          <v-hover style="height: auto">
            <template v-slot:default="{ hover }">
              <v-card width="auto" height="120px" class="transparent">
                <v-avatar
                  v-if="!hasImage"
                  color="primary"
                  size="120"
                  rounded
                  class="white--text font-weight-medium"
                >
                  {{ userAvatar }}
                </v-avatar>
                <v-avatar v-if="hasImage" size="120" rounded>
                  <img :src="imageSrc" />
                </v-avatar>
                <!-- end image name -->
                <v-fade-transition>
                  <v-overlay v-if="hover" absolute color="#212121">
                    <div class="text-center">
                      <v-btn
                        text
                        x-small
                        class="white primary--text caption font-weight-medium"
                        @click="removeImage"
                        v-if="hasImage"
                      >
                        <v-icon left> mdi-cloud-upload </v-icon>
                        <span>Update</span>
                      </v-btn>
                      <v-btn
                        class="white primary--text caption font-weight-medium"
                        x-small
                        @click="onButtonClick"
                        v-if="!hasImage"
                      >
                        <v-icon left> mdi-cloud-upload </v-icon>
                        <span
                          class="d-inline-block text-truncate caption"
                          style="max-width: 70px"
                          >{{ uploadBtnText }}</span
                        >
                      </v-btn>
                    </div>
                  </v-overlay>
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </div>
        <v-card-text class="text-center">
          <p v-if="fileError" class="error--text mb-1 mt-1 pb-0">
            File Size should be less than 5 MB
          </p>
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="12">
              <v-text-field
                label="User Name"
                v-model="userName"
                dense
                filled
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                label="First Name"
                v-model="$v.form.firstName.$model"
                :error-messages="fNameErrors"
                :counter="counterOne"
                dense
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <v-text-field
                label="Last Name"
                v-model="$v.form.lastName.$model"
                :error-messages="lNameErrors"
                :counter="counterOne"
                filled
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                disabled="true"
                label="Email"
                v-model="form.email"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Phone Number"
                v-model="$v.form.phone.$model"
                :error-messages="phoneErrors"
                :counter="counterFive"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" class="d-none">
              <input
                ref="uploader"
                type="file"
                class="d-none"
                accept="image/png, image/jpeg,image/jpg"
                @change="onFileChanged"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
                      <v-btn
                        class="ml-2 mr-2"
                        type="submit"
                        color="primary" 
                        @click="updateUser"
                        text
                        ><v-icon left> mdi-content-save-outline </v-icon>
                        Save</v-btn
                      >
                      <v-btn
                        class="ml-1 mr-1"
                        color="error"
                      
                        text
                        @click="setOldInfo"
                      >
                        <v-icon left> mdi-cancel </v-icon> Cancel</v-btn
                      >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- //FOR Change Password -->
    <v-snackbar v-model="snackbar" :color="snackColor" transition="scale-transition" origin="center center">
      {{ changePasstext }}
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="passwordDialog" persistent max-width="450px">
      <v-card>
        <div class="pt-4 pb-2">
          <!-- <img
            src="../../assets/images/authSvg/changePassword.svg"
            alt=""
            width="100%"
            height="180"
            class="mt-4 mb-4"
          /> -->

          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                color="primaryLight"
                size="120"
              >
              <v-icon color="primary" size="52">mdi-lock-check-outline</v-icon>
              </v-avatar>
              <h3 class="text-h5 mt-3">Change Password</h3>
             
              
             
            </div>
          </v-list-item-content>
        
         
       
        </div>
        <v-card-text >
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12" v-if="showOld">
              <v-text-field v-model="oldPassword" name="oldPassword" />
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="Current Password"
                v-model="$v.form.password.$model"
                :error-messages="passwordErrors"
                @click:append="show1 = !show1"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show1 ? 'text' : 'password'"
                filled
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <v-text-field
                label="New Password"
                v-model="$v.form.newPassword.$model"
                :error-messages="newPasswordErrors"
                @click:append="show2 = !show2"
                :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                :type="show2 ? 'text' : 'password'"
                filled
                hide-details="auto"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="mx-2">
          <v-btn color="primary" text @click="changePassword">
            <v-icon left> mdi-content-save-outline </v-icon>  Save
          </v-btn>

          <v-btn color="error" text @click="closePasswordDialog">
            <v-icon left> mdi-cancel </v-icon> Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Billing Details Dialog -->
    <v-dialog
      v-model="billingDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark tile color="primary">
          <v-toolbar-title>Billing Information</v-toolbar-title>
          <v-spacer></v-spacer>
          
            <v-btn icon @click="billingDialog = false" class="mr-1">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          
        </v-toolbar>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" sm="12" md="12" lg="12" xl="12">
              <!-- Records of Billing  -->
              <v-data-table
                :headers="headers"
                :items="billingDetails"
                class="elevation-1"
              >
                <template v-slot:top>
                  <div >
                    <v-toolbar height="50" rounded color="white" flat>
            <v-toolbar-title class="title">
              Packages History
              
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-tooltip bottom v-if="currentPackage.packagePrice > 0">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                         
                           
                           
                          @click="
                            billingDialog = false;
                            renewDialog = true;
                          "
                          >Renew</v-btn
                        >
                      </template>
                      <span>Renew Current Package</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          
                           
                           
                          @click="
                            billingDialog = false;
                            changeDialog = true;
                          "
                          ><v-icon left>mdi-restore</v-icon> Upgrade</v-btn
                        >
                      </template>
                      <span>Upgrade your Package</span>
                    </v-tooltip>
          </v-toolbar>
              
                   
                    
                  </div>
                  <v-divider></v-divider>
                </template>
              </v-data-table>
              <!-- Records of Billing Ends  -->
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>
    <!-- Billing Details Dialog ends -->
    <!-- Renew Package  -->
    <v-dialog v-model="renewDialog" max-width="600">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title> Package Details </v-card-title>
            <v-divider></v-divider>
            <v-card-text >
              <v-simple-table>
                <tbody>
                  <tr>
                    <td>
                      <span class="font-weight-medium">
                        Current Package</span
                      >
                    </td>
                    <td class="text-end">
                      <label>
                        <v-chip color="secondary">{{
                          currentPackage ? currentPackage.packageName : ""
                        }}</v-chip>
                      </label>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="font-weight-medium"> Price</span>
                    </td>
                    <td class="text-end">
                      <label
                        class="primary--text font-weight-bold"
                        >{{
                          currentPackage ? currentPackage.payment : ""
                        }}</label
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="font-weight-medium">
                        Duration</span
                      >
                    </td>
                    <td class="text-end">
                      <label
                        ><v-chip
                          >{{
                            currentPackage ? currentPackage.paymentCycle : ""
                          }}
                          Month</v-chip
                        ></label
                      >
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="font-weight-medium">
                        Payment Date</span
                      >
                    </td>
                    <td class="text-end">
                      <v-chip color="primary">
                        <!-- {{currentPackage ? currentPackage.payDate : ""}} -->
                        {{ new Date().toISOString().slice(0, 10) }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="font-weight-medium"
                        >Expiry Date</span
                      >
                    </td>
                    <td class="text-end">
                      <v-chip color="error">
                        {{ currentPackageExpiry }}
                      </v-chip>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mx-2">
          
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="RenewCheckout(currentPackage)"
                    
                    v-bind="attrs"
                    v-on="on"
                    color="primary"
                    
                  >
                    Renew
                  </v-btn>
                </template>
                Renew Your Package
              </v-tooltip>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- Renew Package Ends here-->
    <!-- Change Package -->
    <!-- Renew Package  -->
    <v-dialog v-model="changeDialog" max-width="600">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title> Select Package </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-select
                :items="packages"
                v-model="selectedPackage"
                label="Choose Package"
                @change="newlySelectedPkg"
                filled
              ></v-select>
              <v-subheader
                class="pa-0 subtitle-1 font-weight-medium"
                v-if="selectedPackage"
                >Selected Package is
                <v-chip class="ml-2" color="primary">{{
                  selectedPackageDetails[0]
                    ? selectedPackageDetails[0].packageName
                    : ""
                }}</v-chip>
              </v-subheader>
          
              <v-data-table
                class="mt-3"
                :items="selectedPackageDetails"
                :headers="packageHeaders"
                hide-default-footer
              >
                <template v-slot:item.action="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="ChangeCheckout(item)"
                        v-bind="attrs"
                        v-on="on"
                        rounded
                        text
                        small
                        color="error"
                      >
                        Change
                      </v-btn>
                    </template>
                    Change Your Package
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- Renew Package Ends here-->
    <!-- Change Package Ends here-->
    <!-- StripePayment Dialog For Change -->
    <v-dialog v-model="stripeDialog" max-width="600">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title> Payment </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <stripe-elements
                ref="elementsRef"
                :pk="publishableKey"
                :amount="amount"
                locale="en"
                @token="tokenCreated"
                @loading="loading = $event"
              >
              </stripe-elements>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td class="font-weight-medium">Package Name</td>
                      <td style="text-align: right">
                        {{ toPayFor ? toPayFor.packageName : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">Amount to Pay</td>
                      <td style="text-align: right">
                        {{ toPayFor ? toPayFor.payment : "" }}
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">Package Duration</td>
                      <td style="text-align: right">
                        {{ toPayFor ? toPayFor.paymentCycle : "" }} Month
                      </td>
                    </tr>
                    <tr>
                      <td class="font-weight-medium">Package Expiry Date</td>
                      <td style="text-align: right">
                        {{ toPayFor ? toPayFor.payExpiryDate : "" }}
                      </td>
                    </tr>
                    <tr class="primaryLight">
                      <td class="font-weight-medium">Balance</td>
                      <td style="text-align: right" class="font-weight-bold">
                        {{ toPayFor ? toPayFor.remainingAmount : "" }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mx-2">
              
              <v-btn
                :loading="saveLoader"
                color="primary"
                
                 
                
                @click="submit(toPayFor, 'update')"
                >Pay</v-btn
              >
              <!-- Change Package Submit  -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
    <!-- StripePayment Dialog For Renew -->
    <v-dialog v-model="renewStripeDialog" max-width="600">
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-card>
            <v-card-title> Payment </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <stripe-elements
                ref="elementsRef"
                :pk="publishableKey"
                :amount="amount"
                locale="en"
                @token="tokenCreated"
                @loading="loading = $event"
              >
              </stripe-elements>
            </v-card-text>
            <v-card-text>
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr>
                      <td>
                        <span class="font-weight-medium"
                          >Package</span
                        >
                      </td>
                      <td class="text-end">
                        <v-chip class="secondary">
                          {{
                            toPayForRenew ? toPayForRenew.packageName : ""
                          }}</v-chip
                        >
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="font-weight-medium">Price</span>
                      </td>
                      <td class="text-end">
                        <v-chip>
                          {{ toPayForRenew ? toPayForRenew.payment : "" }}
                        </v-chip>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="font-weight-medium"
                          >Duration</span
                        >
                      </td>
                      <td class="text-end">
                        <label
                          class="primary--text font-weight-bold subtitle-1"
                        >
                          {{ toPayForRenew ? toPayForRenew.paymentCycle : "" }}
                          Month
                        </label>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="mx-2">
              <v-btn
                :loading="saveLoader"
                color="primary"
                
                @click="submit(toPayForRenew, 'renew')"
                >Pay Now</v-btn
              >
              <!-- Renew Submit  -->
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-dialog>
  </div>
</template>
<script>
import errorMessages from "../../assets/js/errorMessages";
import mainValidations from "../../assets/js/validations";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
import { StripeElements } from "vue-stripe-checkout";
import fullscreen from "../../util/fullscreen";
import { mapGetters } from "vuex";
export default {
  components: {
    StripeElements,
  },
  created() {
    this.userRights = this.$store.getters["setupItem/viewUserRights"];
    this.userRights.forEach((val) => {
      if (val.menuId == 25 && val.displayPermission) {
        this.showExpiry = true;
      }
    });
    this.userDetails();
    this.viewBusinessPackage();
    this.viewPackageDetails();
    // this.$store
    //   .dispatch("settings/ViewBusinessUser")
    //   .then((res) => {
    //     if (res.data.success == "Ok") {
    //       const RES_DATA = res.data.businessUserView;
    //       if (RES_DATA.length > 0) {
    //         for (let i = 0; i < RES_DATA.length; i++) {
    //           if (
    //             RES_DATA[i].status == "Active" ||
    //             RES_DATA[i].status == "active"
    //           ) {
    //             this.viewUsersActive.push(RES_DATA[i]);
    //           }
    //         }
    //       }
    //       console.log("Active:", this.viewUsersActive);
    //       console.log("Inactive:", this.viewUsersDeactive);
    //     } else {
    //     }
    //     this.isloading = false;
    //   })
    //   .catch((error) => {
    //     this.resErrors = [];
    //     this.resErrors.push("Something went wrong. Try again later");
    //     document
    //       .getElementById("res")
    //       .scrollIntoView({ block: "start", inline: "start" });
    //     console.log(error);
    //   });
  },
  mounted() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
        if (theme === "true") {
            this.$vuetify.theme.dark = true;
        } else {
            this.$vuetify.theme.dark = false;
        }
    } else if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
    ) {
        this.$vuetify.theme.dark = true;
        localStorage.setItem(
            "dark_theme",
            this.$vuetify.theme.dark.toString()
        );
    }
},
  methods: {
    toggle_dark_mode: function() {
        this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
        localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
    },


    setDrawer() {
      this.$store.dispatch("setupItem/setDrawer", !this.getDrawer);
    },
    handleFullScreen() {
      fullscreen.toggleFullScreen();
    },
    //Payment
    submit(el, actionType) {
      if (actionType == "update") {
        console.log("update case", el);
        this.actionType = "update";
        this.packageID = el.packageID;
        this.payCycle = el.paymentCycle;
      } else {
        console.log("renewcase", el);
        this.actionType = "renew";
        this.packageID = el.packageID;
        this.payCycle = el.paymentCycle;
      }
      this.$refs.elementsRef.submit();
    },
    tokenCreated(token) {
      // this.token = token;
      // for additional charge objects go to https://stripe.com/docs/api/charges/object
      this.charge = {
        source: token.id,
        // amount: this.amount, // the amount you want to charge the customer in cents. $100 is 1000 (it is strongly recommended you use a product id and quantity and get calculate this on the backend to avoid people manipulating the cost)
        // email: this.email,
        packageID: this.packageID,
        payCycle: this.payCycle,
        actionType: this.actionType,
      };
      this.sendTokenToServer(this.charge);
    },
    sendTokenToServer(charge) {
      this.saveLoader = true;
      this.$store.dispatch("setupItem/payment", charge).then((res) => {
        console.log("Response of StripePayment", res);
        let response = res.data;
        console.log("PAid", response ? "yes" : "no");
        if (response.success == "OK") {
          // this.saveLoader=true
          if (this.actionType == "update") {
            console.log("actionType", this.actionType);
            let data = {
              packageid: this.packageID,
              payment_cycle: this.payCycle,
            };
            this.changePackage(data);
          } else {
            console.log("case renew", data);
            let data = {
              packageid: this.packageID,
              payment_cycle: this.payCycle,
            };
            console.log("data for renew", data);
            this.renewPackage(data);
          }
        } else if (response.sucess == "Failed") {
          this.snackColor = "error";
          this.changePasstext = response.response;
          this.snackbar = true;
          this.saveLoader = false;
        } else {
          this.snackColor = "error";
          this.changePasstext = response.response[0].message;
          this.snackbar = true;
          this.saveLoader = false;
        }
      });
    },
    //Payment
    ChangeCheckout(item) {
      console.log("ITEM IS", item);
      // this.toPayFor = item;
      // this.stripeDialog = true;
      // this.changeDialog = false;
      let data = {
        packageID: item.packageID,
        paymentCycle: item.packageDuration,
      };
      this.$store.dispatch("setupItem/ViewPaymentDetails", data).then((res) => {
        console.log("response of view details of package to change", res);
        let result = res.data;
        if (result.success == "Ok") {
          if (result.businessPackageView[0].payment == 0) {
            let data = {
              packageid: result.businessPackageView[0].packageID,
              payment_cycle: result.businessPackageView[0].paymentCycle,
            };
            this.changePackage(data);
            this.changeDialog = false;
          } else {
            this.toPayFor = result.businessPackageView[0];
            this.stripeDialog = true;
            this.changeDialog = false;
          }
        } else {
          this.changePasstext = res.data.response[0].message;
          this.snackbar = true;
          this.snackColor = "error";
          this.changeDialog = false;
        }
      });
    },
    changePackage(item) {
      console.log("changePackage to item", item);
      let data = {
        packageid: item.packageid,
        payment_cycle: item.payment_cycle,
      };
      this.$store
        .dispatch("setupItem/UpdateBusinessPackage", data)
        .then((res) => {
          // console.log("Update Package", res);
          if (res.data.success == "OK") {
            this.changePasstext = res.data.response[0].message;
            this.snackbar = true;
            this.snackColor = "snackbarSuccess";
            this.stripeDialog = false;
            this.saveLoader = false;
            this.viewBusinessPackage();
            this.viewPackageDetails();
          } else {
            this.changePasstext = res.data.response[0].message;
            this.snackbar = true;
            this.snackColor = "error";
            this.stripeDialog = false;
            this.saveLoader = false;
          }
        });
    },
    RenewCheckout(el) {
      // console.log("ELEMENT", el);
      if (el.packageName == "Free Trial" || el.packagePrice == 0) {
        this.changePasstext = "Cant renew the current Package";
        this.snackbar = true;
        this.snackColor = "error";
        this.renewDialog = false;
        return;
      }
      this.renewDialog = false;
      this.toPayForRenew = el;
      this.renewStripeDialog = true;
    },
    renewPackage(item) {
      //  console.log("renewPackage to item", item);
      let data = {
        packageid: item.packageid,
        payment_cycle: item.payment_cycle,
      };
      this.$store
        .dispatch("setupItem/RenewBusinessPackage", data)
        .then((res) => {
          console.log("Renew Package", res);
          if (res.data.success == "OK") {
            this.changePasstext = res.data.response[0].message;
            this.snackbar = true;
            this.snackColor = "snackbarSuccess";
            this.renewStripeDialog = false;
            this.saveLoader = false;
            this.viewBusinessPackage();
          } else {
            this.changePasstext = res.data.message;
            this.snackbar = true;
            this.snackColor = "snackbarSuccess";
            this.renewStripeDialog = false;
            this.saveLoader = false;
          }
        });
    },
    viewBusinessPackage() {
      console.warn(
        "VIEWBUSINESSPKG PERMISSION",
        this.getonUserRightDisplayPermission
      );
      //To view All The Packages of Business
      if (this.getonUserRightDisplayPermission) {
        this.$store
          .dispatch("userAndPackageDetails/ViewBusinessPackage")
          .then((res) => {
            let result = res.data;
            if (result.success == "Ok") {
              this.billingDetails = result.businessPackageView;
              result.businessPackageView.forEach((val) => {
                if (val.status == "Active") {
                  this.currentPackage = val;
                }
              });
            }
            // this.businessPackages.push(res.data[0])
            // this.packageDetails=res.data
          });
      }
    },
    viewPackageDetails() {
      // let packageName=this.packages[this.selectedPackage-1].text;
      // console.log("Package Name",packageName)
      this.selectedPackageDetails = [];
      this.$store.dispatch("userAndPackageDetails/viewPackage").then((res) => {
        console.log("VIEW Package Details", res);
        this.selectedPackageDetails.push(res.data[1]);
        this.packageDetails = res.data;
      });
    },
    newlySelectedPkg() {
      this.selectedPackageDetails = [];
      let packageName = null;
      this.packages.forEach((val) => {
        if (val.value == this.selectedPackage) {
          packageName = val.text;
        }
      });
      //   for(let i=0;i<this.packages.length;i++){
      //     if(this.packages[i].value==this.selectedPackage){
      //       console.log('Matched',this.packages[i].value,this.selectedPackage)
      //       packageName=this.packages[i].text
      //       console.log('packageName',packageName)
      //     }
      // }
      this.packageDetails.forEach((val) => {
        if (val.packageName == packageName) {
          this.selectedPackageDetails.push(val);
        }
      });
    },
    viewBillingInfo() {
      this.billingDialog = true;
    },
    // showRenewPackage(){
    //   this.billingDialog = false;
    //   this.renewDialog=true;
    // },
    // showChangePackage(){
    //   this.billingDialog = false;
    //   this.changeDialog=true;
    // },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      console.log("e", e.target.files[0].name);
      if (e.target.files[0].size > 5000000) {
        this.form.file = null;
        this.buttonText = "Upload";
        this.picAction = 0;
        this.fileError = true;
      } else {
        this.fileError = false;
        this.form.file = e.target.files[0];
        this.buttonText = e.target.files[0].name;
        this.picAction = 2;
      }
      // console.log("FILE NAME", e, e.target.files[0].size);
    },
    updateImage() {
      this.picAction = 2;
    },
    removeImage() {
      this.hasImage = false;
      this.picAction = 1;
    },
    setOldInfo() {
      this.form.firstName = this.oldName;
      this.form.lastName = this.oldLastName;
      this.form.phone = this.oldPhone;
      // this.hasImage = true;
      this.dialog = false;
    },
    userDetails() {
      this.userInfo = this.$store.getters["auth/getUserDetails"];
      this.$store
        .dispatch("userAndPackageDetails/viewuserdetails")
        .then((res) => {
          // console.log("VIEW USERS DETAILS", res);
          // let result = res.data;
          let result = res.data.response;
          (this.userName = result.userName),
            (this.form.firstName = result.userFirstName),
            (this.form.lastName = result.userLastName),
            (this.form.userID = result.userID),
            (this.form.email = result.email),
            (this.form.phone = result.userContact),
            (this.oldName = result.userFirstName),
            (this.oldLastName = result.userLastName),
            (this.oldPhone = result.userContact);
          if (result.photo) {
            this.hasImage = true;
            (this.imageSrc = result.photo),
              (this.form.picName = result.photo.split("/").pop());
            this.form.file = null;
            this.buttonText = "Upload";
          }
        });
    },
    updateUser() {
      console.log("fileError", this.fileError);
      if (!this.fileError) {
        const formdata = new FormData();
        formdata.append("userFirstName", this.form.firstName);
        formdata.append("userLastName", this.form.lastName);
        formdata.append("userContact", this.form.phone);
        if (this.picAction == 1) {
          formdata.append("PicName", this.form.picName);
          formdata.append("PicAction", this.picAction);
          this.imageSrc = null;
          this.hasImage = false;
          this.buttonText = "Upload";
        }
        if (this.picAction == 2 && this.form.file) {
          if (this.picName) {
            formdata.append("Photo", this.form.file);
            formdata.append("PicAction", this.picAction);
            formdata.append("PicName", this.form.picName);
          } else {
            formdata.append("PicAction", this.picAction);
            formdata.append("Photo", this.form.file);
          }
        }
        // formdata.append("Photo",this.form.file);
        // formdata.append("PicAction", 2);
        // formdata.append("PicName",this.form.picName)
        // let data={
        //   userFirstName:this.form.firstName,
        //   userLastName:this.form.lastName,
        //   userContact:this.form.phone
        // }
        let data = {
          formdata: formdata,
          userID: this.form.userID,
        };
        // this.viewUsersActive.forEach((val) => {
        //   if (val.userEmail == this.form.email) {
        //     data["userID"] = val.userId;
        //   }
        // });
        this.$store.dispatch("auth/updateUser", data).then((res) => {
          console.log("UPDATE USER Response", res);
          if (res.data.success == "OK") {
            (this.userInfo.userContact = this.form.phone),
              (this.userInfo.userFirstName = this.form.firstName);
            this.userInfo.userLastName = this.form.lastName;
            this.changePasstext = res.data.response[0].message;
            this.snackColor = "snackbarSuccess";
            this.snackbar = true;
            this.dialog = false;
            this.userDetails();
          } else {
            this.changePasstext = res.data.response[0].message;
            this.form.phone = this.oldPhone;
            this.form.firstName = this.oldName;
            this.form.lastName = this.oldLastName;
            this.snackColor = "error";
            this.snackbar = true;
            this.dialog = false;
          }
        });
      } else {
        return;
      }
    },
    showDetails() {
      this.dialog = !this.dialog;
    },
    showChangePass() {
      (this.oldPassword = this.userInfo.password),
        (this.passwordDialog = !this.passwordDialog);
    },
    changeCompany() {
      this.$store.dispatch("setupItem/setSelectBusiness", null).then(() => {
        this.$router.push({
          name: "selectBusiness",
        });
      });
    },
    changeBranch(branchData) {
      this.$store.dispatch("setupItem/setBranch", branchData).then(() => {
        this.$router.go(0);
      });
    },
    closePasswordDialog() {
      this.$v.form.password.$reset();
      this.$v.form.newPassword.$reset();
      this.passwordDialog = false;
    },
    changePassword() {
      this.$v.form.password.$touch();
      this.$v.form.newPassword.$touch();
      if (
        this.$v.form.password.$anyError ||
        this.$v.form.newPassword.$anyError
      ) {
        return;
      } else {
        let data = {
          oldPassword: this.form.password,
          newPassword: this.form.newPassword,
        };
        this.$store.dispatch("auth/changePassword", data).then((res) => {
          console.log("UPDATE PASSWORD", res);
          if (res.data.success == "OK") {
            this.changePasstext = res.data.response[0].message;
            this.form.password = "";
            this.form.newPassword = "";
            this.$v.form.$reset();
            this.snackColor = "snackbarSuccess";
            this.snackbar = true;
            this.passwordDialog = false;
          } else {
            this.changePasstext = res.data.response[0].message;
            this.snackColor = "error";
            this.snackbar = true;
            this.passwordDialog = false;
          }
        });
      }
    },
  },
  computed: {
    ...mapGetters("setupItem", [
      "getonUserRightDisplayPermission",
      "getDrawer",
    ]),
    currentPackageExpiry() {
      if (this.currentPackage) {
        var expiryDate = new Date(this.currentPackage.payExpiryDate);
        expiryDate.setMonth(
          expiryDate.getMonth() + this.currentPackage.paymentCycle
        );
        return expiryDate.toISOString().slice(0, 10);
      }
    },
    uploadBtnText() {
      return this.buttonText;
      // return this.form.file ? this.buttonText : this.buttonText;
    },
    fNameErrors() {
      const errors = [];
      if (!this.$v.form.firstName.$dirty) return errors;
      !this.$v.form.firstName.alphaspace &&
        errors.push("First Name must contain Alphabets Only");
      !this.$v.form.firstName.required && errors.push("First Name is required");
      !this.$v.form.firstName.checkNameLength &&
        errors.push(
          errorMessages.lengthMessages("First Name", mainValidations.length1)
        );
      return errors;
    },
    lNameErrors() {
      const errors = [];
      if (!this.$v.form.lastName.$dirty) return errors;
      !this.$v.form.lastName.alphaspace &&
        errors.push("Last Name must contain Alphabets Only");
      !this.$v.form.lastName.required && errors.push("Last Name is required");
      !this.$v.form.lastName.checkNameLength &&
        errors.push(
          errorMessages.lengthMessages("First Name", mainValidations.length1)
        );
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone.$dirty) return errors;
      !this.$v.form.phone.checkPhoneNumberLength &&
        errors.push(
          errorMessages.lengthMessages("Number", mainValidations.length5)
        );
      !this.$v.form.phone.checkNumeric &&
        errors.push(errorMessages.contactNumbersMessages);
      !this.$v.form.phone.required &&
        errors.push(errorMessages.requiredMessages("Phone Number"));
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.form.password.$dirty) return errors;
      !this.$v.form.password.required && errors.push("Password is required");
      !this.$v.form.password.passwordPattern &&
        errors.push(
          "Password should contain alphabets,numbers and specialcharacters"
        );
      !this.$v.form.password.passLength &&
        errors.push("Password must be 8 characters long");
      return errors;
    },
    newPasswordErrors() {
      const errors = [];
      if (!this.$v.form.newPassword.$dirty) return errors;
      !this.$v.form.newPassword.required &&
        errors.push("Password is required to update");
      !this.$v.form.newPassword.passwordPattern &&
        errors.push(
          "Password should contain alphabets,numbers and specialcharacters"
        );
      !this.$v.form.newPassword.passLength &&
        errors.push("Password must be 8 characters long");
      return errors;
    },
    // userDetails(){
    //   this.userInfo=this.$store.getters['auth/getUserDetails']
    //   console.log("this.$store.getters['auth/getUserDetails']",this.$store.getters['auth/getUserDetails'])
    //   return this.$store.getters['auth/getUserDetails']
    // },
    loading() {
      return this.$store.getters["setupItem/getProgressBar"];
    },
    currentRouteName() {
      return this.$route.name;
    },
    stores() {
      return this.$store.getters["auth/getallBranches"];
    },
    businessName() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.businessName;
      } else {
        return "";
      }
    },
    userAvatar() {
      let userName = this.oldName + " " + this.oldLastName;
      let arr = userName.split(" ");
      let name = "";
      for (let i = 0; i < arr.length; i++) {
        if (i > 1) {
          break;
        }
        if (arr[i] != "") {
          name += arr[i][0];
        }
      }
      return name;
    },
    avatar() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        let businessName = allBusinessData.businessName;
        let arr = businessName.split(" ");
        let name = "";
        for (let i = 0; i < arr.length; i++) {
          if (i > 1) {
            break;
          }
          if (arr[i] != "") {
            name += arr[i][0];
          }
        }
        return name;
      } else {
        return "";
      }
    },
    branchName() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.branchName;
      } else {
        return "";
      }
    },
    logo() {
      let allBusinessData =
        this.$store.getters["setupItem/getSelectedBusiness"];
      if (allBusinessData != null) {
        return allBusinessData.logo;
      } else {
        return null;
      }
    },
  },
  props: {
    source: String,
  },
  watch: {
    color(val) {
      this.$vuetify.theme.themes[this.isDark ? "dark" : "light"].primary = val;
    },
  },
  data: () => ({
    saveLoader: false,
    actionType: null,
    packageID: null,
    payCycle: null,
    stripeEmail: "",
    token: null,
    charge: null,
    amount: "",
    publishableKey: "pk_test_17InAewVNcDqj5Igs07zhpy700qujMbU4P",
    token: null,
    charge: null,
    counterOne: mainValidations.length1,
    counterFive: mainValidations.length5,
    hideDetails: false,
    userInfo: null,
    dialog: false,
    billingDialog: false,
    changeDialog: false,
    stripeDialog: false,
    renewStripeDialog: false,
    renewDialog: false,
    passwordDialog: false,
    showOld: false,
    drawer: null,
    drawerRight: null,
    toPayFor: null,
    toPayForRenew: null,
    viewUsersActive: [],
    right: false,
    snackColor: "snackbarSuccess",
    show1: false,
    show2: false,
    hasDetails: false,
    userDetailsEdit: true,
    oldPassword: null,
    changePasstext: null,
    snackbar: false,
    oldName: "",
    oldLastName: "",
    oldPhone: "",
    imageSrc: "",
    picAction: 0,
    hasImage: false,
    buttonText: "Upload",
    fileError: false,
    userRights: null,
    showExpiry: false,
    userName: "",
    form: {
      firstName: "",
      lastName: "",
      password: "",
      newPassword: "",
      email: "",
      phone: "",
      file: null,
      picName: "",
      userID: "",
    },
    currentPackage: null,
    selectedPackage: 1,
    packageDetails: [],
    selectedPackageDetails: [],
    packageHeaders: [
      {
        text: "Price",
        value: "packagePrice",
        sortable: false,
      },
      {
        text: "Duration",
        value: "packageDuration",
        sortable: false,
        align: "center",
      },
      {
        text: "Action",
        value: "action",
        sortable: false,
        align: "end",
      },
    ],
    packages: [
      {
        text: "Accounting Basic",
        value: 1,
      },
      {
        text: "Accounting Pro",
        value: 2,
      },
      {
        text: "Accounting Premium",
        value: 3,
      },
    ],
    billingDetails: [],
    headers: [
      {
        text: "Package",
        sortable: false,
        value: "packageName",
      },
      { text: "Price", value: "packagePrice", sortable: false },
      { text: "Payment", value: "payment", sortable: false },
      { text: "Duration", value: "paymentCycle", sortable: false },
      { text: "Payment Date", value: "creationDate", sortable: false },
      { text: "Expiry Date", value: "payExpiryDate", sortable: false },
      { text: "Balance", value: "remainingAmount", sortable: false },
    ],
  }),
  mixins: [validationMixin],
  validations: {
    form: {
      firstName: {
        required,
        alphaspace: (val) => {
          return mainValidations.alphabetsOnly(val);
        },
        checkNameLength: (val) => {
          return mainValidations.nameLength(val);
        },
      },
      lastName: {
        required,
        alphaspace: (val) => {
          return mainValidations.alphabetsOnly(val);
        },
        checkNameLength: (val) => {
          return mainValidations.nameLength(val);
        },
      },
      password: {
        required,
        // sameAs: sameAs('oldPassword')
        passwordPattern: (val) => {
          return mainValidations.passwordPattern(val);
        },
        passLength: (val) => {
          if (val.length < 8) {
            return false;
          } else {
            return true;
          }
        },
      },
      newPassword: {
        required,
        passwordPattern: (val) => {
          return mainValidations.passwordPattern(val);
        },
        passLength: (val) => {
          if (val.length < 8) {
            return false;
          } else {
            return true;
          }
        },
      },
      phone: {
        required,
        checkPhoneNumberLength: (val) => {
          return mainValidations.PhoneNumberLength(val);
        },
        checkNumeric: (val) => {
          return mainValidations.contactNumber(val);
        },
      },
    },
  },
};
</script>
 
