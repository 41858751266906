import axios from "axios";
import { mapState, mapGetters, mapActions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import router from "../../router";
const namespaced = true;
const plugins = [createPersistedState()];
const state = {
  viewBag: null,
  purchaseOrderData: null,
  saleQuoteData: null,
  invoiceData: null,
  isReconcileBank: false,
  isReconcileBankStep2: false,
  reconcilationData: null,
  importData: null,
  invoiceDetail: null,
  viewPurchaseBillId: null,
  depriRemmainigLife: null,
  purchaseOrderId: null,
  salesInvoiceId: null,
  SaleQuotationId: null,
  viewPaysslipId: null,
  viewManualJournalId: null,
  viewSpecialJournalId: null,
  viewFixedAssetPurchaseId: null,
  viewFixedAssetSaleId: null,
};
const mutations = {
  setViewFixedAssetSaleId(state, payload) {
    state.viewFixedAssetSaleId = payload;
  },
  setViewFixedAssetPurchaseId(state, payload) {
    state.viewFixedAssetPurchaseId = payload;
  },
  setViewSpecialJournalId(state, payload) {
    state.viewSpecialJournalId = payload;
  },
  setViewManualJournalId(state, payload) {
    state.viewManualJournalId = payload;
  },
  setViewPaysslipId(state, payload) {
    state.viewPaysslipId = payload;
  },
  setSalesInvoiceId(state, payload) {
    state.salesInvoiceId = payload;
  },
  setViewPurchaseBillId(state, payload) {
    state.viewPurchaseBillId = payload;
  },
  setSaleQuotationId(state, payload) {
    state.saleQuotationId = payload;
  },
  setPurchaseOrderId(state, payload) {
    state.purchaseOrderId = payload;
  },
  setViewBag(state, payload) {
    state.viewBag = payload;
  },
  setDepriRemainingLife(state, payload) {
    state.depriRemmainigLife = payload;
  },
  deleteViewBag(state) {
    console.log("deleteViewBag MUTATION");
    state.viewBag = null;
  },
  setInvoiceDetail(state, payload) {
    state.invoiceDetail = payload;
  },
  deleteInvoiceDetail(state) {
    state.invoiceDetail = null;
  },
  setInvoiceData(state, payload) {
    state.invoiceData = payload;
  },
  deleteInvoiceData(state) {
    state.invoiceData = null;
  },
  setPurchaseOrderData(state, payload) {
    state.purchaseOrderData = payload;
  },
  deletePurchaseOrderData(state) {
    state.purchaseOrderData = null;
  },
  setSaleQuoteData(state, payload) {
    state.saleQuoteData = payload;
  },
  deleteSaleQuoteData(state) {
    state.saleQuoteData = null;
  },
  setIsReconcileBank(state, payload) {
    state.isReconcileBank = payload;
  },
  setIsReconcileBankStep2(state, payload) {
    state.isReconcileBankStep2 = payload;
  },
  setReconcilationData(state, payload) {
    state.reconcilationData = payload;
  },
  setImportData(state, payload) {
    state.importData = payload;
  },
};
const actions = {
  onDepriRemainingLife({ commit }, payload) {
    commit("setDepriRemainingLife", payload);
  },
  setViewBag({ commit }, payload) {
    console.log("setViewBag payload", payload);
    commit("setViewBag", payload);
  },
  deleteViewBag({ commit }) {
    console.log("deleteViewBag");
    commit("deleteViewBag");
  },
  setInvoiceData({ commit }, payload) {
    commit("setInvoiceData", payload);
  },
  deleteInvoiceData({ commit }) {
    commit("deleteInvoiceData");
  },
  setInvoiceDetail({ commit }, payload) {
    commit("setInvoiceDetail", payload);
  },
  deleteInvoiceDetail({ commit }) {
    commit("deleteInvoiceDetail");
  },
  setPurchaseOrderData({ commit }, payload) {
    commit("setPurchaseOrderData", payload);
  },
  deletePurchaseOrderData({ commit }) {
    commit("deletePurchaseOrderData");
  },
  setSaleQuoteData({ commit }, payload) {
    commit("setSaleQuoteData", payload);
  },
  deleteSaleQuoteData({ commit }) {
    commit("deleteSaleQuoteData");
  },
  setIsReconcileBank({ commit }, payload) {
    commit("setIsReconcileBank", payload);
  },
  setIsReconcileBankStep2({ commit }, payload) {
    commit("setIsReconcileBankStep2", payload);
  },
  setReconcilationData({ commit }, payload) {
    commit("setReconcilationData", payload);
  },
  setImportData({ commit }, payload) {
    commit("setImportData", payload);
  },
  discuss({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/Discuss",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          statementLineID: data.statementLineID,
          discuss: data.discuss,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  reconcileByRule({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ReconciliationByRule/AddReconciliationByRule",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          bankImportID: data.bankImportID,
          importFileID: data.importFileID,
          statementLineID: data.statementLineID,
          ruleID: data.ruleID,
          reference: data.reference,
          ismanualtrndate: data.ismanualtrndate,
          trndate: data.trndate ? data.trndate : undefined,
          contact: data.contact ? data.contact : undefined,
          createTransferID: data.createTransferID
            ? data.createTransferID
            : undefined,
          exchangeRate: data.exchangeRate ? data.exchangeRate : 1,
          tbExchangeRate: data.tbExchangeRate ? data.tbExchangeRate : undefined,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewRulesForReconciliation(
    { state, getters, commit, rootState, rootGetters },
    accountID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/ViewRulesForReconciliation",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          accountID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // Show Recouncilation Rules
  ShowReconciliationRules(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/ShowReconciliationRules",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          status: payload.status,
          pageNum: payload.pageNum,
          limit: payload.limit,
          ruleID: payload.ruleID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // DeleteCBRE
  DeleteCBRE({ rootGetters }, cbreID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/CBRE/DeleteCBRE",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          cbreID: cbreID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  dropdownContacts({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/CBRE/ShowContactsForDropDown",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addCBRE({ rootGetters }, payload) {
    console.log("payload", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/CBRE/CBRE",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          entryType: payload.entryType,
        },
        payload.data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ReconcileBankTransfer(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    let data = JSON.stringify(formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManageBank/ReconcileBankTransfer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          importID: formdata.importID,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  reconcileData({ rootGetters }, payload) {
    console.warn(payload);
    let transactionData = JSON.stringify(payload.trnId);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/BankReconciliation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          BankImportID: payload.bankId,
          trnNo: payload.trnNo,
          contactID: payload.contactID ? payload.contactID : undefined,
        },
        payload.adjustments,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteStatementLine(
    { state, getters, commit, rootState, rootGetters },
    importBank
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/DeleteBankStatement",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          importBankID: importBank.importBankID,
          importDetailsBankID: importBank.importDetailsBankID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  SearchJournalForReconciliation({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/SearchJournalForReconciliation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          bankID: payload.bankID ? payload.bankID : undefined,
          keyword: payload.keyword ? payload.keyword : undefined,
          amount: payload.amount ? payload.amount : undefined,
          pageNum: payload.pageNum,
          limit: payload.limit,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // Deactive Rule
  deleteRule({ state, getters, commit, rootState, rootGetters }, ruleID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/DectivateReconciliationRule",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          ruleID: ruleID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // activate Rule
  ActivateReconciliationRule(
    { state, getters, commit, rootState, rootGetters },
    ruleID
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/ActivateReconciliationRule",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          ruleID: ruleID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getJournals({ state, getters, commit, rootState, rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/ShowNotReconciledJournal",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          trnType: payload.trnType, //Changes Made
          coaID: payload.coaID,
          pageNum: payload.pageNum,
          limit: payload.limit,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getBankStatements({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/ShowNotReconciledBankImports",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          importID: payload.importID,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getFiles({ state, getters, commit, rootState, rootGetters }, COAID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Reconciliation/ShowBankImportStatement",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          COAID: COAID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  addReconcilationRule(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    let rulesData = JSON.stringify(payload);
    console.log("data Recieved", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/AddReconciliationRules",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // update Reconcillation
  UpdateReconciliationRules(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const ruleID = payload.ruleID;
    let rulesData = JSON.stringify(payload);
    console.log("data Recieved", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Reconciliation/UpdateReconciliationRules",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          ruleID: ruleID,
        },
        payload,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deleteBankTransfer(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    let transferData = JSON.stringify(payload.data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManageBank/DeleteBankTransfer",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          transferID: payload.transferID,
          type: payload.type,
        },
        transferData,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  UpdateBankTransfer(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    let transferData = JSON.stringify(payload.data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/ManageBank/UpdateBankTransfer",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          transferID: payload.transferId,
        },
        transferData,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewBankTransfer({ rootGetters }, transferID) {
    console.log("in view transfer", transferID);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ManageBank/ViewBankTransfer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          transferID: transferID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewBankTransferByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ManageBank/ViewBankTransferByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          // branchID: selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewReconciliationByRuleByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ReconciliationByRule/ViewReconciliationByRuleByInvoice",
        {
          businessID: selectedBusiness.businessId,
          // branchID: selectedBusiness.branchId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateReconciliationByRule({ rootGetters }, RBRID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "ReconciliationByRule/DeactivateReconciliationByRule",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          RBRID: RBRID,
        },
        null,
        options
      )
      .then((res) => {
        console.log("delete response of RBRDeacitvate", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewCBREByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/CBRE/ViewCBREByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importBank({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Reconciliation/BankStatementImport",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getAccountCurrency(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/accountcurrency",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          accountID: payload,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  BankTransfer({ state, getters, commit, rootState, rootGetters }, formdata) {
    let data = JSON.stringify(formdata);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManageBank/BankTransfer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  searchBankTransfer(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ManageBank/SearchBankTransfer",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          invoiceNum: payload.invoice,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getBankTransactions({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "Reconciliation/BankTransactions",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          bankID: payload.bankID,
          pageNum: payload.pageNum,
          limit: payload.limit,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getReconciledStatement({ rootGetters }, statementLineID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "Reconciliation/ReconciledStatement",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          statementLineID: statementLineID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  getReconciliationUnreconcile({ rootGetters }, payload) {
    // let transferData=JSON.stringify(payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "Reconciliation/Unreconcile",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          BRID: payload.bankReconcileID,
        },
        null,
        options
      )
      .then((res) => {
        console.log("delete response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  SearchBankReconciliation({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "Reconciliation/SearchBankStatements",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          accountID: payload.accountID ? payload.accountID : undefined,
          amount: payload.amount,
          fileName: payload.fileName,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateBankReconciliation({ rootGetters }, payload) {
    // let transferData=JSON.stringify(payload.data)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "Reconciliation/DeactivateBankReconciliation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          bankReconcileID: payload.bankReconcileID,
        },
        null,
        options
      )
      .then((res) => {
        console.log("delete response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  SearchPaymentByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ManageBank/SearchPaymentByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          invoiceNum: payload.invoice,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          amount: payload.amount,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  OutstandingInvoice({ rootGetters }, payload) {
    console.warn("Payload", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    let data = {
      businessID: selectedBusiness.businessId,
      branchID: selectedBusiness.branchId,
      referenceType: payload.form.tranctionType,
      invoiceNum: payload.form.invoice ? payload.form.invoice : undefined,
      fromDate: payload.form.fromDate,
      toDate: payload.form.toDate,
      amount: payload.form.amount ? payload.form.amount : 0,
      pageNum: payload.pageNum,
      limit: payload.limit,
    };
    if (payload.form.tranctionType == "vendor" && payload.form.selectedOption) {
      data["referenceID"] = payload.form.selectedOption.vendorID;
    } else if (
      payload.form.tranctionType == "customer" &&
      payload.form.selectedOption
    ) {
      data["referenceID"] = payload.form.selectedOption.customerID;
    } else if (
      payload.form.tranctionType == "employee" &&
      payload.form.selectedOption
    ) {
      data["referenceID"] = payload.form.selectedOption.employeeID;
    }
    return request
      .make("GET", "/ManageBank/OutstandingInvoice", data, null, options)
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  OutStandingInvoicePayment(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "application/json",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/ManageBank/OutStandingInvoicePayment",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //Get Currency
  getCurrency({ rootGetters }, currencyName) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/BusinessCurrency/ViewCurrency",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          currencyName: currencyName,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //Get Products
  getProducts({ state, getters, commit, rootState, rootGetters }, productID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Product/ViewProduct",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          productID: productID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //Get vendors
  getVendors({ state, getters, commit, rootState, rootGetters }, vendorID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Vendor/ViewVendor",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          vendorID: vendorID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //Get Category
  getCategory({ rootGetters }, categoryId) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Category/ViewCategory",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          categoryId: categoryId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // view Tax
  viewTax({ getters, rootGetters }, taxID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/TaxComponent/ViewTax",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          taxID: taxID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //COA
  getChartOfAccount(
    { state, getters, commit, rootState, rootGetters },
    accountId
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccount",
        {
          businessId: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          accountId: accountId,
        },
        null,
        options
      )
      .then((res) => {
        if (
          res.data.success == "Failed" &&
          res.data.response[0].message == "Business id doesnot exists"
        ) {
          router.push({ name: "signout" });
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err;
      });
  },
  //Get chart of account with Refs
  getMultipleCOAwithRef(
    { state, getters, commit, rootState, rootGetters },
    formdata
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    const refNameArray = Array.from(formdata.getAll("refName")).map(Number);

    // Append each element of refNameArray individually
    refNameArray.forEach((value, index) => {
      formdata.append(`refNameArray[${index}]`, value);
    });

    return request
      .make(
        "POST",
        "/ChartOfAccount/ViewChartOfAccountWithMultipleReference",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // ExportPB({ rootGetters}, formData){
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + rootGetters["auth/loginToken"],
  //       // 'Content-Type':'application/json',
  //     }
  //   };
  //   let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  //   console.log("selected business:....", selectedBusiness)
  //   return request
  //     .make(
  //       "GET",
  //       "/Purchase/ExportPB",
  //       {
  //         businessID: selectedBusiness.businessId,
  //         branchID: selectedBusiness.branchId,
  //         fileType: formData.fileType,
  //         purchaseID: formData.purchaseID
  //         },
  //         null,
  //       options
  //     )
  //     .then(res => {
  //       // console.log("response is this:",res)
  //       return res
  //       // commit("vendorExportFile", res.data)
  //     })
  //     .catch(err => {
  //       // console.log("error is:",err)
  //       return err;
  //     });
  // },
  // ExportSale({state, getters, commit, rootState, rootGetters}, formData){
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + rootGetters["auth/loginToken"],
  //       // 'Content-Type':'application/json',
  //     }
  //   };
  //   let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  //   return request
  //     .make(
  //       "GET",
  //       "/Sale/ExportSale",
  //       {
  //         businessID: selectedBusiness.businessId,
  //         branchID  : selectedBusiness.branchId,
  //          fileType : formData.fileType,
  //          saleID   : formData.SaleID
  //         },
  //         null,
  //       options
  //     )
  //     .then(res => {
  //       // console.log("response is this:",res)
  //       return res
  //       // commit("vendorExportFile", res.data)
  //     })
  //     .catch(err => {
  //       // console.log("error is:",err)
  //       return err;
  //     });
  // },
  getCOAwithRef({ state, getters, commit, rootState, rootGetters }, refName) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountWithRef",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          refName: refName,
        },
        refName,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewCOAwithRef({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/ChartOfAccount/ViewChartOfAccountWithRef",
        {
          businessId: selectedBusiness.businessId,
          branchId: data.branchId ? data.branchId : undefined,
          refName: data.refName,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  importPurchase({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };          
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Purchase/ImportPB",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // ADD PURCHASE BILL
  addPurchaseBill({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Purchase/AddPurchaseBill",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addPurchaseReturn({ rootGetters }, payload) {
    // let data = JSON.stringify(payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/PurchaseReturn/AddPurchaseReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewPurchaseReturn({ rootGetters }, purchaseReturnID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/PurchaseReturn/ViewPurchaseReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseReturnID: purchaseReturnID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewPurchaseReturnByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/PurchaseReturn/ViewPurchaseReturnByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivatePurchaseReturn({ rootGetters }, purchaseReturnID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/PurchaseReturn/DeactivatePurchaseReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseReturnID: purchaseReturnID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updatePurchaseReturn({ rootGetters }, payload) {
    // let data = JSON.stringify(payload.data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/PurchaseReturn/UpdatePurchaseReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          PRID: payload.PRID,
        },
        payload.data,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // update PURCHASE BILL
  UpdatePurchaseBill(
    { state, getters, commit, rootState, rootGetters },
    payload
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Purchase/UpdatePurchaseBill",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseID: payload.purchaseID,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // search PURCHASE BILL
  SearchPurchase({ rootGetters }, payload) {
    console.log(
      "data sent as payload",
      payload,
      payload.invoice,
      payload.selectedOption
    );
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "Purchase/SearchPurchase",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          referenceID: payload.selectedOption
            ? payload.selectedOption
            : undefined,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          invoiceNum: payload.invoice ? payload.invoice : undefined,
          amount: payload.amount,
          pageNum: payload.pageNum,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // SearchPurchase({ state, getters, commit, rootState, rootGetters }, searchData) {
  //   console.log('data',searchData)
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + rootGetters["auth/loginToken"]
  //     }
  //   };
  //   let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  //   // if (Object.size(searchData) > 0) {

  //   // }
  //   return request
  //     .make(
  //       "GET",
  //       "Purchase/SearchPurchase",
  //       { businessID: selectedBusiness.businessId,
  //         branchID: selectedBusiness.branchId,
  //         vendorID:searchData.vendorID,
  //         PurchaseDateFrom:searchData.dateFrom,
  //         PurchaseDateTo:searchData.toDate
  //       },
  //       null,
  //       options
  //     )
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // },

  viewPurchase({ rootGetters }, purchaseID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Purchase/ViewPurchase",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseID: purchaseID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewPurchaseByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Purchase/ViewPurchaseByInvoice",
        {
          businessID: selectedBusiness.businessId,
          // branchID: selectedBusiness.branchId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivatePurchaseBill({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Purchase/DeactivatePurchaseBill",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseID: payload.purchaseID,
          isForceDelete: payload.isForceDelete,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ExportPB({ rootGetters }, payload) {
    let pbID;
    if (payload.purchaseID) pbID = { id: payload.purchaseID };
    else pbID = {};
    //console.log("payload received",payload)
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Purchase/ExportPB",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: payload.fileType,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
        },
        pbID,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //PURCHASE Order
  addPurchaseOrder({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/PurchaseOrder/AddPurchaseOrder",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  searchPurchaseOrder({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/PurchaseOrder/SearchPurchaseOrder",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          referenceID: payload.selectedOption
            ? payload.selectedOption
            : undefined,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          invoiceNum: payload.invoice ? payload.invoice : undefined,
          amount: payload.amount,
          pageNum: payload.pageNum,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewPurchaseOrder({ rootGetters }, purchaseOrderID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/PurchaseOrder/ViewPurchaseOrder",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseOrderID: purchaseOrderID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewPurchaseOrderByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/PurchaseOrder/ViewPurchaseOrderByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updatePurchaseOrder({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/PurchaseOrder/UpdatePurchaseOrder",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseOrderID: payload.purchaseOrderID,
        },
        payload.formdata,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactivatePurchaseOrder({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/PurchaseOrder/DeactivatePurchaseOrder",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          purchaseOrderID: payload.purchaseOrderID,
          isForceDelete: payload.isForceDelete,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  getsalesman({ state, getters, commit, rootState, rootGetters }) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Employee/ViewSalesman",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  importSaleInvoice({ state, getters, commit, rootState, rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Sale/ImportSale",
        {
          businessId: selectedBusiness.businessId,
          branchId: selectedBusiness.branchId,
          fileType: data.fileType,
        },
        data.formdata,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ExportSale({ rootGetters }, formData) {
    let saleID;
    if (formData.SaleID) saleID = { id: formData.SaleID };
    else saleID = {};
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        // 'Content-Type':'application/json',
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Sale/ExportSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          fileType: formData.fileType,
          fromDate: formData.fromDate,
          toDate: formData.toDate,
        },
        saleID,
        options
      )
      .then((res) => {
        // console.log("response is this:",res)
        return res;
        // commit("vendorExportFile", res.data)
      })
      .catch((err) => {
        // console.log("error is:",err)
        return err;
      });
  },
  // AddSaleInvoice
  AddSaleInvoice({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Sale/AddSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  addSaleReturn({ rootGetters }, payload) {
    // let data = JSON.stringify(payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/SaleReturn/AddSaleReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        payload,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewSaleReturn({ rootGetters }, saleReturnID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/SaleReturn/ViewSaleReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          saleReturnID: saleReturnID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewSaleReturnByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/SaleReturn/ViewSaleReturnByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  DeactivateSaleReturn({ rootGetters }, saleReturnID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/SaleReturn/DeactivateSaleReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          saleReturnID: saleReturnID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateSaleReturn({ rootGetters }, payload) {
    // let data = JSON.stringify(payload.data);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "multipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/SaleReturn/UpdateSaleReturn",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          SRID: payload.SRID,
        },
        payload.data,
        options
      )
      .then((res) => {
        console.log("update response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  //searchsaleInvoice
  SearchSale({ rootGetters }, payload) {
    console.log("data", payload);
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    // if (Object.size(searchData) > 0) {

    // }
    return request
      .make(
        "GET",
        "Sale/SearchSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          referenceID: payload.selectedOption
            ? payload.selectedOption
            : undefined,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          invoiceNum: payload.invoice ? payload.invoice : undefined,
          amount: payload.amount,
          pageNum: payload.pageNum,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // searchsaleInvoice({ state, getters, commit, rootState, rootGetters }, searchData) {
  //   const options = {
  //     headers: {
  //       Authorization: "Bearer " + rootGetters["auth/loginToken"]
  //     }
  //   };
  //   let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
  //   return request
  //     .make(
  //       "GET",
  //       "/Sale/SearchSale",
  //       {
  //         businessID: selectedBusiness.businessId,
  //         branchID: selectedBusiness.branchId,
  //         customerID: searchData.customerID,
  //         saleDateFrom:searchData.dateFrom,
  //         saleDateTo:searchData.toDate
  //       },
  //       null,
  //       options
  //     )
  //     .then(res => {
  //       return res;
  //     })
  //     .catch(err => {
  //       return err;
  //     });
  // },

  // viewSale
  viewSale({ rootGetters }, saledata) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Sale/ViewSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          saleID: saledata,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewSaleByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Sale/ViewSaleByInvoice",
        {
          businessID: selectedBusiness.businessId,
          // branchID: selectedBusiness.branchId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
          invoiceType: payload.invoiceType,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // DeactivateSaleInvoice
  DeactivateSaleInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "Sale/DeactivateSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          saleID: payload.saleID,
          isForceDelete: payload.isForceDelete,
        },
        null,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  // UpdateSale
  updateSale({ rootGetters }, dataToSend) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Sale/UpdateSale",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          saleID: dataToSend.saleID,
        },
        dataToSend.formdata,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },

  // SearchFAPurchase
  SearchFAPurchase(
    { state, getters, commit, rootState, rootGetters },
    searchData
  ) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/FAPurchase/SearchFAPurchase",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          vendorID: searchData.vendorID,
          PurchaseDateFrom: searchData.dateFrom,
          PurchaseDateTo: searchData.toDate,
          limit: searchData.limit,
          pageNum: searchData.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  AddQuotation({ rootGetters }, data) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Quotation/AddQuotation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
        },
        data,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  searchSaleQuotation({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "Quotation/SearchQuotation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          referenceID: payload.referenceID ? payload.referenceID : undefined,
          fromDate: payload.fromDate,
          toDate: payload.toDate,
          invoiceNum: payload.invoiceNum ? payload.invoiceNum : undefined,
          amount: payload.amount,
          pageNum: payload.pageNum,
          limit: payload.limit,
          pageNum: payload.pageNum,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  viewSaleQuotation({ rootGetters }, quotationID) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Quotation/ViewQuotation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          quotationID: quotationID,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  ViewQuotationByInvoice({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "GET",
        "/Quotation/ViewQuotationByInvoice",
        {
          businessID: selectedBusiness.businessId,
          branchID: payload.branchID
            ? payload.branchID
            : selectedBusiness.branchId,
          invoiceNum: payload.invoiceNo,
        },
        null,
        options
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  deactivateSaleQuotation({ rootGetters }, payload) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "POST",
        "/Quotation/DeactivateQuotation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          quotationID: payload.quotationID,
          isForceDelete: payload.isForceDelete,
        },
        null,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
  updateSaleQuotation({ rootGetters }, dataToSend) {
    const options = {
      headers: {
        Authorization: "Bearer " + rootGetters["auth/loginToken"],
        "Content-Type": "mutlipart/form-data",
      },
    };
    let selectedBusiness = rootGetters["setupItem/getSelectedBusiness"];
    return request
      .make(
        "PUT",
        "/Quotation/UpdateQuotation",
        {
          businessID: selectedBusiness.businessId,
          branchID: selectedBusiness.branchId,
          quotationID: dataToSend.quotationID,
        },
        dataToSend.formdata,
        options
      )
      .then((res) => {
        console.log("response of store", res);
        return res;
      })
      .catch((err) => {
        return err;
      });
  },
};
const getters = {
  getIsReconcileBank(state) {
    return state.isReconcileBank;
  },

  getDepriRemainingLife(state) {
    return state.depriRemmainigLife;
  },
  getIsReconcileBankStep2(state) {
    return state.isReconcileBankStep2;
  },
  getReconcilationData(state) {
    return state.reconcilationData;
  },
  getImportData(state) {
    return state.importData;
  },
  getViewBag(state) {
    return state.viewBag;
  },
  getInvoiceDetail(state) {
    return state.invoiceDetail;
  },
  getInvoiceData(state) {
    return state.invoiceData;
  },
  getPurchaseOrderData(state) {
    return state.purchaseOrderData;
  },
  getSaleQuoteData(state) {
    return state.saleQuoteData;
  },
  getViewPurchaseBillId(state) {
    return state.viewPurchaseBillId;
  },
  getPurchaseOrderId(state) {
    return state.purchaseOrderId;
  },
  getSalesInvoiceId(state) {
    return state.salesInvoiceId;
  },
  getSaleQuotationId(state) {
    return state.saleQuotationId;
  },
  getViewPaysslipId(state) {
    return state.viewPaysslipId;
  },
  getViewManualJournalId(state) {
    return state.viewManualJournalId;
  },
  getViewSpecialJournalId(state) {
    return state.viewSpecialJournalId;
  },
  getViewFixedAssetPurchaseId(state) {
    return state.viewFixedAssetPurchaseId;
  },
  getViewFixedAssetSaleId(state) {
    return state.viewFixedAssetSaleId;
  },
};
export default {
  namespaced,
  plugins,
  getters,
  actions,
  mutations,
  state,
};
import store from "../index";
const request = {
  /* ----------------- Controller Function -------------------- */
  make(
    type = "GET",
    apiAddress = null,
    params = null,
    body = null,
    header = ""
  ) {
    if (apiAddress) {
      return axios({
        method: type,
        url: apiAddress,
        params: params,
        data: body,
        headers: header.headers,
      })
        .then((response) => {
          return new Promise(function (resolve, reject) {
            resolve(response);
          });
        })
        .catch((error) => {
          console.log("error", event);
          if (event.srcElement.status === 401) {
            store.commit("auth/setLoginExpired", true);
          } else if (event.srcElement.status === 403) {
            // var error= JSON.parse( event.srcElement.response)
            console.log(
              "error par,,,,,,,,,,,",
              event,
              JSON.parse(event.srcElement.response).response[0].message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).response[0].message
            );
            router.push({ name: "accessDenied" });
          } else if (event.srcElement.status === 500) {
            console.log(
              "Error 500",
              JSON.parse(event.srcElement.response).message
            );
            store.commit(
              "auth/setErrorMessage",
              JSON.parse(event.srcElement.response).message
            );
          } else {
            return new Promise(function (resolve, reject) {
              reject(error);
            });
          }
        });
    } else {
      return new Promise(function (resolve, reject) {
        let error = new Error();
        error.response = {
          data: {
            error: true,
            message: "server request Failed.",
          },
        };
        resolve({
          error: true,
          message: "server request Failed.",
        });
        reject(error);
      });
    }
  },
  /* ----------------- ---------- -------- -------------------- */
};
