<template>
  <div>
    <v-card>
      <v-card-title
        >Add Vendor
        <v-spacer></v-spacer>
        <v-btn color="error" icon @click="closeVendorTab()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-text-field
              label="Company Name *"
              type="text"
              :error-messages="companyNameErrors"
              v-model.trim="$v.form.companyName.$model"
              required
              filled
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" :loading="loading" text @click="addVendor"
          ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
        >
        <v-btn color="error" text @click="closeVendorTab()"
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import mainValidations from "../../assets/js/validations";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["showMiniComponent", "getAllVendor", "closeModal", "showSnack"],
  data: () => ({
    loading: false,
    showSnackBar: false,
    color: "success",
    snackbarMsg: "",

    form: {
      companyName: "",
    },
  }),
  mixins: [validationMixin],
  validations: {
    form: {
      companyName: {
        required,
        companyNameExists: (val) => {
          return true;
        },
      },
    },
  },
  computed: {
    companyNameErrors() {
      const errors = [];
      if (!this.$v.form.companyName.$dirty) return errors;
      !this.$v.form.companyName.companyNameExists &&
        errors.push("Company Name already exists");
      !this.$v.form.companyName.required &&
        errors.push("Company Name is required");
      return errors;
    },
  },
  methods: {
    closeVendorTab() {
      this.closeModal(), (this.loading = false);
    },
    addVendor() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        window.scrollTo(0, 0);
        return;
      }
      this.resErrors = [];
      this.loading = true;

      const formdata = new FormData();

      formdata.append("VendorCompany", this.form.companyName);
      this.$store
        .dispatch("setupItem/addVendor", formdata)
        .then((response) => {
          console.log(response);
          if (response.data.success == "OK") {
            this.loading = false;
            this.closeModal("success");
            this.$emit("addedVendor", this.form.companyName);
            this.form.companyName = "";
            this.$v.$reset();
            this.showSnack(response.data.response[0].message, "success");
          } else {
            this.showSnack(response.data.response[0].message, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
          this.showSnackBar = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped></style>
