<template>
  <v-app dark>
    <v-app-bar
      :clipped-left="$vuetify.breakpoint.lgAndUp"
      app
      color="white"
      dark 
    >
      <v-toolbar-title style="width: 300px" class="ml-0 pl-3">
        <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
        <span
          class="hidden-sm-and-down grey--text text--darken-3 font-weight-medium"
        >
          <img
            src=".././assets/images/logo/rsheenlogo.svg"
            width="140"
            style="margin-top: 8px"
        /></span>
      </v-toolbar-title>
      <v-progress-linear
        :active="loading"
        :indeterminate="loading"
        absolute
        top
        height="4"
        :value="setupPercent"
        color="primary"
        dark
      ></v-progress-linear>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" color="topBarBtnColor" rounded>
            <v-icon left>mdi-account-circle-outline</v-icon> {{ userName }}
          </v-btn>
        </template>
        <span>User</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            text
            :to="{ name: 'signout' }"
            color="topBarBtnColor"
            class="mr-1 ml-1"
            v-on="on"
             icon 
          >
            <v-icon>mdi-logout-variant</v-icon>
          </v-btn>
        </template>
        <span>LogOut</span>
      </v-tooltip>
    </v-app-bar>

    <v-main class="mainBackground">
      <v-container>
        <!-- vue-router -->
        <v-scroll-x-reverse-transition appear hide-on-leave="true">
        <router-view></router-view>
        </v-scroll-x-reverse-transition>
      </v-container>
    </v-main>
    <!-- <footer-component/> -->
    <v-footer
     color="footerBgColor footerTextColor--text subtitle-2 d-print-none"
      class="text-center"
      padless
    >
      <v-row no-gutters>
        <v-col class="text-center pa-1" cols="12">
          &copy; {{ new Date().getFullYear() }} —
          <strong>R-Sheen Group of Companies</strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import TopNav from "../layouts/containers/topNav";
export default {
  components: {
    TopNav,
  },
  computed: {
    setupPercent() {
      return this.$store.getters["companySetup/getPrecent"];
    },
    userName() {
      return this.$store.getters["auth/getUsername"];
    },
  },
};
</script>

<style lang="scss" scoped>

// .background {
//   background-image: url(".././assets/images/BusinessSetupLayout.svg");
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   top: 0;
//   bottom: 0;
//   right: 0;
//   left: 0;
//   z-index: 0;
// }
</style>
