<template>
  <div>
    <v-card>
      <v-card-title class="font-weight-medium">
        All Groups
        <v-spacer></v-spacer>

        <!--  -->
        <v-btn class="mt-1 ml-1" icon @click="closeGroupDialog" color="error"
          ><v-icon>mdi-close</v-icon></v-btn
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-toolbar elevation="1" color="white">
        <v-tabs v-model="tab"  background-color="white"  color="primary">
          <v-tab class="text-capitalize">Active Groups</v-tab>
          <v-tab class="text-capitalize">Inactive Groups</v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              rounded
              v-on="on"
              @click="openCreateGroupDialog"
              depressed
            >
              <v-icon small left> mdi-plus</v-icon>
              Create
            </v-btn>
          </template>
          <span>Create New Group</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text class="pa-0 pt-1 pb-0">
       
        <v-tabs-items v-model="tab"  class="white">
          <v-tab-item >
            <v-card flat>
              <v-card-text class="pb-0">
                <v-toolbar
                  elevation="1"
                  v-if="activeGroups.length > 0"
                  rounded
                  height="48"
                  class="mb-4"
                  color="white"
                >
                  <v-responsive max-width="300">
                    <v-text-field
                      prepend-inner-icon="mdi-magnify"
                      v-model="search"
                      label="Search"
                      single-line
                      filled
                      rounded
                      dense
                      hide-details
                      autocomplete="off"
                    ></v-text-field>
                  </v-responsive>
                  <v-spacer></v-spacer>
                  <v-toolbar-items v-if="activeGroups.length > 0">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          text
                          @click="assignGroup"
                          :loading="isAssignLoading"
                        >
                          <v-icon left>mdi-check-decagram-outline</v-icon>
                          Assign</v-btn
                        >
                      </template>
                      <span>Assign Group</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="error"
                          text
                          @click="unassignGroup"
                          :loading="unAssignLoading"
                        >
                          <v-icon left> mdi-close-octagon-outline</v-icon>
                          Remove
                        </v-btn>
                      </template>
                      <span>Remove From All Group</span>
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="text-center mt-15 mb-15" v-if="showActiveData">
                  <img
                    src="../../assets/images/groupSvg/activeGroup.svg"
                    width="350"
                  />
                  <h3>No Active Group Found</h3>
                </div>
                <v-data-table
                  :headers="headers"
                  :items="activeGroups"
                  :search="search"
                  :items-per-page="5"
                  v-if="activeGroups.length > 0"
                  dense
                >
                  <template v-slot:item.groupName="{ item }">
                   
                    <v-radio-group
                      v-model="groupIDS"
                      hide-details
                      
                    >
                      <v-radio
                        :disabled="selectedBusiness.branchId != item.branchID"
                        :label="item.groupName"
                        :value="item.groupID"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <span v-if="item.description">{{ item.description }}</span>
                    <span v-if="!item.description" class="error--text"
                      >Not Provided</span
                    >
                  </template>
                  <template v-slot:item.colorCode="{ item }">
                    <v-avatar :color="item.colorCode" size="22"></v-avatar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip bottom v-if="item.groupID == groupIDS">
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="selectedBusiness.branchId == item.branchID"
                          @click="showUpdateDialog(groupIDS)"
                          color="error"
                          text
                          small
                          v-on="on"
                        >
                          <v-icon small left> mdi-pencil </v-icon> Update
                        </v-btn>
                      </template>
                      <span>Update by Group</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="selectedBusiness.branchId == item.branchID"
                          icon
                          color="primary"
                          @click="updateGroup(item.groupID)"
                          v-on="on"
                        >
                          <v-icon small> mdi-pencil </v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-if="selectedBusiness.branchId == item.branchID"
                          @click="showDeactiveDialog(item.groupID)"
                          color="error"
                          icon
                          v-on="on"
                        >
                          <v-icon small color="error"> mdi-delete </v-icon>
                        </v-btn>
                      </template>
                      <span>Deactivate Group</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
            
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!--  -->
          <v-tab-item >
            <v-card flat>
              <v-card-text class="pb-0">
                <v-toolbar
                  elevation="1"
                  v-if="deactiveGroups.length > 0"
                  rounded
                  height="48"
                  class="mb-4"
                  color="white"
                >
                  <v-responsive max-width="300">
                    <v-text-field
                      v-model="search"
                      prepend-inner-icon="mdi-magnify"
                      label="Search"
                      single-line
                      filled
                      rounded
                      dense
                      hide-details
                      autocomplete="off"
                    ></v-text-field>
                  </v-responsive>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="primary"
                          text
                          @click="assignGroup"
                        >
                          <v-icon left>mdi-check-decagram-outline</v-icon>
                          Assign</v-btn
                        >
                      </template>
                      <span>Assign Group</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          v-on="on"
                          color="error"
                          text
                          @click="unassignGroup"
                        >
                          <v-icon left> mdi-close-octagon-outline</v-icon>
                          Remove</v-btn
                        >
                      </template>
                      <span>Remove From All Group</span>
                    </v-tooltip>
                  </v-toolbar-items>
                </v-toolbar>
                <div class="text-center mt-15 mb-15" v-if="showDeActiveData">
                  <img
                    src="../../assets/images/groupSvg/deActiveGroup.svg"
                    width="350"
                  />
                  <h3>No Inactive Group Found</h3>
                </div>
                <!-- for deactive table -->
                <v-data-table
                  :headers="headers"
                  :items="deactiveGroups"
                  :search="search"
                  :items-per-page="5"
                  v-if="deactiveGroups.length > 0"
                  dense
                >
                  <template v-slot:item.groupName="{ item }">
                    <v-radio-group
                      v-model="groupIDS"
                      hide-details
                    >
                      <v-radio
                        :disabled="selectedBusiness.branchId != item.branchID"
                        :label="item.groupName"
                        :value="item.groupID"
                      ></v-radio>
                    </v-radio-group>
                  </template>
                  <template v-slot:item.description="{ item }">
                    <span v-if="item.description">{{ item.description }}</span>
                    <span v-if="!item.description" class="error--text"
                      >Not Provided</span
                    >
                  </template>
                  <template v-slot:item.colorCode="{ item }">
                    <v-avatar :color="item.colorCode" size="22"></v-avatar>
                  </template>
                  <template v-slot:item.action="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          text
                          @click="showActiveDialog(item.groupID)"
                          color="success"
                          v-on="on"
                          small
                        >
                          <v-icon left small> mdi-check-bold </v-icon> Activate
                        </v-btn>
                      </template>
                      <span>Activate Group</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
    </v-card>

    <v-dialog v-model="createGroupDialog" width="750" persistent>
      <v-card>
        <v-card-title
          >Create Group
          <v-spacer></v-spacer>
          <v-btn
            class="mt-0 ml-1"
            icon
            @click="createGroupDialog = false"
            color="error"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>

        <v-card-text class="pt-4">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-text-field
                    label="Group Name"
                    :counter="counterOne"
                    filled
                    v-model="$v.form.groupName.$model"
                    :error-messages="groupNameErrors"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <v-textarea
                    label="Description"
                    :counter="counterTwo"
                    filled
                    rows="6"
                    max-rows="6"
                    v-model="$v.form.description.$model"
                    :error-messages="descriptionErrors"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6" xl="6">
              <v-row>
                <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                  <div class="d-flex justify-center mb-6">
                    <div>
                      <v-subheader
                        class="subtitle-1 font-weight-medium pl-0 pt-0 mt-0 mb-4"
                        height="20"
                        style="height: 18px !important"
                      >
                        Select Color
                      </v-subheader>
                      <v-color-picker
                        elevation="1"
                        hide-inputs
                        dot-size="40"
                        canvas-height="160"
                        show-swatches
                        hide-sliders="true"
                        class="white"
                        :swatches="swatches"
                        v-model="form.colorCode"
                      ></v-color-picker>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            class="ml-2"
            :loading="isLoading"
            @click="createGroup"
            ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
          >
          <v-btn color="error" text @click="createGroupDialog = false"
            ><v-icon left>mdi-cancel</v-icon>Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- end create group -->
    <v-dialog
      v-model="deactivateDialog"
      persistent
      max-width="480"
      min-width="480"
    >
      <v-card>
        <!-- <v-card-title class="headline">Are you sure?</v-card-title> -->
        <v-card-text v-if="!groupMsg">
          <div class="d-flex flex-row pt-6 pb-1">
            <div>
              <v-avatar color="ErrorLight" size="70">
                <v-icon color="error" size="40">mdi-trash-can-outline</v-icon>
              </v-avatar>
            </div>
            <div class="ml-3 mt-1">
              <v-subtitle class="headline font-weight-bold"
                >Are you sure?</v-subtitle
              >
              <br />
              <v-subtitle class="subtitle-1">
                Are you sure you want to
                <span class="font-weight-bold error--text">InActive</span>
                this Group?</v-subtitle
              >
            </div>
          </div>
        </v-card-text>
        <!-- <v-card-text 
          >Are you sure you want to
          <span class="font-weight-bold error--text">Deactivate</span>
          Group</v-card-text
        > -->
        <!-- <v-card-text 
          >Are you sure you want to
          <span class="font-weight-bold success--text">Activate</span>
          Group</v-card-text
        > -->
        <v-card-text v-if="groupMsg">
          <div class="d-flex flex-row pt-6 pb-1">
            <div>
              <v-avatar color="SuccessLight" size="70">
                <v-icon color="success" size="40">mdi-check</v-icon>
              </v-avatar>
            </div>
            <div class="ml-3 mt-1">
              <v-subtitle class="headline font-weight-bold"
                >Are you sure?</v-subtitle
              >
              <br />
              <v-subtitle class="subtitle-1">
                Are you sure you want to
                <span class="font-weight-bold success--text">Activate</span>
                this Group?
              </v-subtitle>
            </div>
          </div>
        </v-card-text>
        <v-card-actions class="alertDialogFooterBg">
          <v-spacer></v-spacer>
          <v-btn color="white" class="ml-2" @click="deactivateDialog = false"
            ><v-icon left> mdi-cancel</v-icon> Cancel</v-btn
          >
          <v-btn v-if="!groupMsg" color="error" @click="deactivateGroup"
            ><v-icon left> mdi-check</v-icon> Confirm</v-btn
          >
          <v-btn v-if="groupMsg" color="primary" @click="activateGroup"
            ><v-icon left> mdi-check</v-icon> Confirm</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end -->
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import mainValidations from "@/assets/js/validations";
import errorMessages from "@/assets/js/errorMessages";
import { mapGetters } from "vuex";
const { required } = require("vuelidate/lib/validators");
export default {
  props: [
    "changeview",
    "moduleName",
    "vendorItems",
    "refernceID",
    "getGroups",
    "getTableData",
    "showGroupSnack",
    "closeGroupDialog",
    "showUpdateDialog",
    "showGroupColumn",
  ],
  data() {
    return {
      swatches: [
        ["#f44336", "#E91E63", "#9c27b0"],
        ["#673ab7", "#3f51b5", "#2196F3"],
        ["#03a9f4", "#00bcd4", "#009688"],
        ["#4caf50", "#cddc39", "#ff9800"],
        ["#ffeb3b", "#795548", "#263238"],
      ],
      search: "",
      showActiveData: false,
      showDeActiveData: false,
      tab: null,
      counterOne: mainValidations.length1,
      counterTwo: mainValidations.length2,
      showGroup: true,
      groupMsg: false,
      closeOnContentClick: false,
      isLoading: false,
      isAssignLoading: false,
      unAssignLoading: false,
      createGroupDialog: false,
      deactivateDialog: false,
      activateDialog: false,
      groupID: "",
      groupIDS: "",
      form: {
        groupName: "",
        description: "",
        colorCode: "#DF5353",
        formType: "",
      },
      activeGroups: [],
      deactiveGroups: [],
      headers: [
        { text: "Group Name", value: "groupName", sortable: "desc" },
        { text: "Description", value: "description", sortable: false },
        { text: "Color", value: "colorCode", sortable: false, align: "center" },
        { text: "Action", value: "action", sortable: false, align: "end" },
      ],
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      groupName: {
        required,
        alphabetsNumberWithoutSpace: (val) => {
          return mainValidations.alphabetsNumberWithoutSpace(val);
        },
        checkLength: (val) => {
          return mainValidations.nameLength(val);
        },
      },
      description: {
        checkDescriptionLength: (val) => {
          return mainValidations.descriptionLength(val);
        },
      },
    },
  },
  computed: {
    ...mapGetters("setupItem", { selectedBusiness: "getSelectedBusiness" }),
    groupNameErrors() {
      const errors = [];
      if (!this.$v.form.groupName.$dirty) return errors;
      !this.$v.form.groupName.required &&
        errors.push("Group Name is required.");
      !this.$v.form.groupName.alphabetsNumberWithoutSpace &&
        errors.push(
          errorMessages.startwithAlphabetsNumberMessage("Group Name")
        );
      !this.$v.form.groupName.checkLength &&
        errors.push(
          errorMessages.lengthMessages("Group Name", mainValidations.length1)
        );
      return errors;
    },
    descriptionErrors() {
      const errors = [];
      if (!this.$v.form.description.$dirty) return errors;
      !this.$v.form.description.checkDescriptionLength &&
        errors.push(
          errorMessages.lengthMessages("Description", mainValidations.length2)
        );
      return errors;
    },
  },
  created() {
    this.getGroups();
    //Getting Groups
    this.getAllGroups();
    let assignedGroups = [];
    // console.log("AJSHKJDSA",this.vendorItems,'helll0',this.refernceID)
    for (let i = 0; i < this.refernceID.length; i++) {
      console.log("assG", this.refernceID[i]);
      assignedGroups.push(this.refernceID[i].assignedGroups);
    }
    for (let j = 0; j < assignedGroups.length; j++) {
      console.log("assignedGroups", assignedGroups[j]);
      for (let k = 0; k < assignedGroups[j].length; k++) {
        // this.groupIDS.push(assignedGroups[j][k].groupid);
        this.groupIDS = assignedGroups[j][k].groupid;
      }
    }
  },
  methods: {
    createGroup() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.isLoading = true;
        let colorCode = this.form.colorCode.replace("#", "");
        let data = {
          groupName: this.form.groupName,
          description: this.form.description,
          formType: this.moduleName,
          colorCode: colorCode,
        };
        this.$store
          .dispatch("setupItem/createGroup", data)
          .then((res) => {
            console.log("createGroup resposne is ", res);
            if (res.data.success == "OK") {
              this.createGroupDialog = false;
              this.showGroupSnack("Group Added Successfully", "snackbarSuccess");
              this.getGroups();
              this.getAllGroups();
            } else {
              this.createGroupDialog = false;
              this.showGroupSnack(res.data.response[0].message, "error");
            }
          })
          .catch((error) => {
            console.log("error is", error);
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    },
    showDeactiveDialog(id) {
      this.groupID = id;
      (this.deactivateDialog = true), (this.groupMsg = false);
    },
    showActiveDialog(id) {
      this.groupID = id;
      this.deactivateDialog = true;
      this.groupMsg = true;
    },
    getAllGroups() {
      this.$store.dispatch("setupItem/viewGroup", "").then((res) => {
        this.activeGroups = [];
        this.deactiveGroups = [];
        console.log("view Group", res);
        let result = res.data.groupRespnse;
        for (let i = 0; i < result.length; i++) {
          if (
            result[i].status == "Active" &&
            result[i].formType == this.moduleName
          ) {
            this.activeGroups.push(result[i]);
          } else if (
            result[i].status == "Inactive" &&
            result[i].formType == this.moduleName
          ) {
            this.deactiveGroups.push(result[i]);
          }
        }
        if (this.activeGroups.length == 0) {
          this.showActiveData = true;
        } else {
          this.showActiveData = false;
        }
        if (this.deactiveGroups.length == 0) {
          this.showDeActiveData = true;
        } else {
          this.showDeActiveData = false;
        }
      });
    },
    deactivateGroup() {
      this.$store
        .dispatch("setupItem/deactivategroup", this.groupID)
        .then((res) => {
          console.log("deactivategroup is ", res);
          if (res.data.success == "OK") {
            this.deactivateDialog = false;
            this.showGroupSnack("Group Inactive", "snackbarSuccess");
            this.getAllGroups();
            this.getGroups();
            if (activeGroups.length == 0) {
              this.showActiveData = true;
            }
          } else {
            this.showGroupSnack(res.data.response[0].message);
          }
        })
        .catch((error) => {
          console.log("deactivategroup error is", error);
        });
    },
    activateGroup() {
      this.$store
        .dispatch("setupItem/activategroup", this.groupID)
        .then((res) => {
          console.log("activategroup is ", res);
          if ((res.data.success = "OK")) {
            this.deactivateDialog = false;
            this.showGroupSnack("Group Activated", "snackbarSuccess");
            this.getAllGroups();
            this.getGroups();
          }
        })
        .catch((error) => {
          console.log("activategroup error is", error);
        });
    },
    updateGroup(e) {
      this.gpID = e;
      this.$emit("gpID", this.gpID);
      this.changeview("updateGroup");
      this.getGroups();
      //this.getTableData()
    },
    assignGroup() {
      // if (this.groupIDS.length == 0) {
      if (!this.groupIDS) {
        this.showGroupSnack("Select a Group to Assign", "error");
        return;
      }
      this.isAssignLoading = true;
      let refID = [];
      let gpID = [];
      this.refernceID.forEach((val) => {
        if (this.moduleName == "product") {
          refID.push(val.productID);
        } else if (this.moduleName == "branch") {
          refID.push(val.branchID);
        } else if (this.moduleName == "vendor") {
          refID.push(val.vendorId);
        } else if (this.moduleName == "customer") {
          refID.push(val.customerId);
        } else if (this.moduleName == "employee") {
          refID.push(val.employeeId);
        } else if (this.moduleName == "fixedassetitem") {
          refID.push(val.fixedAssetID);
        } else {
          refID.push(val.id);
        }
      });
      gpID.push(this.groupIDS);
      let data = {
        formType: this.moduleName,
        groupsID: gpID,
        // groupsID: this.groupIDS,
        referenceID: refID,
      };
      this.$store
        .dispatch("setupItem/addGroupDetails", JSON.stringify(data))
        .then((res) => {
          console.log("addGroupDetails Response ", res);
          if (res.data.success == "OK") {
            //if(res.data.success=="OK"){
            //                 newChanges
            let groupDetails = [];
            for (let i = 0; i < this.activeGroups.length; i++) {
              // for (let j = 0; j < this.groupIDS.length; j++) {
              //console.log("Check for match",this.activeGroups[i].groupID,this.groupIDS[j])
              if (this.activeGroups[i].groupID == this.groupIDS) {
                groupDetails.push(this.activeGroups[i]);
              }
              // }//INNER fOR LOOP
            }
            //}
            this.showGroupSnack("Group Assigned Successfully", "snackbarSuccess");
            this.getGroups();
            this.showGroupColumn(groupDetails, refID);
            this.closeGroupDialog();
          } else {
            this.showGroupSnack(res.data.response[0].message, "error");
          }
        })
        .catch((error) => {
          console.log("addGroupDetails error is", error);
        })
        .finally(() => {
          this.isAssignLoading = false;
        });
    },
    unassignGroup() {
      this.unAssignLoading = true;
      let refID = [];
      this.refernceID.forEach((val) => {
        if (this.moduleName == "product") {
          refID.push(val.productID);
        } else if (this.moduleName == "branch") {
          refID.push(val.branchID);
        } else if (this.moduleName == "vendor") {
          refID.push(val.vendorId);
        } else if (this.moduleName == "customer") {
          refID.push(val.customerId);
        } else if (this.moduleName == "employee") {
          refID.push(val.employeeId);
        } else if (this.moduleName == "fixedassetitem") {
          refID.push(val.fixedAssetID);
        } else {
          refID.push(val.id);
        }
      });
      let data = {
        formType: this.moduleName,
        groupsID: [],
        referenceID: refID,
      };
      this.$store
        .dispatch("setupItem/addGroupDetails", JSON.stringify(data))
        .then((res) => {
          if (res.data.success == "OK") {
            this.showGroupSnack("Unassigned Successfully", "snackbarSuccess");
            this.getGroups();
            this.closeGroupDialog();
            this.getTableData();
          } else {
            this.showGroupSnack(res.data.response[0].message, "error");
          }
        })
        .catch((error) => {
          console.log("addGroupDetails error is", error);
        })
        .finally(() => {
          this.unAssignLoading = false;
        });
    },
    openCreateGroupDialog() {
      this.createGroupDialog = true;
      // this.closeGroupDialog()
    },
  },
};
</script>

<style lang="scss" scoped></style>
