<template>
  <div>
    <v-card>
      <v-card-title
        >Add Product
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          icon
          @click="
            closeModal();
            loading = false;
          "
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12" lg="12" sm="12" md="12">
            <v-text-field
              label="Service Name *"
              type="text"
              :error-messages="serviceNameErrors"
              v-model.trim="$v.form.serviceName.$model"
              filled
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="mx-2">
        <v-btn color="primary" :loading="loading" text @click="addProduct"
          ><v-icon left>mdi-content-save-outline</v-icon> Save</v-btn
        >
        <v-btn
          color="error"
          text
          @click="
            closeModal();
            loading = false;
          "
          ><v-icon left>mdi-cancel</v-icon> Cancel</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import mainValidations from "../../assets/js/validations";
import { validationMixin } from "vuelidate";
const { required } = require("vuelidate/lib/validators");
export default {
  props: ["getAllProduct", "closeModal", "showSnack"],
  data() {
    return {
      loading: false,
      form: {
        serviceName: "",
      },
    };
  },
  mixins: [validationMixin],
  validations: {
    form: {
      serviceName: {
        required,
      },
    },
  },
  computed: {
    serviceNameErrors() {
      const errors = [];
      if (!this.$v.form.serviceName.$dirty) return errors;
      !this.$v.form.serviceName.required &&
        errors.push("Service Name is required");
      return errors;
    },
  },
  methods: {
    addProduct() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.loading = true;
        this.resErrors = [];
        const formdata = new FormData();
        formdata.append("ProductName", this.form.serviceName);
        this.$store
          .dispatch("setupItem/addProduct", formdata)
          .then((res) => {
            console.log("Product added", res);
            if (res.data.success == "OK") {
              this.loading = false;
              this.closeModal("success");
              this.$emit("addedProduct", this.form.serviceName);
              this.showSnack(res.data.response[0].message, "success");
              this.form.serviceName = "";
              this.$v.$reset();
            } else {
              this.closeModal("error");
              this.showSnack(res.data.response[0].message, "error");
            }
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
